import { FaAddressBook } from "react-icons/fa";
import { BiBookOpen } from "react-icons/bi";
import { publishAll } from "../utils/publishAll";
import { StructureBuilder, StructureResolverContext } from "sanity/structure";
import { orderableDocumentListDeskItem } from "@sanity/orderable-document-list";
import { brochureContentGroup } from "../schemas/content-types";
import { ComponentType } from "react";

export default (S: StructureBuilder, context: StructureResolverContext) =>
  S.listItem()
    .title("Brochures")
    .icon(FaAddressBook)
    .child(
      S.list()
        .title("Brochures")
        .items([
          S.listItem()
            .title("Active Brochures")
            .schemaType("brochure")
            .child(
              S.documentTypeList("brochure")
                .filter(
                  `_type == 'brochure' && defined(isActive) && isActive == true`
                )
                .menuItems([
                  ...(S.documentTypeList("brochure").getMenuItems() || []),
                  S.menuItem()
                    .title("Publish all")
                    .action(() =>
                      publishAll(
                        "brochure",
                        S.context.getClient,
                        S.context.schema
                      )
                    )
                ])
            ),
          S.listItem()
            .title("Inactive Brochures")
            .schemaType("brochure")
            .child(
              S.documentTypeList("brochure")
                .filter(
                  `_type == 'brochure' && (!defined(isActive) || isActive != true)`
                )
                .menuItems([
                  ...(S.documentTypeList("brochure").getMenuItems() || []),
                  S.menuItem()
                    .title("Publish all")
                    .action(() =>
                      publishAll(
                        "brochure",
                        S.context.getClient,
                        S.context.schema
                      )
                    )
                ])
            ),
          S.divider(),
          S.listItem()
            .title("Groups")
            .schemaType("brochureGrouping")
            .child(
              S.documentTypeList("brochureGrouping").menuItems([
                ...(S.documentTypeList("brochureGrouping").getMenuItems() ||
                  []),
                S.menuItem()
                  .title("Publish all")
                  .action(() =>
                    publishAll(
                      "brochureGrouping",
                      S.context.getClient,
                      S.context.schema
                    )
                  )
              ])
            ),
          orderableDocumentListDeskItem({
            type: "brochureContentGroup",
            S,
            context,
            icon: brochureContentGroup.icon as ComponentType,
            title: brochureContentGroup.title
          }),

          S.divider(),
          S.listItem()
            .title("Request Brochure Page")
            .icon(BiBookOpen)
            .child(
              S.editor()
                .id("request-brochure-page")
                .schemaType("requestBrochure")
                .documentId("request-brochure-page")
            ),
          S.listItem()
            .title("Request Brochure - Thank you page")
            .icon(BiBookOpen)
            .child(
              S.editor()
                .id("request-brochure-thankyou-page")
                .schemaType("requestBrochureThankYou")
                .documentId("request-brochure-thankyou-page")
            )
        ])
    );
