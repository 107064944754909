import { BiBookOpen } from "react-icons/bi";
import { defineType } from "sanity";

export default defineType({
  name: "requestBrochureThankYou",
  title: "Request Brochure - Thank you page",
  icon: BiBookOpen,
  type: "document",
  fieldsets: [
    {
      name: "digital",
      title: "Digital Brochure",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "printed",
      title: "Printed Brochure",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  groups: [
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "subtitle",
      title: "Subtitle (to be removed)",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "digitalSubtitle",
      title: "Subtitle",
      type: "multilingualString",
      validation: (Rule) => Rule.required(),
      fieldset: "digital"
    },
    {
      name: "printedSubtitle",
      title: "Subtitle",
      type: "multilingualString",
      validation: (Rule) => Rule.required(),
      fieldset: "printed"
    },

    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Request Brochure - Thank you page"
      };
    }
  }
});
