import { filterableContentOf } from "../../utils/filterableContentOf";

const toReadableTextArray = (filterableNumbers: any) =>
  (filterableNumbers || []).map(toReadableText).join(", ");

const filterableNumber = {
  ...filterableContentOf("number", (content: any) => `${content}`),
  title: "Number",
  toReadableText,
  toReadableTextArray
};

function toReadableText(number: any): string {
  return `${filterableNumber.toString(
    //@ts-ignore
    number
    //@ts-ignore
  )}/${filterableNumber.filtersAsReadableText(number)}`;
}

export default filterableNumber;
