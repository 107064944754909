import { BsTextCenter } from "react-icons/bs";
import { imageWithCaption, videoWithPosterImage } from "../objects";
import { defineType } from "sanity";

export default defineType({
  title: "2. [Description] Text",
  name: "descriptionText",
  type: "object",
  icon: BsTextCenter,
  fieldsets: [
    {
      name: "layout",
      title: "Layout",
      options: { collapsible: true, collapsed: true }
    }
  ],
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "variant",
      fieldset: "layout",
      title: "Variant",
      type: "string",
      options: {
        list: [
          { title: "Leadin (default)", value: "leadin" },
          { title: "Big Title", value: "bigTitle" }
        ]
      }
    },
    {
      name: "content",
      title: "Content",
      type: "introSection",
      options: {
        collapsable: false
      }
    },
    {
      name: "highlights",
      title: "Highlights",
      type: "array",
      of: [{ type: "iconHighlight" }]
    },
    {
      name: "slideshow",
      title: "Slideshow",
      type: "array",
      of: [
        {
          type: imageWithCaption.name
        },
        {
          type: videoWithPosterImage.name
        }
      ],
      options: {
        collapsable: true
      }
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  preview: {
    select: {
      title: "content.leadIn.en",
      media: "slideshow.image[0]"
    },
    prepare(selection) {
      const { title, media } = selection;
      return {
        title: title || "([Description] Text)",
        media: media
      };
    }
  }
});
