import { nanoid } from "nanoid";
import { PortableTextObject } from "sanity";
import { MultilingualBlockContentValue } from "../schemas/base/multilingualBlockContent";
import { LanguageCode } from "../common/localization";

// TODO: try to migrate to normalizeBlock from @sanity/block-tools

export const cloneBlockContentValue = (
  blocks: MultilingualBlockContentValue | null | undefined
) => {
  if (!blocks) return blocks;

  return (
    Object.keys(blocks) as LanguageCode[]
  ).reduce<MultilingualBlockContentValue>((newBlocks, key) => {
    const values = blocks[key]; //array or "type"

    if (typeof values === "string") {
      return {
        ...newBlocks,
        [key]: values
      };
    }

    const newValue = values?.map((value) => {
      const { markDefs, history } = (value.markDefs || []).reduce<{
        markDefs: PortableTextObject[];
        history: Record<string, string>;
      }>(
        (acc, def) => {
          const newKey = nanoid();
          const newMark = { ...def, _key: newKey } satisfies PortableTextObject;

          return {
            ...acc,
            history: {
              ...acc.history,
              [def._key]: newKey
            },
            markDefs: [...acc.markDefs, newMark]
          };
        },
        { history: {}, markDefs: [] }
      );

      return {
        ...value,
        _key: nanoid(),
        children: value.children.map((x) => ({
          ...x,
          _key: nanoid(),
          marks: (x.marks as string[]).map((m) => history[m] || m)
        })),
        markDefs
      };
    });

    return {
      ...newBlocks,
      [key]: newValue
    };
  }, {} as MultilingualBlockContentValue);
};
