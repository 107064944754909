const links = [
  { url: "/api/v1/shoreExcursions", name: "Shorexes" },
  { url: "/api/v1/skipsynchitems", name: "Skip Sync Items" },
  { url: "/api/v1/shoreExcursions/Itinerary", name: "Shorex Itineraries" },
  { url: "/api/v1/hotels/supplier", name: "Hotels" },
  { url: "/api/v1/hotels/supplier/Itinerary", name: "Itineraries" },
  { url: "/api/v1/specialvoyages/hotels", name: "Special Voyage Hotels" },
  { url: "/api/v1/landpackages", name: "Land Packages" },
  { url: "/api/v1/landpackages/Itinerary", name: "Itinerary Land Packages" },
  { url: "/api/v1/specialvoyages/lands", name: "Special Voyage Land Packages" },
  { url: "/api/v1/voyages", name: "Voyages" },
  {
    url: "/api/v1/voyages/changesFrom/2019-01-01?is_combo=true",
    name: "Combo Voyages"
  },
  {
    url: "/api/v1/itineraries?after=20150101&before=20251231",
    name: "Itineraries"
  },
  { url: "/api/v1/gateways", name: "Gateways" },
  { url: "/api/v1/cities", name: "Cities" },
  { url: "/api/v1/destinations", name: "Destinations" },
  { url: "/api/v3/prices", name: "Prices" },
  { url: "/api/v1/specialOffers", name: "Special Offers" },
  { url: "/api/v1/voyageSpecialOffers", name: "Voyage Special Offers" },
  { url: "/api/v1/countries", name: "Countries" },
  { url: "/api/v1/ships", name: "Ships" },
  { url: "/api/v1/gateways/zones", name: "Gateway Zones" },
  { url: "/api/v1/promotions?is_actual=N", name: "Promotions (Future)" },
  { url: "/api/v1/promotions", name: "Promotions (Existing)" },
  {
    url: "/api/v1/promotions/airpromo?is_actual=N",
    name: "Air Promos (Future)"
  },
  { url: "/api/v1/promotions/airpromo", name: "Air Promos (Existing)" },

  {
    url: "/api/v3/specialVoyages?currency_cod=USD",
    name: "Special Voyages (USD)"
  },
  {
    url: "/api/v3/specialVoyages?currency_cod=EUR",
    name: "Special Voyages (EUR)"
  },
  {
    url: "/api/v3/specialVoyages?currency_cod=GBP",
    name: "Special Voyages (GBP)"
  },
  {
    url: "/api/v3/specialVoyages?currency_cod=CAD",
    name: "Special Voyages (CAD)"
  },
  {
    url: "/api/v3/specialVoyages?currency_cod=AUD",
    name: "Special Voyages (AUD)"
  },

  { url: "/api/v1/brochures", name: "Brochures" },

  { url: "/api/v1/termconditions/paymentterms", name: "T&C Payment Terms" },

  { url: "/api/v1/termconditions/penaltymodels", name: "T&C Penalty Models" },

  { url: "/api/v1/termconditions/penalties", name: "T&C Penalties" },

  {
    url: "/api/v1/promotions/inclusiveitem?is_actual=N",
    name: "Promotions Inclusive Item (New)"
  },
  {
    url: "/api/v1/promotions/inclusiveitem",
    name: "Promotions Inclusive Item (Existing)"
  }
];

export default links;
