import { FaAnchor } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  name: "portData",
  title: "Port Data",
  type: "document",
  icon: FaAnchor,
  orderings: [
    {
      title: "Name",
      name: "nameAsc",
      by: [{ field: "name.en", direction: "asc" }]
    }
  ],
  preview: {
    select: {
      name: "name.en",
      code: "code",
      country: "country.name.en"
    },
    prepare(value) {
      return {
        title: value.code ? `${value.name} (${value.code})` : value.name,
        subtitle: value.country
      };
    }
  },
  fields: [
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      validation: (Rule) => Rule.required(),
      readOnly: true
    },
    {
      name: "code",
      title: "Code",
      type: "string",
      readOnly: true
    },
    {
      name: "resCode",
      title: "Res Code",
      type: "string",
      readOnly: true
    },
    {
      name: "iTravelCode",
      title: "iTravel Code",
      type: "string",
      readOnly: true
    },
    {
      name: "source",
      title: "Source",
      type: "string",
      readOnly: true
    },
    {
      name: "country",
      title: "Country",
      type: "reference",
      to: [{ type: "country" }],
      validation: (Rule) => Rule.required(),
      readOnly: true
    },
    {
      name: "shortDescription",
      title: "Short Description (English)",
      description: "Used in itinerary etc.",
      type: "text",
      readOnly: true
    }
  ]
});
