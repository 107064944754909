import { defineField } from "sanity";

export default [
  defineField({
    title: "Metadata",
    name: "openGraph",
    description:
      "These values populate meta tags, and are used by social media to add images/descriptions to links.",
    type: "openGraph",
    options: {
      collapsible: true,
      collapsed: true
    }
  }),
  defineField({
    title: "Tracking",
    name: "tracking",
    description:
      "Info about tracking this entity in analytics (Adobe/Google Analytics)",
    type: "tracking",
    options: {
      collapsible: true,
      collapsed: true
    }
  }),
  defineField({
    title: "Exclude from search engines",
    description: "Prevent search engines from indexing this page",
    name: "excludeFromSitemap",
    type: "boolean"
  }),
  defineField({
    title: "Exclude this page from website search",
    description:
      "Prevent this page from showing in the internal website search results",
    name: "excludeFromInternalSearch",
    type: "boolean"
  })
];
