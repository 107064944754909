import React from "react";
import { VisibilityStatus } from "./CruiseTrafficLights";

//const scale = 1 / 0.375;
const BlackColor = "#000000";
const DisabledColor = "#4A4A4A";
const YellowColor = "#F8E71C";
const GreenColor = "#7ED321";
const OrangeColor = "#F5A623";
const RedColor = "#D0021B";

const fillColorByStatus: Record<VisibilityStatus, string> = {
  Loading: DisabledColor,
  Error: RedColor,
  Unknown: YellowColor,
  Visible: GreenColor,
  NotVisible: OrangeColor
};

type Props = React.SVGProps<SVGSVGElement> & {
  size: number;
  visible: boolean | undefined;
  status: VisibilityStatus;
};

const TrafficLight: React.FC<Props> = ({
  size = 40,
  visible,
  status,
  ...props
}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox={"0 0 50 50"}
    version="1.1"
    {...props}
  >
    <defs>
      <circle id="redCirclePath" cx="25" cy="25" r="20" />

      <filter
        x="-50%"
        y="-50%"
        width="200%"
        height="200%"
        filterUnits="objectBoundingBox"
        id="shadowFilter"
      >
        <feGaussianBlur
          stdDeviation="3"
          in="SourceAlpha"
          result="shadowBlurInner1"
        />

        <feOffset
          dx="0"
          dy="4"
          in="shadowBlurInner1"
          result="shadowOffsetInner1"
        />

        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
          result="shadowInnerInner1"
        />

        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowInnerInner1"
        />
      </filter>
    </defs>

    <g>
      <rect fill={BlackColor} x="0" y="0" width="50" height="50" rx="8" />

      <use
        fill={fillColorByStatus[status]}
        fillRule="evenodd"
        xlinkHref="#redCirclePath"
      />

      <use
        fill="black"
        fillOpacity="1"
        filter="url(#shadowFilter)"
        xlinkHref="#redCirclePath"
      />
    </g>
  </svg>
);

export default TrafficLight;
