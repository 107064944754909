import { defineField } from "sanity";
import {
  DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES,
  SUPPORTED_LANGUAGE_IDS,
  LocalizedValueType
} from "../../common/localization";

const NON_DEFAULT_LANGUAGES = SUPPORTED_LANGUAGES.filter(
  (lang) => lang !== DEFAULT_LANGUAGE
);

// const defaultLanguageDefined = (context) =>
//   !!context.parent[DEFAULT_LANGUAGE.id];

// const allOtherLanguagesNotDefined = (context) =>
//   NON_DEFAULT_LANGUAGES.every((lang) => !context.parent[lang.id]);

const firstValidTranslation = (selection: MultilingualStringValue) =>
  SUPPORTED_LANGUAGE_IDS.reduce<string | undefined>(
    (s, i) => s || selection[i],
    ""
  ) || "";

// const defaultLanguageShouldBeProvided = (item, context) =>
//   defaultLanguageDefined(context) ||
//   allOtherLanguagesNotDefined(context) ||
//   "English should be provided";

export type MultilingualStringValue = {
  _type: "multilingualString";
} & LocalizedValueType<string>;

export default defineField({
  name: "multilingualString",
  title: "Multi-lingual String",
  type: "object",
  fields: [
    defineField({
      title: DEFAULT_LANGUAGE.title,
      name: DEFAULT_LANGUAGE.id,
      type: "string"
    }),
    ...NON_DEFAULT_LANGUAGES.map((lang) =>
      defineField({
        title: lang.title,
        name: lang.id,
        type: "string"
      })
    )
  ],
  // preview: {
  //   select: SUPPORTED_LANGUAGES_MAP,
  //   prepare(translations: MultilingualStringValue) {
  //     return {
  //       title: firstValidTranslation(translations)
  //     };
  //   }
  // },
  toReadableText: (multilingualString: MultilingualStringValue) =>
    firstValidTranslation(multilingualString)
});

export function multilingualStringPreview(
  multilingualString: MultilingualStringValue
) {
  return firstValidTranslation(multilingualString);
}
