import { defineField } from "sanity";
import { multilingualSlug, multilingualString } from "../base";

export const slugField = defineField({
  name: "slug",
  type: multilingualSlug.name,
  description:
    "This is the last part of the path, e.g. 'crucero-antardida' in 'es/destinos/crucero-antartida'",
  title: "Slug",
  validation: (Rule) => Rule.required()
});

export const pathField = defineField({
  name: "path",
  type: multilingualString.name,
  title: "Path",
  readOnly: true
});
