import { defineType } from "sanity";
import { multilingualStringPreview } from "../../base/multilingualString";
import mainMenuItem from "./mainMenuItem";
import { contentFilterPreview } from "../../../common/localization";

export default defineType({
  name: "locationSpecificMainMenuItem",
  title: "Location Specific Item",
  type: "object",
  preview: {
    select: {
      page: "page.card",
      filters: "filters"
    },
    prepare({ page, filters }) {
      const { title } = page || {};
      return {
        title: multilingualStringPreview(title),
        subtitle: contentFilterPreview(filters)
      };
    }
  },
  fields: [
    ...mainMenuItem.fields,
    {
      name: "filters",
      title: "Filters",
      description:
        "If present, the menu item will only appear for the below selected filters",
      type: "array",
      of: [
        {
          type: "locationFilter"
        },
        {
          type: "airNonAirFilter"
        }
      ]
    }
  ]
});
