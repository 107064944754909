import { FaBuffer } from "react-icons/fa";
import allPageBuilderSections from "../../common/allPageBuilderSections";
import { defineField, defineType } from "sanity";

export default defineType({
  name: "sharedSection",
  title: "Shared Section",
  type: "document",
  icon: FaBuffer,
  preview: {
    select: {
      title: "slug.current"
    }
  },
  fields: [
    {
      name: "slug",
      title: "Slug",
      description: "don't change as it's used as a reference",
      type: "slug",
      validation: (Rule) => Rule.required()
    },
    defineField({
      name: "sections",
      type: "array",
      title: "Page sections",
      description: "Add, edit, and reorder sections",
      validation: (Rule) => Rule.length(1),
      of: allPageBuilderSections()
    })
  ]
});
