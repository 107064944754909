import React, { useCallback } from "react";
import { Select, Container } from "@sanity/ui";
import { StringInputProps, set, unset } from "sanity";

const HexColorInput: React.FC<StringInputProps> = (props) => {
  const { onChange, value = "", elementProps } = props;

  const handleChange: React.FormEventHandler<HTMLSelectElement> = useCallback(
    (event) => {
      const inputValue = event.currentTarget.value;
      onChange(inputValue ? set(inputValue) : unset());
    },
    [onChange]
  );

  return (
    <Container>
      <Select {...elementProps} onChange={handleChange} value={value || ""}>
        <option value={"c84456"} className={"otium-suite-color"}>
          Otium (C84456)
        </option>

        <option value={"8da27e"} className={"owner-suite-color"}>
          Owner (8da27e)
        </option>

        <option value={"ada4ad"} className={"grand-suite-color"}>
          Grand (ada4ad)
        </option>

        <option value={"E5A4A8"} className={"master-suite-color"}>
          Master (E5A4A8)
        </option>

        <option value={"948EB4"} className={"signature-suite-color"}>
          Signature (948EB4)
        </option>

        <option value={"d38877"} className={"royal-suite-color"}>
          Royal (d38877)
        </option>

        <option value={"e5c78d"} className={"silver-suite-color"}>
          Silver (e5c78d)
        </option>

        <option value={"B9E5FB"} className={"junior-grande-suite-color"}>
          Junior Grande (B9E5FB)
        </option>

        <option value={"55C2B8"} className={"premium-medallion-suite-color"}>
          Premium Medallion (55C2B8)
        </option>

        <option value={"dcd3de"} className={"medallion-suite-2-color"}>
          Medallion (dcd3de)
        </option>

        <option value={"C9E4BB"} className={"premium-veranda-suite-color"}>
          Premium Veranda (C9E4BB)
        </option>

        <option value={"77ccca"} className={"deluxe-veranda-suite-color"}>
          Deluxe Veranda (77ccca)
        </option>

        <option value={"f8eab3"} className={"superior-veranda-suite-color"}>
          Superior Veranda (f8eab3)
        </option>

        <option value={"aeafb0"} className={"classic-veranda-suite-color"}>
          Classic Veranda (aeafb0)
        </option>

        <option value={"BD9554"} className={"panorama-suite-color"}>
          Panorama (BD9554)
        </option>

        <option value={"33A3DC"} className={"veranda-suite-color"}>
          Veranda (33A3DC)
        </option>

        <option value={"DFC3DE"} className={"vista-suite-color"}>
          Vista (DFC3DE)
        </option>

        <option value={"FCBB76"} className={"view-suite-color"}>
          View (FCBB76)
        </option>

        <option value={"F69679"} className={"explorer-suite-color"}>
          Explorer (F69679)
        </option>

        <option value={"99C49F"} className={"adventure-suite-color"}>
          Adventure (99C49F)
        </option>
      </Select>

      <svg width="100" height="100">
        <circle r="20" cx="25" cy="55" fill={`#${value}`} />
      </svg>
    </Container>
  );
};

export default HexColorInput;
