import { BiArea } from "react-icons/bi";
import { metadataWithoutParent } from "../page-fragments";
import { defineType } from "sanity";

export default defineType({
  name: "publicAreaCategory",
  title: "Public Area Category",
  type: "document",
  icon: BiArea,
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  orderings: [
    {
      title: "Name",
      name: "nameAsc",
      by: [{ field: "name.en", direction: "asc" }]
    }
  ],
  preview: {
    select: {
      title: "name.en",
      images: "images"
    },
    prepare: ({ title, images }) => ({ title, media: images[0] })
  },
  fields: [
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "description",
      title: "Description",
      type: "openingText"
    },
    {
      name: "images",
      title: "Images",
      type: "array",
      of: [{ type: "imageWithCaption" }]
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: metadataWithoutParent.name,
      group: "seo"
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    }
  ]
});
