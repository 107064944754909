import { PortableTextObject, PortableTextTextBlock, defineField } from "sanity";
import {
  SUPPORTED_LANGUAGES,
  DEFAULT_LANGUAGE,
  LocalizedValueType
} from "../../common/localization";

export type MultilingualBlockContentValue = {
  _type: "multilingualBlockContent";
} & LocalizedValueType<PortableTextTextBlock[]>;

const multilingualBlockContent = defineField({
  name: "multilingualBlockContent",
  title: "Multi-lingual Block Content",
  type: "object",
  fields: SUPPORTED_LANGUAGES.map((lang) =>
    defineField({
      title: lang.title,
      name: lang.id,
      type: "blockContent"
    })
  )
});
export default multilingualBlockContent;

const defaultContentBlockAt =
  (multilingualBlockContent: MultilingualBlockContentValue) =>
  (blockIndex: number): any =>
    multilingualBlockContent?.[DEFAULT_LANGUAGE.id]?.[0]?.children?.[blockIndex]
      ?.text;

const truncateAt = (length: number) => (text: string) =>
  text.length > length ? `${text.substring(0, length - 3)}...` : text;

const blockContentPreview = (maxLength: number, parts: PortableTextObject[]) =>
  truncateAt(maxLength)(parts.filter(Boolean).join(" "));

export const multilingualBlockContentPreview =
  (maxLength: number) =>
  (multilingualBlockContent: MultilingualBlockContentValue) => {
    const parts = [0, 1, 2, 3].map(
      defaultContentBlockAt(multilingualBlockContent)
    );

    return blockContentPreview(maxLength, parts);
  };
