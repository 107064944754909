import { defineType } from "sanity";

export default defineType({
  name: "menuGroup",
  title: "Menu Group",
  type: "object",
  preview: {
    select: {
      name: "target.title.en"
    },
    prepare({ name }) {
      return {
        title: name
      };
    }
  },
  fields: [
    {
      name: "target",
      title: "Target",
      type: "reference",
      validation: (Rule) => Rule.required(),
      to: [
        { type: "page" },
        { type: "landingPage" },
        { type: "termsAndConditions" },
        { type: "offersTermsAndConditions" }
      ]
    },
    {
      name: "menuItems",
      title: "Menu Items",
      type: "array",
      of: [
        {
          type: "menuItem"
        },
        {
          type: "linkCTA"
        }
      ]
    }
  ]
});
