import { defineArrayMember, defineType } from "sanity";
import { filterableNumber } from "../base";

export default defineType({
  type: "object",
  name: "numberToken",
  title: "Number token",
  preview: {
    select: {
      name: "name",
      values: "values"
    },
    prepare({ name, values }) {
      return {
        title: name,
        subtitle: filterableNumber.toReadableTextArray(values)
      };
    }
  },
  fields: [
    {
      name: "name",
      type: "string",
      title: "Name",
      validation: (Rule) => Rule.required()
    },
    {
      name: "values",
      type: "array",
      of: [defineArrayMember({ type: filterableNumber.name })]
    }
  ]
});
