import { filterableContentOf } from "../../utils/filterableContentOf";

const toReadableTextArray = (filterableDate: any) =>
  filterableDate.map(toReadableText).join(", ");

const filterableDate = {
  ...filterableContentOf("date", (content: any) => `${content}`),
  title: "Date",
  toReadableText,
  toReadableTextArray
};

function toReadableText(date: any): string {
  return `${filterableDate.toString(
    //@ts-ignore
    date
    //@ts-ignore
  )}/${filterableDate.filtersAsReadableText(date)}`;
}

export default filterableDate;
