import { defineType } from "sanity";

export default defineType({
  name: "wordPressArticle",
  title: "Word Press Article",
  type: "object",
  preview: {
    select: {
      title: "title",
      subtitle: "url"
    }
  },
  fields: [
    {
      name: "id",
      title: "Id",
      type: "number"
    },
    {
      name: "title",
      title: "Title",
      type: "string"
    },
    {
      name: "url",
      title: "url",
      type: "string"
    }
  ]
});
