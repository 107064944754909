import { Stack, Heading, Flex, Label } from "@sanity/ui";
import React from "react";
import TrafficLight from "./trafficLight";

export type VisibilityStatus =
  | "Loading"
  | "Error"
  | "Unknown"
  | "Visible"
  | "NotVisible";

export type Visibility = {
  title: string;
  visible: boolean | undefined;
  status: VisibilityStatus;
};

const footnoteByStatus: Record<VisibilityStatus, string> = {
  Loading: " ",
  Error: "Error",
  Unknown: "Unknown",
  Visible: "Visible",
  NotVisible: "Not Visible"
};

const CruiseStatusTrafficLights: React.FC<{
  cruisePipelineVisibility: Visibility[] | undefined;
}> = ({ cruisePipelineVisibility }) => {
  if (!cruisePipelineVisibility) return <></>;

  return (
    <Stack space={5}>
      <Heading size={1} align="center">
        Cruise Status
      </Heading>
      <Flex justify="center" gap={3}>
        {cruisePipelineVisibility.map((item) => (
          <Stack key={item.title} space={2}>
            <Label size={3} align="center">
              {item.title}
            </Label>
            <TrafficLight
              size={100}
              visible={item.visible}
              status={item.status}
            />
            <Label size={1} align="center" style={{ textTransform: "none" }}>
              {footnoteByStatus[item.status]}
            </Label>
          </Stack>
        ))}
      </Flex>
    </Stack>
  );
};

export default CruiseStatusTrafficLights;
