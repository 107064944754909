import { defineType } from "sanity";

export default defineType({
  title: "Brochure Section",
  name: "cruiseBrochureSection",
  type: "object",
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent"
    },
    {
      name: "brochureGroup",
      title: "Brochure",
      type: "reference",
      to: [{ type: "brochureGrouping" }]
    }
  ],
  preview: {
    select: {
      title: "title.en"
    },
    prepare({ title }) {
      return {
        title: title || ""
      };
    }
  }
});
