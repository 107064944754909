import { RiLayoutRowFill } from "react-icons/ri";
import { defineArrayMember, defineField, defineType } from "sanity";
import { contentsTitles } from "../../utils/preview";

export default defineType({
  title: "5. Featured Content Rows",
  name: "featuredContentRows",
  type: "object",
  icon: RiLayoutRowFill,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Section Heading",
      type: "sectionTitle",
      options: {
        collapsable: true
      }
    },
    defineField({
      name: "rows",
      title: "Rows",
      type: "array",
      of: [defineArrayMember({ type: "featuredContentRow" })]
    })
  ],
  preview: {
    select: {
      title: "heading.title.en",
      contents: "rows"
    },
    prepare(selection) {
      const { title, contents } = selection;
      return {
        title: title || contentsTitles(contents) || "(Featured Content Rows)",
        subtitle: `Rows: ${contents?.length}`
      };
    }
  }
});
