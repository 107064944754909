import { BsAspectRatio } from "react-icons/bs";
import { locationFilter } from "../base";
import customOfferBoxItem from "../objects/customOfferBoxItem";
import { defineType } from "sanity";

export default defineType({
  title: "5. Custom Offer Box",
  name: "customOfferBox",
  type: "object",
  icon: BsAspectRatio,
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualString"
    },
    {
      name: "subtitle",
      title: "Subtitle",
      type: "multilingualString"
    },
    {
      name: "items",
      title: "Items",
      type: "array",
      of: [{ type: customOfferBoxItem.name }]
    },
    {
      name: "disclaimer",
      type: "multilingualBlockContent",
      title: "Disclaimer"
    },
    {
      name: "locationFilters",
      title: "Location Filters",
      type: "array",
      of: [
        {
          type: locationFilter.name
        }
      ]
    }
  ],
  preview: {
    select: {
      title: "title.en",
      subtitle: "subtitle.en"
    },
    prepare(selection) {
      const { title, subtitle } = selection;
      return {
        title: title || "(Custom Offer Box)",
        subtitle
      };
    }
  }
});
