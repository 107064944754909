import React, { useCallback, useMemo, useState } from "react";
import { useClient } from "sanity";
import { DashboardWidgetContainer } from "@sanity/dashboard";
import { Spinner, Stack, Text, TextInput } from "@sanity/ui";
import { useDiagnosticData } from "./useShopApi";
import { UseQueryResult } from "react-query";
import { SanityCruiseQuery, useSanityCruiseData } from "./useSanityCruiseData";
import DataBlock, {
  buildCruiseDiagnosticValueHtml,
  DiagnosticValue
} from "./DataBlock";
import CruiseStatusTrafficLights, { Visibility } from "./CruiseTrafficLights";
import CruiseDiagnosticImporterLogs from "./CruiseDiagnosticImporterLogs";
import {
  buildCruiseDiagnosticValueString,
  CruiseDiagnosticResponse
} from "@silversea-ssc/dotcom-api-types";
import { match, P } from "ts-pattern";
import { ComboType } from "@silversea-ssc/dotcom-read-model-types";

const linkTo = (url: string, label?: string) =>
  `<a href="${url}">${label ?? url}</a>`;

const message = (message: string) => `<p>${message}</p>`;

type Props = {
  silverseaApiRootUrl?: string;
};

export const CruiseCodeSearch: React.FC<Props> = ({ silverseaApiRootUrl }) => {
  const url = new URL(window.location.href);
  const [cruiseCode, setCruiseCode] = useState(
    url.searchParams.get("cruiseCode") ?? ""
  );
  const client = useClient({ apiVersion: "2021-10-21" });

  const { importerData, searchData, shopApiData, importerLogsData } =
    useDiagnosticData({
      cruiseCode: cruiseCode,
      silverseaApiRootUrl
    });

  const sanityData = useSanityCruiseData(cruiseCode, client);
  const sanityCruiseData = computeSanityCruiseData(sanityData.data);
  const shopApiLinks = computeShopApiLinks(sanityData.data);

  const cruisePipelineVisibility = useMemo(
    () => [
      computeStatus("Shop API", shopApiData, cruiseDiagnosticVisibility),
      computeStatus("Importer", importerData, cruiseDiagnosticVisibility),
      computeStatus("Sanity", sanityData, (x) => x.isVisible),
      computeStatus("Search", searchData, cruiseDiagnosticVisibility)
    ],
    [importerData, sanityData.data, searchData, shopApiData]
  );

  const isLoading = [importerData, searchData, shopApiData, sanityData].some(
    (x) => x.isLoading
  );
  const withData = sanityCruiseData && sanityCruiseData.length > 0;

  const onBlur = useCallback(() => {
    if (!cruiseCode) return;
    const newURL = new URL(window.location.href);
    newURL.searchParams.set("cruiseCode", cruiseCode);
    history.pushState({}, "", newURL);
  }, [cruiseCode]);

  return (
    <DashboardWidgetContainer header="Cruise Code Search">
      <Stack padding={4} space={[3, 3, 4, 5]}>
        <TextInput
          iconRight={
            <React.Fragment>
              {isLoading && <Spinner style={{ padding: 0 }} />}
            </React.Fragment>
          }
          fontSize={[2, 2, 3, 4]}
          onChange={(event) => {
            const value = event.currentTarget.value;
            setCruiseCode(value);
          }}
          onBlur={onBlur}
          padding={[3, 3, 4]}
          placeholder="Search by Cruise Code"
          value={cruiseCode}
        />
        {!withData && (
          <Text size={4} weight="bold" align="center">
            Please enter a valid cruise code
          </Text>
        )}

        <CruiseStatusTrafficLights
          cruisePipelineVisibility={cruisePipelineVisibility}
        />

        <table cellSpacing="21px">
          <DataBlock title="Shop API" rows={shopApiData.data} />
          <DataBlock title="Importer" rows={importerData.data} />
          <DataBlock title="Sanity" rows={sanityCruiseData} />
          <DataBlock title="Shop API Links" rows={shopApiLinks} />
        </table>

        <CruiseDiagnosticImporterLogs logs={importerLogsData.data} />
      </Stack>
    </DashboardWidgetContainer>
  );
};

const cruiseDiagnosticVisibility = (data: CruiseDiagnosticResponse) => {
  const item = data.find((x) => x.title === "Visible");
  if (!item || item.type !== "bool") return undefined;
  return item.value;
};

function computeStatus<T>(
  title: string,
  response: UseQueryResult<T | undefined>,
  isVisible: (data: T) => boolean | undefined
): Visibility {
  const status = match(response)
    .with({ status: "success" }, ({ data }) => {
      if (data === undefined || data === null) return "Unknown" as const;
      const result = isVisible(data);
      if (result === undefined) return "Unknown" as const;
      return result ? ("Visible" as const) : ("NotVisible" as const);
    })
    .with({ status: P.union("loading", "idle") }, () => "Loading" as const)
    .with({ status: "error" }, () => "Error" as const)
    .exhaustive();

  return {
    title: title,
    visible: status === "Loading" ? undefined : status === "Visible",
    status
  };
}

const computeSanityCruiseData = (
  results: SanityCruiseQuery | undefined
): DiagnosticValue[] | undefined => {
  const props = match(results)
    .with({ _type: "cruiseData", _id: P.string }, sanityCruiseData)
    .with(
      { _type: "specialVoyageData", cruiseCode: P.string, segments: P.array() },
      (x) => sanitySpecialVoyageData(x)
    )
    .otherwise(() => undefined);

  if (!props) return undefined;

  const { isAvailable, isVisible } = results || {};
  const { cruise, data, segments } = props;

  return [
    buildCruiseDiagnosticValueHtml(
      `<a href="${cruise.href}">${cruise.label}</a>`,
      "Cruise"
    ),
    buildCruiseDiagnosticValueHtml(
      `<a href="${data.href}">${data.label}</a>`,
      "Cruise Data"
    ),
    buildCruiseDiagnosticValueString(
      `${isVisible ? `Visible` : `Not Visible`}, ${
        isAvailable ? `Available` : `Not Available`
      }`,
      "Status"
    ),
    ...segments.map((segment) =>
      buildCruiseDiagnosticValueHtml(linkTo(segment.href), segment.label)
    )
  ];
};

type LinkProps = { label: string; href: string };
type CruiseDataProps = {
  cruise: LinkProps;
  data: LinkProps;
  segments: readonly LinkProps[];
};

const sanityCruiseData = (
  result: { _type: "cruiseData" } & Required<Pick<SanityCruiseQuery, "_id">>
): CruiseDataProps => {
  const { _id } = result;
  const cruiseId = _id.replace(/^cruiseData-/, "");
  const sanityCruiseId = `cruise-${cruiseId}`;
  return {
    cruise: {
      label: sanityCruiseId,
      href: `/intent/edit/type=cruise;id=${sanityCruiseId}`
    },
    data: { label: _id, href: `/intent/edit/type=cruiseData;id=${_id}` },
    segments: []
  };
};

const sanitySpecialVoyageData = (
  result: { _type: "specialVoyageData" } & Required<
    Pick<SanityCruiseQuery, "cruiseCode" | "segments">
  >
): CruiseDataProps => {
  const { cruiseCode, segments } = result;
  const sanitySpecialVoyageId = `specialVoyage-${cruiseCode}`;
  const sanitySpecialVoyageDataId = `specialVoyageData-${cruiseCode}`;

  return {
    cruise: {
      label: sanitySpecialVoyageId,
      href: `/intent/edit/id=${sanitySpecialVoyageId}`
    },
    data: {
      label: sanitySpecialVoyageDataId,
      href: `/intent/edit/id=${sanitySpecialVoyageDataId}`
    },
    segments: segments.map((segment, index) => {
      const segmentUrl = new URL(window.location.href);
      segmentUrl.searchParams.set("cruiseCode", segment.cruiseCode);
      return {
        label: `Segment ${index + 1}`,
        href: segmentUrl.href
      };
    })
  };
};

const shopApiByStage: Record<string, string> = {
  qa: "https://shop.silversea.com/api",
  prod: "https://shop.silversea.com/api"
};

const shopApiBaseUrl = (stage: string | undefined) =>
  shopApiByStage[stage ?? "dev"] ?? "https://apit.silversea.com/api-qa";

const computeShopApiLinks = (
  results: SanityCruiseQuery | undefined
): DiagnosticValue[] | undefined => {
  const _id = results?._id;
  const cruiseCode = results?.cruiseCode;
  const comboType = results?.comboType ?? "Single";
  if (!_id || !cruiseCode) return undefined;
  const cruiseId = _id.replace(/^cruiseData-/, "");

  const baseUrl = shopApiBaseUrl(process.env.SANITY_STUDIO_AWS_ENV);

  const pricesByComboType: Record<ComboType, string> = {
    Single: linkTo(`${baseUrl}/v3/prices?voyage_cod=${cruiseCode}`),
    Combo: linkTo(
      `${baseUrl}/v3/specialVoyages?special_voyage_id=${cruiseCode}&currency_cod=USD`
    ),
    Grand: message("See 'Special Voyage' response"),
    World: message("See 'Special Voyage' response")
  };

  const cruisesMapper: Record<
    string,
    {
      name: string;
      voyages: string;
      voyagesItinerary: string | undefined;
      shoreExcursionsItinerary: string | undefined;
      prices: string;
    }
  > = {
    cruiseData: {
      name: "Voyage",
      voyages: linkTo(`${baseUrl}/v1/voyages?voyage_id=${cruiseId}`),
      voyagesItinerary: linkTo(`${baseUrl}/v1/voyages/${cruiseId}/itinerary`),
      shoreExcursionsItinerary: linkTo(
        `${baseUrl}/v1/shoreExcursions/Itinerary?voyage_id=${cruiseId}`
      ),
      prices: pricesByComboType[comboType]
    },
    specialVoyageData: {
      name: "Special Voyage",
      voyages: linkTo(
        `${baseUrl}/v3/specialVoyages?special_voyage_id=${cruiseCode}&currency_cod=USD`
      ),
      voyagesItinerary: undefined,
      shoreExcursionsItinerary: undefined,
      prices: pricesByComboType[comboType]
    }
  };

  const cruises = cruisesMapper[results?._type || ""];
  if (!cruises) return undefined;

  return [
    buildCruiseDiagnosticValueHtml(cruises.voyages, cruises.name),
    ...(cruises.voyagesItinerary
      ? [buildCruiseDiagnosticValueHtml(cruises.voyagesItinerary, "Itinerary")]
      : []),
    ...(cruises.shoreExcursionsItinerary
      ? [
          buildCruiseDiagnosticValueHtml(
            cruises.shoreExcursionsItinerary,
            "Shorexes"
          )
        ]
      : []),
    buildCruiseDiagnosticValueHtml(cruises.prices, "Prices"),
    buildCruiseDiagnosticValueHtml(
      linkTo(`${baseUrl}/v1/brochures?envelope=true&page=1&per_page=1000`),
      "Brochures"
    ),
    buildCruiseDiagnosticValueHtml(
      linkTo(`${baseUrl}/v1/promotions/inclusiveitem?voyage_cod=${cruiseCode}`),
      "Inclusions"
    )
  ];
};
