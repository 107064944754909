import { defineType } from "sanity";
import videoWithPosterImage from "../videoWithPosterImage";

export default defineType({
  name: "videoCTA",
  type: "object",
  title: "Video CTA",
  fields: [
    {
      name: "label",
      type: "multilingualString",
      description: 'leave empty for "View Video"'
    },
    {
      name: "video",
      title: "Video",
      type: videoWithPosterImage.name,
      validation: (Rule) => Rule.required()
    },
    {
      name: "type",
      type: "buttonType"
    }
  ],
  preview: {
    select: {
      title: "label.en"
    },
    prepare({ title }) {
      return {
        title: title || "View Video"
      };
    }
  }
});
