import { defineType } from "sanity";

const aligns = [
  { title: "Left", value: "left" },
  { title: "Right", value: "right" },
  { title: "Center", value: "center" }
];

const widths = [
  { title: "1/4", value: "quarter" },
  { title: "1/3", value: "third" },
  { title: "1/2", value: "half" },
  { title: "Full", value: "full" }
];

const titleFromValue =
  (list: { title: string; value: string }[]) => (value: string) =>
    list.find((x) => x.value === value)?.title || "-";

export default defineType({
  name: "inlineImage",
  type: "image",
  title: "Image",
  options: {
    hotspot: true
  },
  preview: {
    select: {
      title: "alt.en",
      width: "width",
      align: "align",
      imageUrl: "asset.url",
      media: "asset"
    },
    prepare: ({ title, align, width, imageUrl, media }) => ({
      title,
      imageUrl,
      media,
      description: `Width: ${titleFromValue(widths)(
        width
      )}, Align: ${titleFromValue(aligns)(align)}`
    })
  },
  fields: [
    {
      name: "alt",
      type: "multilingualString",
      title: "Alternative text",
      description: "Important for SEO and accessibility.",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "width",
      type: "string",
      title: "Width",
      options: {
        list: widths,
        layout: "radio",
        direction: "horizontal"
      }
    },
    {
      name: "align",
      type: "string",
      title: "Align",
      options: {
        list: aligns,
        layout: "radio",
        direction: "horizontal"
      }
    }
  ]
});
