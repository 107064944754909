import { filtersAsReadableText } from "../../utils/filterableContentOf";
import airConditions from "./airConditions";
import locationFilter from "./locationFilter";
import airNonAirFilter from "./airNonAirFilter";
import { defineField } from "sanity";
import { currencies } from "../../common/currencies";

const toReadableTextArray = (filterableNumbers: any) =>
  (filterableNumbers || []).map(toReadableText).join(", ");

const filterableCurrency = defineField({
  name: `filterableCurrency`,
  title: "Currency",
  type: "object",
  preview: {
    select: {
      conditionFilters: "conditionFilters",
      locationFilters: "locationFilters",
      value: "value",
      currency: "currency"
    },
    prepare({ value, currency, conditionFilters, locationFilters }) {
      return {
        title: `${value} ${currency}`,
        subtitle: filtersAsReadableText(conditionFilters, locationFilters)
      };
    }
  },
  fields: [
    {
      name: "value",
      title: "Value",
      type: "number",
      options: {
        collapsed: false,
        collapsable: true
      }
    },
    {
      name: "currency",
      title: "Currency",
      type: "string",
      options: {
        collapsed: false,
        collapsable: true,
        required: true,
        list: currencies
      }
    },
    {
      name: "conditionFilters",
      title: "Condition Filters",
      type: airConditions.name,
      hidden: false
    },
    {
      name: "locationFilters",
      title: "Location Filters",
      description:
        "The content above will be shown if the user's country matches any of the following (unless there is more specific content available for that country).",
      type: "array",
      of: [
        {
          type: locationFilter.name
        },
        {
          type: airNonAirFilter.name
        }
      ]
    }
  ],
  // @ts-ignore
  toString: (filterableContent: any) => filterableContent?.content,
  filtersAsReadableText: (filterableContent: any) =>
    filtersAsReadableText(
      filterableContent?.conditionFilters,
      filterableContent?.locationFilters
    ),
  toReadableText,
  toReadableTextArray
});

function toReadableText(number: string): string {
  return `${filterableCurrency.toString(
    //@ts-ignore
    number
    //@ts-ignore
  )}/${filterableCurrency.filtersAsReadableText(number)}`;
}

export default filterableCurrency;
