import { useQuery } from "react-query";
import { SanityClient } from "sanity";

export type SanityComponentSearchQuery = {
  _id: string;
  _type: string;
  _createdAt: string;
  _updatedAt: string;
  _rev: string;
};

export const useSanityComponentSearch = (
  componentKey: string,
  client: SanityClient
) => {
  console.debug("do query", componentKey);
  return useQuery({
    queryKey: ["sanity-sections", componentKey],
    enabled: !!componentKey && componentKey.length > 0,
    queryFn: async () => {
      const result = await client.fetch<SanityComponentSearchQuery[]>(
        `*[
            _type in ["page", "landingPage"] &&
            count((sections[]._type)[@ in [$componentKey]]) > 0
           
           ] {
            _id,
            _type,
            _createdAt, _updatedAt, _rev
          }`,
        { componentKey }
      );

      return result;
    }
  });
};
