import { RiPagesLine } from "react-icons/ri";
import { defineField, defineType } from "sanity";

export default defineType({
  name: "microContentExcursions",
  title: "Excursions",
  type: "document",
  icon: RiPagesLine,

  fields: [
    defineField({
      name: "features",
      title: "Features",
      type: "array",
      of: [{ type: "microContentExcursionFeature" }]
    }),
    defineField({
      name: "activityLevels",
      title: "Activity Levels",
      type: "array",
      of: [{ type: "microContentExcursionActivityLevel" }]
    }),
    defineField({
      name: "accessibilityLevels",
      title: "Accessibility Levels",
      type: "array",
      of: [{ type: "microContentExcursionAccessibilityLevel" }]
    })
  ]
});
