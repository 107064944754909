import { LanguageFilterConfig } from "@sanity/language-filter";
import { SUPPORTED_LANGUAGES } from "../common/localization";
import { isPresent } from "../utils/checks";
import * as contentTypes from "../schemas/content-types";
import { isKeyedObject } from "sanity";

const objectBasedMultinlingualTypes = [
  "multilingualBlockContent",
  "multilingualSlug",
  "multilingualString",
  "multilingualText",
  "multilingualUrl",
  "multilingualFile"
];

export default {
  supportedLanguages: SUPPORTED_LANGUAGES,
  defaultLanguages: ["en"],
  documentTypes: Object.keys(contentTypes),
  filterField: (enclosingType, field, selectedLanguageIds) => {
    if (
      enclosingType.jsonType === "object" &&
      enclosingType.name.startsWith("internationalizedArray") &&
      "kind" in field
    ) {
      const language = isKeyedObject(field.field.path.at(-2))
        ? (field.field.path.at(-2) as any)?._key
        : null;

      return language ? selectedLanguageIds.includes(language) : false;
    }

    const isMultilingualObject = objectBasedMultinlingualTypes.includes(
      enclosingType.name
    );

    return (
      isPresent(enclosingType.name) &&
      (!isMultilingualObject || selectedLanguageIds.includes(field.name))
    );
  }
} satisfies LanguageFilterConfig;
