import { defineType } from "sanity";

export default defineType({
  name: "shipSpecialFeatureContent",
  type: "object",
  title: "Ship Special Feature Content",
  fields: [
    {
      name: "title",
      type: "multilingualString"
    },
    {
      name: "description",
      type: "multilingualBlockContent"
    },
    {
      name: "images",
      title: "Images",
      description: "if more than 1, a slideshow is shown",
      type: "array",
      of: [
        {
          type: "imageWithAlt"
        },
        {
          type: "videoWithPosterImage"
        }
      ]
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  preview: {
    select: {
      title: "title.en",
      images: "images"
    },
    prepare: ({ title, images }) => ({
      title,
      media: images[0]
    })
  }
});
