import { defineField } from "sanity";
import DesignSystemIconInput from "../../components/DesignSystemIconInput";

export default defineField({
  title: "Icon",
  name: "designSystemIcon",
  type: "string",
  components: {
    input: DesignSystemIconInput
  }
});
