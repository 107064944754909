import { filterableDate } from "../base";
import { customDateTokens } from "../../utils/exclusiveOfferTokens";
import { defineArrayMember, defineField, defineType } from "sanity";

export default defineType({
  type: "object",
  name: "dateToken",
  title: "Date token",
  preview: {
    select: {
      name: "name",
      values: "values"
    },
    prepare({ name, values }) {
      return {
        title: name,
        subtitle: filterableDate.toReadableTextArray(values)
      };
    }
  },
  fields: [
    defineField({
      name: "name",
      type: "string",
      title: "Name",
      options: {
        list: customDateTokens
      },
      validation: (Rule) => Rule.required()
    }),
    defineField({
      name: "values",
      type: "array",
      of: [defineArrayMember({ type: filterableDate.name })]
    })
  ]
});
