import { FaRoute } from "react-icons/fa";
import { imageWithCaption, videoWithPosterImage } from "../objects";
import { multilingualBlockContent, multilingualString } from "../base";
import { metadataWithoutParent } from "../page-fragments";
import { defineType } from "sanity";

export default defineType({
  name: "specialVoyage",
  title: "Special Voyage",
  type: "document",
  orderings: [
    {
      title: "Cruise Code",
      name: "cruiseCodeAsc",
      by: [{ field: "data.cruiseCode", direction: "asc" }]
    }
  ],
  icon: FaRoute,
  fieldsets: [
    { name: "promotions", title: "Promotions" },
    {
      name: "map",
      title: "Map Section",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "inclusions",
      title: "Inclusions & Benefits",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "description",
      title: "Description",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "events",
      title: "Events",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "fyc",
      title: "Find your cruise",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "onboard",
      title: "Onboard",
      options: { collapsible: true, collapsed: true }
    }
  ],
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  preview: {
    select: {
      cruiseCode: "data.cruiseCode",
      departurePort: "data.departurePort.name.en",
      arrivalPort: "data.arrivalPort.name.en",
      media: "heroImage",
      departureDate: "data.departureDate",
      cruiseCodeCopy: "cruiseCode",
      name: "name.en"
    },
    prepare({
      name,
      cruiseCode,
      departurePort,
      arrivalPort,
      media,
      departureDate,
      cruiseCodeCopy
    }) {
      return {
        title: `${name ? `${name} - ` : ""} ${
          cruiseCode || `(deleted cruise) ${cruiseCodeCopy}`
        }`,
        subtitle:
          departurePort && arrivalPort
            ? `${departureDate} - ${departurePort} to ${arrivalPort}`
            : "(no data)",
        media
      };
    }
  },
  fields: [
    {
      name: "layout",
      title: "Layout",
      type: "string",
      options: {
        list: [
          { title: "Current (default)", value: "current" },
          { title: "New", value: "new" }
        ]
      }
    },
    {
      name: "data",
      title: "Data",
      type: "reference",
      to: [{ type: "specialVoyageData" }],
      readOnly: true
    },
    {
      name: "isVisible",
      title: "Visible?",
      description: "True if visible to the public website",
      type: "boolean"
    },
    {
      name: "showCovidProcedure",
      title: "Show Covid procedure",
      type: "boolean"
    },
    {
      name: "name",
      title: "Name",
      type: multilingualString.name
    },
    {
      name: "cruiseAbstract",
      title: "Cruise Abstract",
      description: "i.e. Expedition Grand Voyage 2022",
      type: multilingualString.name
    },
    {
      name: "uniquePortsCount",
      title: "Number of Ports Visited",
      type: "number"
    },
    {
      name: "uniqueCountriesCount",
      title: "Number of Countries Visited",
      type: "number"
    },
    {
      name: "heroImage",
      title: "Hero Image",
      type: "image",
      options: {
        hotspot: true
      },
      validation: (Rule) => Rule.required()
    },
    {
      fieldset: "map",
      name: "introTextTitle",
      title: "Intro Title",
      description: "intro title shown before the map",
      type: "multilingualText",
      hidden: ({ document }) => document?.layout !== "new"
    },
    {
      fieldset: "map",
      name: "introText",
      title: "Intro",
      description: "intro text shown before the map",
      type: "multilingualBlockContent",
      hidden: ({ document }) => document?.layout !== "new"
    },
    {
      fieldset: "map",
      name: "map",
      title: "Overall Map",
      description:
        "The map image for the entire voyage (including all segments)",
      type: "image"
    },
    {
      name: "segments",
      title: "Segment Maps",
      description:
        "Map images can be provided for each segment of the special voyage.",
      type: "array",
      of: [{ type: "specialVoyageSegment" }]
    },

    {
      name: "customBrochureLink",
      title: "Custom Brochure CTA link",
      type: "multilingualString",
      hidden: ({ document }) => document?.layout == "new"
    },
    {
      name: "brochure",
      title: "Brochure",
      type: "cruiseBrochureSection",
      options: { collapsible: true, collapsed: true },
      hidden: ({ document }) => document?.layout !== "new"
    },
    {
      fieldset: "description",
      name: "itineraryDescriptionTitle",
      title: "Itinerary Description Title",
      type: "multilingualString",
      hidden: ({ document }) => document?.layout !== "new"
    },
    {
      fieldset: "description",
      name: "itineraryDescription",
      title: "Itinerary Description",
      description: "Description text to appear below 'Itinerary & Excursions'",
      type: multilingualBlockContent.name
    },
    {
      fieldset: "inclusions",
      name: "slideshow",
      title: "Slideshow",
      type: "array",
      of: [
        {
          type: imageWithCaption.name
        },
        {
          type: videoWithPosterImage.name
        }
      ]
    },

    {
      name: "onboard",
      type: "onboardEnrichments",
      hidden: ({ document }) => document?.layout !== "new",
      options: { collapsible: true, collapsed: true }
    },

    {
      name: "travelDeeper",
      title: "Travel Deper",
      type: "cardSection",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "events",
      title: "Events",
      type: "array",
      of: [{ type: "cruiseEvent" }]
    },
    {
      fieldset: "inclusions",
      name: "cruiseFareInclusions",
      title: "Included in the Cruise Fare (Benefits)",
      description:
        "Bullets to appear under 'Benefits' for this special voyage.",
      type: multilingualBlockContent.name
    },
    {
      fieldset: "inclusions",
      name: "cruiseFareInclusionsFootnotes",
      title: "Footnotes to appear below the benefits",
      type: multilingualBlockContent.name
    },
    {
      name: "blog",
      title: "Blog Posts",
      type: "blogSection",
      options: { collapsible: true, collapsed: true },
      hidden: ({ document }) => document?.layout !== "new"
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    },
    {
      name: "keywords",
      title: "Searchable Keywords",
      fieldset: "fyc",
      type: "array",
      of: [
        {
          type: "multilingualString"
        }
      ]
    },
    {
      fieldset: "fyc",
      name: "starText",
      title: "Star Text",
      type: "multilingualString"
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: metadataWithoutParent.name,
      group: "seo"
    },
    {
      name: "cruiseCode",
      title: "Cruise Code",
      type: "string",
      readOnly: true
    }
  ]
});
