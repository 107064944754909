import { LayoutProps } from "sanity";
import ReactQueryProvider from "./ReactQueryProvider";
import React from "react";

const CustomStudioLayout: React.FC<LayoutProps> = (props) => {
  return (
    <React.Fragment>
      <ReactQueryProvider>{props.renderDefault(props)}</ReactQueryProvider>
    </React.Fragment>
  );
};

export default CustomStudioLayout;
