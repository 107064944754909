// We import object and document schemas
import { allTokenTypes } from "../common/allTokens";
import * as baseTypes from "./base";
import * as customPages from "./custom-pages";
import * as pageFragments from "./page-fragments";
import * as schemaObjects from "./objects";
import * as pageBuilder from "./page-builder";
import * as contentTypes from "./content-types";
import * as dataTypes from "./data-types";
import * as dccContentTypes from "./micro-content";
import { SchemaPluginOptions } from "sanity";

type SchemaItem = NonNullable<SchemaPluginOptions["types"]>;

const schema: SchemaItem = [
  ...allTokenTypes(),
  ...Object.values(baseTypes),
  ...Object.values(contentTypes),
  ...Object.values(dataTypes),
  ...Object.values(schemaObjects),
  ...Object.values(customPages),
  ...Object.values(pageFragments),
  ...Object.values(pageBuilder),
  ...Object.values(dccContentTypes)
];

export default schema;
