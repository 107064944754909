import { MdOutlineContactPhone } from "react-icons/md";
import { CustomValidator, defineType } from "sanity";

import groq from "groq";

const isUniqueId: CustomValidator<number | undefined> = (ccptId, options) => {
  const { document, getClient } = options;
  const client = getClient({ apiVersion: "2022-12-07" });

  const id = document?._id.replace(/^drafts\./, "");

  const params = {
    draft: `drafts.${id}`,
    published: id,
    ccptId: ccptId
  };

  /* groq */
  const query = groq`!defined(*[
    _type == 'ccpt' &&
    !(_id in [$draft, $published]) &&
    ccptId == $ccptId
  ][0]._id)`;

  return client.fetch(query, params);
};

export default defineType({
  name: "ccpt",
  title: "CCPT",
  type: "document",
  icon: MdOutlineContactPhone,
  orderings: [
    {
      title: "Id",
      name: "ccptIdAsc",
      by: [{ field: "ccptId", direction: "asc" }]
    },
    {
      title: "Name",
      name: "nameAsc",
      by: [{ field: "name", direction: "asc" }]
    }
  ],
  preview: {
    select: {
      title: "name",
      subtitle: "ccptId"
    }
  },
  fields: [
    {
      name: "ccptId",
      title: "Id",
      type: "number",
      validation: (Rule) =>
        Rule.required().custom(async (value: number | undefined, context) => {
          const isUnique = await isUniqueId(value, context);
          if (!isUnique) return "Another ccpt with this id is present";
          return true;
        })
    },
    {
      name: "name",
      title: "Name",
      type: "string",
      validation: (Rule) => Rule.required()
    },
    {
      name: "phone",
      title: "Phone",
      type: "string",
      validation: (Rule) => Rule.required()
    },
    {
      name: "email",
      title: "Email",
      type: "string",
      validation: (Rule) => Rule.required()
    }
  ]
});
