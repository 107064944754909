import {
  useToast,
  Stack,
  Grid,
  Box,
  Button,
  Flex,
  Card,
  Checkbox,
  Text
} from "@sanity/ui";
import { useState, useMemo } from "react";
import { useSchema, useClient, Preview } from "sanity";
import PageAutocomplete from "./PageAutocomplete";
import { SanityDocumentWithSections, SectionBase } from "./type";
import { RemoveCircleIcon } from "@sanity/icons";
import { assignArrayKeys } from "./remove-keys";

function pluralMessage(count: number, singular: string, plural: string) {
  if (count === 1) {
    return `${count} ${singular}`;
  }
  return `${count} ${plural}`;
}

type SectionsChooserProps = {
  document: SanityDocumentWithSections;
  onSectionSelected: (section: string) => void;
  selectedSections: string[];
  onSelectedDocument: (document: SanityDocumentWithSections) => void;
  onRemoveSelectedDocument: (document: SanityDocumentWithSections) => void;
  selectedDocuments: SanityDocumentWithSections[];
  onMigrationCompleted: () => void;
};
const SectionsChooser: React.FC<SectionsChooserProps> = ({
  document,
  selectedSections,
  onSectionSelected,
  selectedDocuments,
  onSelectedDocument,
  onRemoveSelectedDocument,
  onMigrationCompleted
}: SectionsChooserProps) => {
  const pageSections = document.sections;
  const schema = useSchema();
  const client = useClient({ apiVersion: "2021-10-21" });
  const toast = useToast();
  const [migrating, setMigrating] = useState(false);

  const handleMigrate = () => {
    setMigrating(true);

    const realSections = document.sections.reduce(
      (acc, section) => {
        const { _key, ...content } = section;
        if (selectedSections.includes(section._key)) return [...acc, content];
        return acc;
      },
      [] as Omit<SectionBase, "_key">[]
    );

    const transaction = client.transaction();

    selectedDocuments.forEach((doc) => {
      const draftedId = doc._id.includes("drafts.")
        ? doc._id
        : `drafts.${doc._id}`;
      transaction.createIfNotExists({
        ...doc,
        _id: draftedId
      });

      transaction.patch(draftedId, (p) =>
        p
          .setIfMissing({ sections: [] })
          .insert("after", "sections[-1]", assignArrayKeys(realSections))
      );
    });

    transaction
      .commit({ autoGenerateArrayKeys: true })
      .then(() => {
        toast.push({ status: "success", title: "Copy Complete." });
        setMigrating(false);
        onMigrationCompleted();
      })
      .catch((err) => {
        toast.push({ status: "error", title: err.details.description });
        setMigrating(false);
      });
  };

  const migrationMessage = useMemo(
    () =>
      selectedSections.length && selectedDocuments.length
        ? `Copy ${pluralMessage(
            selectedSections.length,
            `Section`,
            `Sections`
          )} to ${pluralMessage(selectedDocuments.length, `Page`, `Pages`)}`
        : `Select sections to Copy`,
    [selectedSections, selectedDocuments]
  );

  return (
    <Stack space={4}>
      <Grid columns={[1, 1, 1, 2]} gap={5}>
        <Stack space={1}>
          <Box paddingBottom={2}>
            <Text>Select fields to copy</Text>
          </Box>

          {pageSections.map((section) => {
            const schemaType = schema.get(section._type);
            return (
              <Button
                key={section._key}
                onClick={() => onSectionSelected(section._key)}
                mode={
                  selectedSections.includes(section._key) ? `default` : `ghost`
                }
                tone={
                  selectedSections.includes(section._key)
                    ? `primary`
                    : `default`
                }
              >
                <Flex gap={2} align="center">
                  <Card padding={0} tone="transparent" radius={2}>
                    <Flex align="center">
                      <Checkbox
                        checked={selectedSections.includes(section._key)}
                      />
                    </Flex>
                  </Card>

                  <Box flex={1}>
                    {schemaType ? (
                      <Preview value={section} schemaType={schemaType} />
                    ) : (
                      <div>{section._type}</div>
                    )}
                  </Box>
                </Flex>
              </Button>
            );
          })}
        </Stack>

        <Stack space={1}>
          <Box paddingBottom={2}>
            <Text>Find Target Pages</Text>
          </Box>
          <PageAutocomplete onSelectedDocument={onSelectedDocument} />

          {selectedDocuments.length ? (
            <>
              <Box paddingBottom={2} paddingTop={5}>
                <Text>Target Page(s)</Text>
              </Box>
              {selectedDocuments.map((doc) => {
                return (
                  <Flex gap={2} align="center" key={doc._id}>
                    <Box flex={1}>
                      <Preview
                        value={doc}
                        schemaType={schema.get(doc._type)!}
                        status={
                          <Button
                            icon={RemoveCircleIcon}
                            tone="critical"
                            mode="bleed"
                            onClick={() => {
                              onRemoveSelectedDocument(doc);
                            }}
                          ></Button>
                        }
                      />
                    </Box>
                  </Flex>
                );
              })}
            </>
          ) : (
            <Box />
          )}
        </Stack>
      </Grid>

      <Button
        tone="positive"
        disabled={
          !selectedSections.length || !selectedDocuments.length || migrating
        }
        text={migrationMessage}
        onClick={handleMigrate}
      />
    </Stack>
  );
};

export default SectionsChooser;
