import {
  getLabelForInclusionCode,
  inclusionCodes,
  inclusionIcons
} from "../data-types/inclusionsTokens";
import inclusionSpecificContent from "../data-types/inclusionSpecificContent";
import inclusionOverrideContent from "../data-types/inclusionOverrideContent";
import {
  ValidationContext,
  defineArrayMember,
  defineField,
  defineType
} from "sanity";
import { multilingualStringPreview } from "../base/multilingualArrayString";

//import groq from "groq";

const isUniqueCode = (
  codes: string[] | undefined,
  context: ValidationContext
) => {
  const { document, getClient } = context;
  if (!document || !codes) return false;

  const client = getClient({ apiVersion: "2022-12-07" });

  const id = document._id.replace(/^drafts\./, "");

  const params = {
    draft: `drafts.${id}`,
    published: id,
    codes
  };

  const orCodes = codes.map((c, i) => `($codes[${i}] in codes)`).join(" || ");

  /* groq */
  const query = `!defined(*[
    _type == 'inclusion' &&
    !(_id in [$draft, $published]) &&
    (${orCodes})
  ][0]._id)`;

  return client.fetch(query, params);
};

export default defineType({
  name: "inclusion",
  type: "document",
  title: "Inclusion",
  fieldsets: [
    {
      name: "generic content",
      title: "Generic content",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "group content",
      title: "Group content",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "cruise content",
      title: "Cruise content",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  fields: [
    defineField({
      name: "codes",
      type: "array",
      of: [defineArrayMember({ type: "string" })],
      options: {
        list: inclusionCodes
      },
      validation: (Rule) =>
        Rule.required().custom(async (value: string[] | undefined, context) => {
          const isUnique = await isUniqueCode(value, context);
          if (!isUnique) return "Another inclusion with this code is present";
          return true;
        })
    }),
    {
      name: "icon",
      title: "Icon",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: inclusionIcons
      }
    },
    {
      name: "genericContent",
      title: "Generic Content",
      type: inclusionSpecificContent.name,
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "groupSpecificContent",
      title: "Group Content",
      type: inclusionSpecificContent.name,
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "cruiseSpecificContent",
      title: "Cruise Content",
      type: inclusionSpecificContent.name,
      options: { collapsible: true, collapsed: true }
    },
    defineField({
      name: "overrideContent",
      title: "Override Content",
      type: "array",
      of: [defineArrayMember({ type: inclusionOverrideContent.name })]
      // options: {
      //   editModal: "fullscreen"
      // }
    })
  ],
  preview: {
    select: {
      title: "genericContent.infographicTitle",
      codes: "codes",
      icon: "icon"
    },
    prepare({ title, codes }) {
      return {
        title: multilingualStringPreview(title),
        subtitle: codes?.map(getLabelForInclusionCode)?.join(", ")
      };
    }
  },
  orderings: [
    {
      title: "Title",
      name: "orderAsc",
      by: [{ field: "genericContent.infographicTitle.en", direction: "asc" }]
    }
  ]
});
