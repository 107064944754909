/* eslint-disable react/display-name */
import React, { ChangeEvent, useCallback, useMemo } from "react";
import { Container, Select } from "@sanity/ui";
import {
  Schema,
  StringInputProps,
  StringSchemaType,
  useFormValue,
  set,
  unset,
  useSchema
} from "sanity";
import { PageBuilderSection } from "../common/allPageBuilderSections";

const previewForType = (schema: Schema, rawData: PageBuilderSection) => {
  const fieldTypeName = rawData._type;
  const schemaForType = schema.get(fieldTypeName);

  if (schemaForType && schemaForType.preview?.prepare) {
    const preview = schemaForType.preview?.prepare?.(rawData);
    if (!preview) return `${rawData._type}`;
    return `${preview.subtitle || ""} ${preview.title || ""}`.trim();
  }

  return `${rawData._type}`;
};

const sectionsWithoutStickyNav = (sections: PageBuilderSection[]) =>
  sections.filter(({ _type }) => _type !== "stickyNav");

export const SectionAnchorInput: React.FC<
  StringInputProps<StringSchemaType>
> = (props) => {
  const allSections = useFormValue([`sections`]) as PageBuilderSection[];
  const schema = useSchema();

  const { value, elementProps, onChange } = props;

  const sections = useMemo(
    () => sectionsWithoutStickyNav(allSections || []),
    [allSections]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const inputValue = event.currentTarget.value;
      onChange(inputValue ? set(inputValue) : unset());
    },
    [onChange]
  );

  return (
    <Container>
      <Select {...elementProps} onChange={handleChange} value={value || ""}>
        <option />
        {sections.map((s, index) => (
          <option value={s._key} key={s._key}>
            {index} - {previewForType(schema, s)}
          </option>
        ))}
      </Select>
    </Container>
  );
};
