import { defineField } from "sanity";

export default defineField({
  type: "string",
  name: "packageIcon",
  title: "Icon",
  options: {
    list: [
      "default",
      "Services",
      "Flight",
      "Food & Beverage",
      "Reduced Fares",
      "Hotel",
      "Private Flight",
      "Pack",
      "Shorex",
      "Quito",
      "Single fares",
      "Free transfers",
      "Venetian Society",
      "Early Booking Bonus",
      "All Inclusive Offer",
      "Door to Door",
      "P2P Prepaid",
      "Suite Upgrade",
      "Clock"
    ]
  }
});
