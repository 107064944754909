import React from "react";
import WidenAssetSearch from "../widen/WidenAssetSearch";
import { WidenAsset } from "../widen/types";
import { AssetFromSource, AssetSourceComponentProps } from "sanity";

const stripSizeAndQualityParams = (embedTemplateUrl: string) =>
  // eslint-disable-next-line no-useless-escape
  embedTemplateUrl.replace(/\/{size}[^\/]*\//, "/px/").replace(/\?[^?]+$/, "");

const WidenAssetSource: React.FC<AssetSourceComponentProps> = (props) => {
  const handleSelect = (item: WidenAsset) => {
    const sanityAssetFromSource: AssetFromSource = {
      kind: "url",
      value: stripSizeAndQualityParams(item.embeds.templated.url),
      assetDocumentProps: {
        originalFilename: item.filename, // Use this filename when saving the image.
        source: {
          name: "Widen", // The source this image is from
          id: item.id, // A string that uniquely identifies it within the source
          url: item._links.self // Where to find more info about the asset
        },
        description:
          ((item.metadata.fields.iMAGEDESCRIPTION &&
            item.metadata.fields.iMAGEDESCRIPTION[0]) as string) ||
          "No description in Widen field iMAGEDESCRIPTION",
        creditLine:
          ((item.metadata.fields["13CREDITLINE"] &&
            item.metadata.fields["13CREDITLINE"][0]) as string) ||
          "No credit line in Widen field 13CREDITLINE",
        label: "selected-from-widen"
      } as any //TODO: wrong sanity type definition
    };

    props.onSelect([sanityAssetFromSource]);
  };

  return (
    <WidenAssetSearch
      onClose={props.onClose}
      handleSelect={handleSelect}
      fileTypes="image"
    />
  );
};

export default WidenAssetSource;
