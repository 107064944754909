import { netlifyWidget } from "sanity-plugin-dashboard-widget-netlify";
import { sscInitiateImportWidget } from "../plugins/initiate-import";
import { DashboardPluginConfig } from "@sanity/dashboard";
import { pageBuilderSectionsQueryWidget } from "../plugins/page-builder-sections-query";
import { sanityStatsWidget } from "../plugins/sanity-stats";

const awsEnv = process.env.SANITY_STUDIO_AWS_ENV || "dev";

const apiId = "468c4351-26c5-484b-8bcd-73b498ffe025";
const name = "silverseacom";
const productionNetlify = {
  title: "Production Website",
  apiId,
  buildHookId: "6621106fa966b308fc957804",
  name,
  branch: "production",
  url: "https://www.silversea.com"
};
const devNetlify = {
  title: "Dev Website",
  apiId,
  buildHookId: "662114243a44260a77939358",
  name,
  branch: "dev",
  url: "https://dev--silverseacom.netlify.app"
};
const qaNetlify = {
  title: "QA Website",
  apiId,
  buildHookId: "5f7b0b8ff5a6e5b68e04264c",
  name,
  branch: "qa",
  url: "https://qa--silverseacom.netlify.app"
};
const devRX01Netlify = {
  title: "Dev RX01 Website",
  apiId,
  buildHookId: "65cba095b976200cebb30b0d",
  name,
  branch: "dev-rx01",
  url: "https://dev-rx01--silverseacom.netlify.app"
};
const tstRxNetlify = {
  title: "Test RX Website",
  apiId,
  buildHookId: "664c67d58c65b1b92f4cda3e",
  name,
  branch: "tst-rx",
  url: "https://tst-rx--silverseacom.netlify.app"
};
const stgRxNetlify = {
  title: "Stage RX Website",
  apiId,
  buildHookId: "66606a8c71cfdf1d254a0f10",
  name,
  branch: "stg-rx",
  url: "https://stg-rx--silverseacom.netlify.app"
};
const netlifyConfigs: Record<string, typeof productionNetlify> = {
  production: productionNetlify,
  dev: devNetlify,
  qa: qaNetlify,
  "dev-rx01": devRX01Netlify,
  "tst-rx": tstRxNetlify,
  "stg-rx": stgRxNetlify
};

export default {
  defaultLayout: {
    height: "small"
  },
  widgets: [
    netlifyWidget({
      title: "Deploy",
      sites: [netlifyConfigs[awsEnv] || productionNetlify],
      layout: {
        width: "small"
      }
    }),
    sanityStatsWidget({
      layout: {
        width: "small"
      }
    }),
    sscInitiateImportWidget({
      layout: {
        width: "medium"
      }
    }),

    pageBuilderSectionsQueryWidget({
      layout: {
        width: "medium"
      }
    })
  ]
} satisfies DashboardPluginConfig;
