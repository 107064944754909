import { FaFileInvoiceDollar } from "react-icons/fa";
import page from "./page";
import { defineType } from "sanity";

export default defineType({
  ...page,
  name: "corporateIncentivePage",
  title: "Corporate Incentive Page",
  icon: FaFileInvoiceDollar
});
