import React from "react";

import { useDiagnosticApi } from "../commons/useDiagnosticApi";
import { DashboardWidgetContainer } from "@sanity/dashboard";
import SectionLabel from "../commons/SectionLabel";
import ValueLabel from "../commons/ValueLabel";
import { Flex, Spinner } from "@sanity/ui";

type ValidationChecksResponse = ValidationCheck[];

type ValidationCheck = {
  issueDescription: string;
  instances?: Instance[];
};

export interface Instance {
  title: string;
  info?: string;
}

type DataBlockProps = {
  title: string;
  rows: Instance[] | undefined;
};
const DataBlock: React.FC<DataBlockProps> = ({ title, rows }) => {
  if (!rows?.length) return <></>;

  return (
    <React.Fragment>
      <thead></thead>
      <tbody style={{ borderSpacing: "1em" }}>
        <tr>
          <th colSpan={2}>
            <SectionLabel size={4} align="left">
              {title}
            </SectionLabel>
          </th>
        </tr>
        {rows.map((row) => (
          <tr key={row.title}>
            <th>
              <ValueLabel muted>{row.title}</ValueLabel>
            </th>
            <td>{row.info}</td>
          </tr>
        ))}
      </tbody>
    </React.Fragment>
  );
};

type Props = {
  silverseaApiRootUrl?: string;
};
export const ValidationChecks: React.FC<Props> = ({ silverseaApiRootUrl }) => {
  const validationChecksQuery = useDiagnosticApi<ValidationChecksResponse>({
    queryKey: ["validationChecks"],
    enabled: true,
    silverseaApiRootUrl: silverseaApiRootUrl!,
    action: "validationChecks"
  });

  return (
    <DashboardWidgetContainer header="Cruise Validation Checks">
      {validationChecksQuery.isLoading && (
        <Flex justify="center" paddingTop={5}>
          <Spinner style={{ padding: 0 }} />
        </Flex>
      )}
      <table cellSpacing="21px">
        {validationChecksQuery.data?.map((datum) => (
          <DataBlock
            key={datum.issueDescription}
            title={datum.issueDescription}
            rows={datum.instances}
          />
        ))}
      </table>
    </DashboardWidgetContainer>
  );
};
