import { defineType } from "sanity";

export default defineType({
  name: "cruiseEvent",
  title: "CruiseEvent",
  type: "object",
  preview: {
    select: {
      title: "title.en",
      media: "image"
    }
  },
  fields: [
    {
      name: "image",
      title: "Image",
      type: "image",
      options: {
        hotspot: true
      },
      validation: (Rule) => Rule.required()
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "date",
      title: "Date",
      type: "date"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent"
    },
    {
      name: "location",
      title: "Location",
      type: "multilingualString"
    }
  ]
});
