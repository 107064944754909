import { defineType } from "sanity";
import { multilingualStringPreview } from "../base/multilingualArrayString";
import { designSystemIconPreview } from "../../components/DesignSystemIconInput";

export default defineType({
  title: "Micro Content Excursion Feature",
  name: "microContentExcursionFeature",
  type: "object",
  fields: [
    {
      name: "code",
      title: "Code",
      type: "string",
      options: {
        list: [
          "privato",
          "evening-excursions",
          "new",
          "free-shorex",
          "meal-included",
          "limited-space-available",
          "world-cruise-collection",
          "special-price"
        ]
      },
      validation: (Rule) => Rule.required()
    },
    {
      name: "icon",
      type: "designSystemIcon"
    },
    {
      name: "label",
      title: "Label",
      type: "multilingualArrayString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualArrayText"
    }
  ],
  preview: {
    select: {
      title: "label",
      icon: "icon",
      code: "code"
    },
    prepare({ title, code, icon }) {
      return {
        title: multilingualStringPreview(title),
        subtitle: code,
        media: designSystemIconPreview(icon)
      };
    }
  }
});
