import { defineArrayMember, defineField, defineType } from "sanity";
import { FaCircle, FaHighlighter } from "react-icons/fa";
import { multilingualStringPreview } from "../base/multilingualArrayString";
import {
  CRUISE_GROUP_CODES,
  CRUISE_GROUP_DESCRIPTIONS
} from "@silversea-ssc/web-cruise-groups";

const cruiseGroups = CRUISE_GROUP_CODES.map((c) => ({
  title: CRUISE_GROUP_DESCRIPTIONS[c],
  value: c
}));

export default defineType({
  title: "Cruise Group Inclusion",
  name: "cruiseGroupInclusion",
  type: "object",
  icon: FaCircle,
  preview: {
    select: {
      text: "text",
      highlight: "highlight",
      allCruiseGroups: "allCruiseGroups",
      cruiseGroups: "cruiseGroups"
    },
    prepare: ({ text, highlight, allCruiseGroups, cruiseGroups }) => {
      return {
        title: multilingualStringPreview(text),
        subtitle: allCruiseGroups
          ? "All cruise groups"
          : cruiseGroups.join(", "),
        media: highlight ? FaHighlighter : FaCircle
      };
    }
  },
  fields: [
    defineField({
      name: "text",
      title: "Text",
      type: "multilingualArrayString",
      validation: (Rule) => Rule.required()
    }),
    defineField({
      name: "highlight",
      title: "Highlight?",
      description: "Highlight this item",
      type: "boolean"
    }),
    defineField({
      name: "allCruiseGroups",
      title: "Valid for all cruise groups",
      description: "Is valid for all cruise groups?",
      type: "boolean",
      initialValue: true
    }),
    defineField({
      name: "cruiseGroups",
      title: "Cruise groups",
      type: "array",
      of: [defineArrayMember({ type: "string" })],
      options: {
        list: cruiseGroups
      },
      hidden: ({ parent }) => parent?.allCruiseGroups,
      validation: (Rule) =>
        Rule.custom((value, context) =>
          !(context.parent as any)?.allCruiseGroups && !value
            ? "You must select at least one cruise group"
            : true
        )
    })
  ]
});
