import { FaBus } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  name: "hotel",
  title: "Hotel",
  type: "document",
  icon: FaBus,
  preview: {
    select: {
      name: "name"
    },
    prepare(value) {
      return {
        title: value.name
      };
    }
  },
  fields: [
    {
      name: "name",
      title: "Name",
      type: "string",
      validation: (Rule) => Rule.required(),
      readOnly: true
    },
    {
      name: "code",
      title: "Code",
      type: "string",
      validation: (Rule) => Rule.required(),
      readOnly: true
    },
    {
      name: "resCode",
      title: "Res Code",
      description: "Code used to identify this Hotel within Res 2.0",
      type: "string",
      readOnly: true
    },
    {
      name: "iTravelCode",
      title: "iTravel Code",
      description: "Code used to identify this Hotel within iTravel",
      type: "string",
      readOnly: true
    },
    {
      name: "source",
      title: "Source",
      description: "Indicate which reservation system this data comes from",
      type: "string",
      readOnly: true
    },
    {
      name: "image",
      title: "Image",
      description: "Image used in the popup (where available)",
      type: "image",
      options: {
        hotspot: true
      },
      readOnly: true
    },
    {
      name: "descriptionHtml",
      title: "Description (Raw HTML)",
      description: "HTML used in the popup",
      type: "text",
      readOnly: true
    }
  ]
});
