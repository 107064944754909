import { defineType } from "sanity";

export default defineType({
  name: "accordionTextItem",
  type: "object",
  title: "Accordion Item",
  fields: [
    {
      name: "title",
      type: "multilingualString",
      title: "Title"
    },
    {
      name: "content",
      type: "multilingualBlockContent",
      title: "Content"
    }
  ],
  preview: {
    select: {
      title: "title.en"
    }
  }
});
