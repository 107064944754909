import { defineType } from "sanity";
import metadataCommonFields from "./metadataCommonFields";

export default defineType({
  name: "metadata",
  title: "SEO, Routing & Tracking",
  options: {
    collapsible: true,
    collapsed: true
  },
  type: "object",
  fieldsets: [
    {
      title: "Visibility",
      name: "visibility"
    }
  ],
  fields: [
    {
      name: "routing",
      type: "routing",
      title: "Routing",
      description: "Manage the URL of the page."
    },
    ...metadataCommonFields
    // {
    //   title: "Use site title?",
    //   description:
    //     "Use the site settings title as page title instead of the title on the referenced page",
    //   name: "useSiteTitle",
    //   type: "boolean"
    // },
    // {
    //   title: "Include in sitemap",
    //   description: "For search engines. Will be generateed to /sitemap.xml",
    //   name: "includeInSitemap",
    //   type: "boolean",
    //   fieldset: "visibility"
    // },
    // {
    //   title: "Disallow in robots.txt",
    //   description: "Hide this route for search engines like google",
    //   name: "disallowRobots",
    //   type: "boolean",
    //   fieldset: "visibility"
    // },
    /*
    // This can be used by a server-side rendered website. We plan to figure out proper JAMstack support
    {
      name: 'queries',
      type: 'array',
      description: 'Used to return personalized content based on paid search terms and remarketing',
      of: [
        {
          type: 'string'
        }
      ],
      options: {
        layout: 'tags'
      }
    }, */
    // {
    //   name: "campaign",
    //   type: "string",
    //   title: "Campaign",
    //   description: "UTM for campaings"
    // }
    /*
    // This can be used by a server-side rendered website. We plan to figure out proper JAMstack support
    {
      name: 'experiment',
      type: 'experiment',
      description: 'Use this to A/B/n test this route towards different pages',
    }, */
  ]
});
