import { PortableTextObject, PortableTextTextBlock, defineField } from "sanity";
import {
  ArrayLocalizedValueType,
  DEFAULT_LANGUAGE
} from "../../common/localization";

export type MultilingualArrayBlockContentValue = {
  _type: "multilingualArrayBlockContent";
} & ArrayLocalizedValueType<
  "internationalizedArrayBlockContentValue",
  PortableTextTextBlock[]
>;

export default defineField({
  name: "multilingualArrayBlockContent",
  title: "Multi-lingual String",
  type: "object",
  fields: [
    defineField({
      title: " ", // yes, is correct
      name: "langs",
      type: "internationalizedArrayBlockContent"
    })
  ]
});

const defaultContentBlockAt =
  (multilingualBlockContent: MultilingualArrayBlockContentValue) =>
  (blockIndex: number): any => {
    const langs = multilingualBlockContent.langs;
    const defaultLanguage = langs?.find((x) => x._key == DEFAULT_LANGUAGE.id);
    return defaultLanguage?.value?.[0].children?.[blockIndex]?.text;
  };

const truncateAt = (length: number) => (text: string) =>
  text.length > length ? `${text.substring(0, length - 3)}...` : text;

const blockContentPreview = (maxLength: number, parts: PortableTextObject[]) =>
  truncateAt(maxLength)(parts.filter(Boolean).join(" "));

export const multilingualBlockContentPreview =
  (maxLength: number) =>
  (multilingualBlockContent: MultilingualArrayBlockContentValue) => {
    const parts = [0, 1, 2, 3].map(
      defaultContentBlockAt(multilingualBlockContent)
    );

    return blockContentPreview(maxLength, parts);
  };
