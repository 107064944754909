import { RiPagesLine } from "react-icons/ri";
import { defineField, defineType } from "sanity";

export default defineType({
  name: "microContentCruisePage",
  title: "Cruise Page",
  type: "document",
  icon: RiPagesLine,
  groups: [{ name: "specialVoyage", title: "Special Voyages" }],
  fieldsets: [
    {
      name: "fareSection",
      title: "Fare Section",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "itinerarySection",
      title: "Itinerary Section",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "suiteSection",
      title: "Suite Section",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "eventsSection",
      title: "Events Section",
      options: { collapsible: true, collapsed: true }
    }
  ],
  fields: [
    defineField({
      name: "eCommerceBenefitsContent",
      title: "ECommerce Benefits",
      type: "eCommerceBenefitsContent",
      options: { collapsible: true, collapsed: true }
    }),
    defineField({
      name: "fareSection",
      type: "blockContentSectionTitle",
      fieldset: "fareSection"
    }),
    defineField({
      name: "pricesValidUntil",
      title: "Prices valid until",
      type: "multilingualArrayBlockContent",
      fieldset: "fareSection"
    }),

    defineField({
      name: "itineraryShorexDisclaimer",
      title: "Itinerary Shorex Disclaimer",
      type: "multilingualArrayBlockContent",
      fieldset: "itinerarySection"
    }),

    defineField({
      name: "suitesSection",
      type: "blockContentSectionTitle",
      fieldset: "suiteSection"
    }),
    defineField({
      name: "suitesSectionGVContent",
      title: "Grand Voyage Suites Section Content",
      type: "multilingualArrayBlockContent",
      fieldset: "suiteSection",
      group: "specialVoyage"
    }),
    defineField({
      name: "suitesSectionWCContent",
      title: "World Cruise Suites Section Content",
      type: "multilingualArrayBlockContent",
      fieldset: "suiteSection",
      group: "specialVoyage"
    }),
    defineField({
      name: "onboardBenefitsSection",
      type: "simpleSectionTitle",
      options: { collapsible: true, collapsed: true }
    }),

    defineField({
      name: "worldCruiseWhySilverseaSection",
      type: "simpleSectionTitle",
      options: { collapsible: true, collapsed: true },
      group: "specialVoyage"
    }),
    defineField({
      name: "grandVoyageWhySilverseaSection",
      type: "simpleSectionTitle",
      options: { collapsible: true, collapsed: true },
      group: "specialVoyage"
    }),
    defineField({
      name: "storiesSection",
      type: "simpleSectionTitle",
      options: { collapsible: true, collapsed: true }
    }),
    defineField({
      name: "expeditionTeamSection",
      type: "simpleSectionTitle",
      options: { collapsible: true, collapsed: true }
    }),
    defineField({
      name: "eventsSection",
      type: "simpleSectionTitle",
      fieldset: "eventsSection"
    }),
    defineField({
      name: "eventsSectionGVContent",
      title: "Grand Voyage Events Section Content",
      type: "multilingualArrayBlockContent",
      fieldset: "eventsSection",
      group: "specialVoyage"
    }),
    defineField({
      name: "eventsSectionWCContent",
      title: "World Cruise Events Section Content",
      type: "multilingualArrayBlockContent",
      fieldset: "eventsSection",
      group: "specialVoyage"
    })
  ]
});
