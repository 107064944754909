import { FaWpforms } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  name: "formDisclaimer",
  title: "Form Disclaimer",
  type: "document",
  icon: FaWpforms,
  fields: [
    {
      name: "disclaimer",
      title: "Disclaimer",
      type: "multilingualBlockContent"
    },
    {
      name: "overrideDisclaimer",
      title: "Override disclaimer",
      type: "array",
      of: [{ type: "baseLocationSpecificTextBox" }]
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Form Disclaimer"
      };
    }
  }
});
