import { FaSitemap } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { RiDraftLine } from "react-icons/ri";
import { MdFindInPage } from "react-icons/md";
import { landingPageYears } from "../schemas/content-types/landingPage";
import { StructureBuilder } from "sanity/structure";

function byCategoryAndYear(
  S: StructureBuilder,
  category: string,
  year: number | undefined
) {
  const query = [`_type == 'landingPage'`, `(archived != true)`];

  if (year) query.push(`year == ${year}`);
  else query.push(`!(defined(year))`);

  if (category) query.push(`category == "${category}"`);

  return S.listItem()
    .title(year ? year.toString() : "No Year")
    .child(
      S.documentTypeList("landingPage")
        .title("Landing Pages")
        .filter(query.join(" && "))
    );
}

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Pages")
    .icon(FaSitemap)
    .child(
      S.list()
        .title("Pages")
        .items([
          S.listItem()
            .title("All Pages")
            .schemaType("page")
            .child(
              S.documentTypeList("page").defaultOrdering([
                { field: "title.en", direction: "asc" }
              ])
            ),
          S.listItem()
            .title("Draft Pages")
            .icon(RiDraftLine)
            .schemaType("page")
            .child(
              S.documentTypeList("page")
                .title("Draft Pages")
                .filter(`_type == 'page' && (_id in path("drafts.**"))`)
                .defaultOrdering([{ field: "title.en", direction: "asc" }])
            ),
          S.listItem()
            .title("Pages for SEO")
            .icon(MdFindInPage)
            .schemaType("page")
            .child(
              S.documentTypeList("page")
                .title("SEO Pages")
                .filter(`_type == 'page' && category == "seo"`)
                .defaultOrdering([{ field: "title.en", direction: "asc" }])
            ),
          S.divider(),
          S.listItem()
            .title("Corporate Incentive")
            .schemaType("corporateIncentivePage")
            .child(S.documentTypeList("corporateIncentivePage")),
          S.divider(),

          S.listItem()
            .title(`Landing Pages`)
            .child(
              S.list()
                .title(`Landing Pages`)
                .items([
                  S.listItem()
                    .title("Draft Landing Pages")
                    .icon(RiDraftLine)
                    .schemaType("landingPage")
                    .child(
                      S.documentTypeList("landingPage")
                        .title("Draft Landing Pages")
                        .filter(
                          `_type == 'landingPage' && (_id in path("drafts.**"))`
                        )
                        .defaultOrdering([
                          { field: "title.en", direction: "asc" }
                        ])
                    ),
                  S.divider(),

                  S.listItem()
                    .title("Destination")
                    .child(
                      S.list()
                        .title("Destination")
                        .items([
                          ...landingPageYears.map((y) =>
                            byCategoryAndYear(S, "destination", y)
                          ),
                          byCategoryAndYear(S, "destination", undefined)
                        ])
                    ),
                  S.listItem()
                    .title("Ship")
                    .child(
                      S.list()
                        .title("Ship")
                        .items([
                          ...landingPageYears.map((y) =>
                            byCategoryAndYear(S, "ship", y)
                          ),
                          byCategoryAndYear(S, "ship", undefined)
                        ])
                    ),
                  S.listItem()
                    .title("Brand")
                    .child(
                      S.documentTypeList("landingPage")
                        .title("Landing Pages")
                        .filter(
                          `_type == 'landingPage' && (archived != true) && category == 'brand'`
                        )
                    ),
                  S.listItem()
                    .title("NWS")
                    .child(
                      S.documentTypeList("landingPage")
                        .title("Landing Pages")
                        .filter(
                          `_type == 'landingPage' && (archived != true) && category == 'nws'`
                        )
                    ),
                  S.listItem()
                    .title("Other")
                    .child(
                      S.list()
                        .title("Other")
                        .items([
                          ...landingPageYears.map((y) =>
                            byCategoryAndYear(S, "other", y)
                          ),
                          byCategoryAndYear(S, "other", undefined)
                        ])
                    ),
                  S.listItem()
                    .title("Un-Categorised")
                    .child(
                      S.documentTypeList("landingPage")
                        .title("Landing Pages")
                        .filter(
                          `_type == 'landingPage' && (archived != true) && !(defined(category))`
                        )
                    ),
                  S.divider(),
                  S.listItem()
                    .title("Archived Landing Pages")
                    .schemaType("landingPage")
                    .child(
                      S.documentTypeList("landingPage")
                        .title("Archived Landing Pages")
                        .filter(`_type == 'landingPage' && archived == true`)
                        .defaultOrdering([
                          { field: "title.en", direction: "asc" }
                        ])
                    )
                ])
            ),

          S.divider(),
          S.listItem()
            .title("Search Page")
            .icon(BiSearch)
            .child(
              S.editor()
                .id("page-search")
                .schemaType("search")
                .documentId("page-search")
            )
        ])
    );
