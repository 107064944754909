import { FaPiggyBank } from "react-icons/fa";
import { defineField, defineType } from "sanity";
import { imageWithAlt } from "../objects";

export default defineType({
  name: "microContentSubscribeToNewsletter",
  title: "Subscribe to Newsletter",
  description: "Content for the SFO",
  icon: FaPiggyBank,
  type: "document",

  fields: [
    defineField({
      name: "heading",
      type: "blockContentSectionTitle"
    }),
    {
      title: "background image",
      name: "backgroundImage",
      type: imageWithAlt.name
    }
  ],
  preview: {
    select: {
      title: "title.en"
    },
    prepare(value) {
      return {
        title: value.title,
        subtitle: "Subscribe to Newsletter"
      };
    }
  }
});
