import { FaRulerCombined } from "react-icons/fa";
import deckSuiteCategory from "./deckSuiteCategory";
import { defineType } from "sanity";

export default defineType({
  name: "deck",
  title: "Deck",
  type: "document",
  icon: FaRulerCombined,
  preview: {
    select: {
      deckNumber: "deckNumber"
    },
    prepare({ deckNumber }) {
      return {
        title: `Deck: ${deckNumber}`
      };
    }
  },
  fields: [
    {
      name: "deckImage",
      title: "Deck Image",
      type: "image",
      options: {
        hotspot: false
      },
      validation: (Rule) => Rule.required()
    },
    {
      name: "deckNumber",
      title: "Deck Number",
      type: "number",
      validation: (Rule) => Rule.required()
    },
    {
      name: "deckSuiteCategories",
      title: "Deck Suite Categories",
      type: "array",
      of: [{ type: deckSuiteCategory.name }]
    }
  ]
});
