import { FaBook } from "react-icons/fa";
import { locationFilter } from "../base";
import { defineType } from "sanity";
import { termsAndConditionsSection } from "../objects";
import { filtersAsReadableText } from "../../utils/filterableContentOf";

export default defineType({
  name: "termsAndConditionsByMarket",
  title: "Terms And Conditions",
  type: "document",
  icon: FaBook,
  preview: {
    select: {
      internalName: "internalName",
      locationFilters: "locationFilters"
    },
    prepare({ internalName, locationFilters }) {
      return {
        title: filtersAsReadableText([], locationFilters),
        subtitle: internalName
      };
    }
  },
  fields: [
    {
      name: "internalName",
      title: "Internal Name",
      type: "string"
    },
    {
      name: "sections",
      title: "Sections",
      type: "array",
      of: [{ type: termsAndConditionsSection.name }]
    },

    {
      name: "locationFilters",
      title: "Location Filters",
      type: "array",
      of: [
        {
          type: locationFilter.name
        }
      ]
    }
  ]
});
