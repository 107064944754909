import { defineType } from "sanity";

export default defineType({
  name: "tracking",
  title: "Tracking",
  options: {
    collapsible: true,
    collapsed: true
  },
  type: "object",
  fields: [
    {
      title: "Name",
      name: "name",
      description:
        "Name used to track clicks on this item in analytics (Adobe/Google Analytics)",
      type: "string"
    },
    {
      title: "Page Category 1",
      name: "pageCategory1",
      description: "prop1 in Adobe Analytics",
      type: "string"
    },
    {
      title: "Page Category 2",
      name: "pageCategory2",
      description: "prop2 in Adobe Analytics",
      type: "string"
    },
    {
      title: "Page Type",
      name: "pageType",
      description: "prop3 in Adobe Analytics",
      type: "string"
    }
  ]
});
