import { FaAnchor } from "react-icons/fa";
import { imageWithCaption } from "../objects";
import { defineType } from "sanity";

export default defineType({
  name: "port",
  title: "Port",
  type: "document",
  icon: FaAnchor,
  orderings: [
    {
      title: "Name",
      name: "nameAsc",
      by: [{ field: "name.en", direction: "asc" }]
    }
  ],
  preview: {
    select: {
      name: "data.name.en",
      code: "data.code",
      region: "region.name.en",
      country: "data.country.name.en",
      media: "card.image"
    },
    prepare(value) {
      return {
        title: value.code ? `${value.name} (${value.code})` : value.name,
        subtitle: value.region || value.country,
        media: value.media
      };
    }
  },
  fieldsets: [
    {
      name: "hero",
      title: "Hero",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "data",
      title: "Data",
      type: "reference",
      to: [{ type: "portData" }],
      readOnly: true
    },
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      readOnly: true
    },
    {
      name: "code",
      title: "Code",
      type: "string",
      readOnly: true
    },
    {
      name: "region",
      title: "Region",
      description:
        "Only required when we need to be more specific than country (e.g. Tierra del Fuego)",
      type: "reference",
      to: [{ type: "region" }]
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString",
      fieldset: "hero"
    },
    {
      name: "heroImages",
      title: "Images",
      type: "array",
      of: [{ type: "heroSlideImage" }],
      options: { hotspot: true },
      fieldset: "hero"
    },
    {
      name: "description",
      title: "Description",
      type: "descriptionText",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "shortDescriptionOverride",
      title: "Short Description (override)",
      description:
        "Used in itinerary etc. If not set, will use (English only) short description from Shop API.",
      type: "multilingualBlockContent",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "slideshow",
      title: "Slideshow",
      type: "array",
      of: [
        {
          type: imageWithCaption.name
        }
      ]
    },
    {
      name: "hotelsContent",
      title: "Hotels",
      description: "leave empty to hide the section",
      type: "array",
      of: [{ type: "accordionContent" }],
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    }
  ]
});
