import { BsBraces } from "react-icons/bs";
import { FaFilePdf, FaFlag, FaPhoneAlt } from "react-icons/fa";
import { VscFileSymlinkFile } from "react-icons/vsc";
import { linkablePageTypes } from "../../common/allLinkablePage";
import DisclaimerTextBoxStyle from "../../components/DisclaimerTextBoxStyle";
import LocationSpecificTextRenderer from "../../components/LocationSpecificTextRenderer";
import exclusiveOfferTokens from "../../utils/exclusiveOfferTokens";
import airNonAirFilter from "./airNonAirFilter";
import locationFilter from "./locationFilter";
import { defineArrayMember, defineField } from "sanity";

/**
 * This is the schema definition for the rich text fields used for
 * for this blog studio. When you import it in schemas.js it can be
 * reused in other parts of the studio with:
 *  {
 *    name: 'someName',
 *    title: 'Some title',
 *    type: 'blockContent'
 *  }
 */
export default defineField({
  title: "Block Content",
  name: "blockContent",
  type: "array",
  of: [
    defineArrayMember({
      title: "Block",
      type: "block",
      // Styles let you set what your user can mark up blocks with. These
      // corrensponds with HTML tags, but you can set any title or value
      // you want and decide how you want to deal with it where you want to
      // use your content.
      styles: [
        { title: "Normal", value: "normal" },
        { title: "H1", value: "h1" },
        { title: "H2", value: "h2" },
        { title: "H3", value: "h3" },
        { title: "H4", value: "h4" },
        {
          title: "Disclaimer",
          value: "disclaimer",
          component: DisclaimerTextBoxStyle
        }
      ],
      lists: [{ title: "Bullet", value: "bullet" }],
      // Marks let you mark up inline text in the block editor.
      marks: {
        // Decorators usually describe a single property – e.g. a typographic
        // preference or highlighting by editors.
        decorators: [
          { title: "Strong", value: "strong" },
          { title: "Emphasis", value: "em" }
        ],
        // Annotations can be any object structure – e.g. a link or a footnote.
        annotations: [
          defineArrayMember({
            title: "URL",
            name: "link",
            type: "object",
            fields: [
              {
                title: "URL",
                name: "href",
                type: "url",
                validation: (Rule) =>
                  Rule.uri({
                    allowRelative: true,
                    scheme: ["http", "https", "mailto"]
                  })
              },
              {
                title: "Open in new tab",
                name: "openInNewTab",
                type: "boolean"
              }
            ]
          }),
          defineArrayMember({
            title: "Link to Page",
            name: "linkToPage",

            icon: VscFileSymlinkFile,
            type: "object",
            fields: [
              {
                name: "page",
                type: "reference",
                to: linkablePageTypes
              }
            ]
          }),
          defineArrayMember({
            name: "linkToFile",
            type: "object",
            title: "Link to File",
            icon: FaFilePdf,
            fields: [
              {
                name: "file",
                title: "File",
                type: "file",
                validation: (Rule) => Rule.required()
              },
              {
                title: "Force download",
                name: "forceDownload",
                type: "boolean"
              }
            ]
          }),
          defineArrayMember({
            name: "placeholder",
            type: "object",
            title: "Token",
            icon: BsBraces,
            description: `One of ${exclusiveOfferTokens.join(",")}`,
            fields: [
              defineField({
                title: "Name",
                name: "name",
                type: "string",
                description: "Can be `token_name` or `PROMO_CODE-token_name`",
                validation: (Rule) =>
                  Rule.custom((x) =>
                    isValidToken(exclusiveOfferTokens, x?.trim())
                      ? true
                      : `Unknown token`
                  ).warning()
              })
            ]
          }),
          defineArrayMember({
            title: "Localized Phone Number",
            name: "localizedPhoneNumber",
            icon: FaPhoneAlt,
            type: "object",
            fields: [
              {
                title: "Enabled",
                name: "enabled",
                type: "boolean",
                readOnly: true
              }
            ]
          }),
          defineArrayMember({
            title: "Location Specific Text Section",
            name: "locationSpecificTextSection",
            icon: FaFlag,
            components: {
              annotation: LocationSpecificTextRenderer
            },
            type: "object",
            fields: [
              {
                name: "locationFilters",
                title: "Location Filters",
                type: "array",
                of: [
                  {
                    type: locationFilter.name
                  },
                  {
                    type: airNonAirFilter.name
                  }
                ]
              }
            ]
          })
        ]
      }
    }),
    // You can add additional types here. Note that you can't use
    // primitive types such as 'string' and 'number' in the same array
    // as a block type.
    {
      type: "inlineImage",
      options: { hotspot: true }
    }
  ]
});

const isValidToken = (allTokens: string[], token: string | undefined) => {
  if (!token) return true;
  for (const item of allTokens) {
    if (item === token || token.endsWith(item)) {
      return true;
    }
  }

  return false;
};
