import { defineType } from "sanity";
import { currencies } from "../../../common/currencies";

export default defineType({
  name: "singleCurrencyTokenValue",
  title: "Single Currency Token Value",
  type: "object",
  preview: {
    select: {
      name: "name",
      value: "value",
      currency: "currency"
    },
    prepare({ name, value, currency }) {
      return {
        title: name,
        subtitle: `${value} ${currency}`
      };
    }
  },
  fields: [
    {
      name: "name",
      title: "Token name",
      type: "string",
      validation: (Rule) => Rule.required()
    },
    {
      name: "value",
      title: "Value",
      type: "number",
      validation: (Rule) => Rule.required()
    },
    {
      name: "currency",
      title: "Currency",
      type: "string",
      options: {
        list: currencies
      },
      validation: (Rule) => Rule.required()
    }
  ]
});
