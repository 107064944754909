import { defineField, defineType } from "sanity";
import { multilingualStringPreview } from "../base/multilingualArrayString";
import { designSystemIconPreview } from "../../components/DesignSystemIconInput";
import { orderRankField } from "@sanity/orderable-document-list";

export default defineType({
  name: "cruiseGroupInclusions",
  type: "document",
  title: "Cruise Group Inclusions",
  preview: {
    select: {
      title: "title",
      icon: "icon"
    },
    prepare: ({ title, icon }) => {
      return {
        title: multilingualStringPreview(title),
        media: designSystemIconPreview(icon)
      };
    }
  },
  fields: [
    defineField({
      name: "icon",
      title: "Icon",
      type: "designSystemIcon",
      validation: (Rule) => Rule.required()
    }),
    defineField({
      name: "title",
      title: "Title",
      type: "multilingualArrayString",
      validation: (Rule) => Rule.required()
    }),
    defineField({
      name: "inclusions",
      title: "Inclusions",
      type: "array",
      of: [{ type: "cruiseGroupInclusion" }]
    }),
    orderRankField({ type: "cruiseGroupInclusions" })
  ]
});
