import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { defineType } from "sanity";

export default defineType({
  name: "mainMenu",
  title: "Main Menu",
  icon: BsFillMenuButtonWideFill,
  type: "document",
  fields: [
    {
      name: "mainMenu",
      title: "Main Menu",
      type: "array",
      of: [{ type: "mainMenuSection" }]
    },
    {
      name: "menuPrimaryCTA",
      title: "Menu Primary CTA",
      type: "customMenuItem",
      description:
        "Primary CTA in the main menu, if empty FYC link will be shown",
      options: {
        collapsed: true,
        collapsable: true
      }
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Main Menu"
      };
    }
  }
});
