import { defineField, defineType } from "sanity";
import { contentFilterPreview } from "../../common/localization";
import { airNonAirFilter, locationFilter } from "../base";
import ctaButtons from "./ctaButtons/ctaButtons";

export default defineType({
  name: "featuredContentRow",
  type: "object",
  title: "Featured Content Row",
  fields: [
    {
      name: "image",
      title: "Image",
      type: "imageWithAlt"
    },
    {
      name: "imageSize",
      title: "Image Size",
      description: "If empty, the default is regular",
      type: "string",
      options: {
        list: [
          { title: "Regular", value: "regular" },
          { title: "Small", value: "small" }
        ]
      }
    },
    defineField({
      name: "title",
      type: "multilingualText"
    }),
    {
      name: "description",
      type: "multilingualBlockContent"
    },
    {
      ...ctaButtons,
      name: "link",
      validation: (Rule) => Rule.max(2)
    },
    {
      name: "locationFilters",
      title: "Location Filters",
      type: "array",
      of: [
        {
          type: locationFilter.name
        },
        {
          type: airNonAirFilter.name
        }
      ]
    }
  ],
  preview: {
    select: {
      title: "title.en",
      media: "image",
      locationFilters: "locationFilters"
    },
    prepare({ title, media, locationFilters }) {
      return {
        title,
        media,
        subtitle: contentFilterPreview(locationFilters)
      };
    }
  }
});
