import { MdOutlineRocketLaunch } from "react-icons/md";
import { defineField, defineType } from "sanity";
import { multilingualArrayString, multilingualArrayText } from "../base";
import { multilingualStringPreview } from "../base/multilingualArrayString";

export default defineType({
  name: "cruisesLaunch",
  title: "Cruises Launch",
  type: "document",
  icon: MdOutlineRocketLaunch,
  preview: {
    select: {
      title: "title",
      date: "validFrom"
    },
    prepare({ title, date }) {
      return {
        title: multilingualStringPreview(title),
        subtitle: date
      };
    }
  },
  fields: [
    defineField({
      name: "code",
      title: "Code",
      type: "string",
      description: "Unique code for cruise launch",
      validation: (Rule) => Rule.required()
    }),
    defineField({
      name: "title",
      title: "Title",
      type: multilingualArrayString.name,
      description: "Short title for the menu",
      validation: (Rule) => Rule.required()
    }),
    defineField({
      name: "description",
      title: "Description",
      description: "Short description for the menu",
      type: multilingualArrayText.name
    }),
    defineField({
      name: "validFrom",
      title: "Valid From Date",
      type: "date"
    }),
    defineField({
      name: "cruiseCodes",
      title: "Cruise Codes",
      description: "list of cruise codes (one per row) without any comma",
      type: "text"
    }),
    defineField({
      name: "page",
      title: "Page for marketing the launch",
      type: "reference",
      to: [{ type: "page" }, { type: "landingPage" }],
      weak: true
    })
  ]
});
