import { BiBookOpen } from "react-icons/bi";
import { defineType } from "sanity";

export default defineType({
  name: "requestAQuoteThankYou",
  title: "Request a Quote - Thank you page",
  icon: BiBookOpen,
  type: "document",
  groups: [
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "subtitle",
      title: "Subtitle",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Request a Quote - Thank you page"
      };
    }
  }
});
