import { FaFileContract } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  name: "termsAndConditions",
  title: "Terms and Conditions Page",
  icon: FaFileContract,
  type: "document",
  groups: [
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "heading",
      title: "Heading",
      type: "openingText",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "sections",
      title: "Sections",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "termsAndConditionsByMarket" }]
        }
      ]
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Terms and Conditions Page"
      };
    }
  }
});
