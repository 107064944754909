import { FaFileContract } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  name: "termsAndConditionsSummary",
  title: "Terms & Conditions Summary",
  type: "document",
  icon: FaFileContract,
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Terms & Conditions Summary"
      };
    }
  }
});
