import { defineType } from "sanity";

export default defineType({
  type: "object",
  name: "expeditionTeamMemberDestinationBio",
  title: "Expedition Team Member Destination Bio",
  preview: {
    select: {
      title: "destination.name.en",
      media: "destination.heroImage"
    }
  },
  fields: [
    {
      name: "shortBio",
      type: "multilingualArrayBlockContent",
      title: "Short Bio"
    },
    {
      name: "destination",
      title: "Destination",
      type: "reference",
      validation: (Rule) => Rule.required(),
      to: [{ type: "destination" }]
    }
  ]
});
