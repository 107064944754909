import { defineType } from "sanity";

export default defineType({
  name: "cruiseWithConfidenceCard",
  type: "object",
  title: "Cruise with confidence Card",
  fields: [
    {
      name: "title",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "description",
      type: "multilingualBlockContent",
      validation: (Rule) => Rule.required()
    }
  ],
  preview: {
    select: {
      title: "title.en"
    },
    prepare({ title }) {
      return {
        title
      };
    }
  }
});
