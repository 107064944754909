import { AiOutlineForm } from "react-icons/ai";
import { StructureBuilder } from "sanity/structure";

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Request a Quote")
    .icon(AiOutlineForm)
    .child(
      S.list()
        .title("Request a Quote")
        .items([
          S.listItem()
            .title("Request a Quote Page")
            .icon(AiOutlineForm)
            .child(
              S.editor()
                .id("request-brochure-page")
                .schemaType("requestAQuote")
                .documentId("request-a-quote-page")
            ),
          S.listItem()
            .title("Request a Quote - Thank you page")
            .icon(AiOutlineForm)
            .child(
              S.editor()
                .id("request-brochure-thankyou-page")
                .schemaType("requestAQuoteThankYou")
                .documentId("request-a-quote-thankyou-page")
            )
        ])
    );
