import { defineType } from "sanity";

export default defineType({
  name: "mosaicCardModal",
  type: "object",
  title: "Mosaic Card Modal",
  fieldsets: [
    {
      name: "modal"
    }
  ],
  fields: [
    {
      name: "image",
      title: "Image",
      type: "imageWithAlt",
      options: { hotspot: true },
      validation: (Rule) => Rule.required()
    },
    {
      name: "title",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "tagline",
      type: "multilingualText",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "description",
      type: "multilingualText",
      validation: (Rule) => Rule.required()
    },
    {
      fieldset: "modal",
      name: "modalTitle",
      type: "multilingualString",
      description: "if empty, the card title is shown"
    },
    {
      fieldset: "modal",
      name: "modalContent",
      type: "multilingualBlockContent"
    }
  ],
  preview: {
    select: {
      title: "title.en",
      media: "image"
    },
    prepare({ title, media }) {
      return {
        title,
        subtitle: "modal",
        media
      };
    }
  }
});
