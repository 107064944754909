import { defineType } from "sanity";

export default defineType({
  type: "object",
  name: "customMenuItem",
  title: "Custom Menu Item",
  preview: {
    select: {
      title: "target.title.en",
      title2: "target.heading.title.en"
    },
    prepare({ title, title2 }) {
      return {
        title: title || title2
      };
    }
  },
  fields: [
    {
      type: "multilingualString",
      name: "title",
      validation: (Rule) => Rule.required()
    },
    {
      type: "designSystemIcon",
      name: "icon"
    },
    {
      name: "target",
      title: "Target",
      type: "reference",
      validation: (Rule) => Rule.required(),
      to: [
        { type: "page" },
        { type: "landingPage" },
        { type: "termsAndConditions" },
        { type: "offersTermsAndConditions" },
        { type: "exclusiveOfferPerPromo" }
      ]
    },
    {
      name: "trackingName",
      title: "Tracking Name",
      type: "string",
      validation: (Rule) => Rule.required()
    }
  ]
});
