import filterableCurrency from "../base/filterableCurrency";
import { customCurrencyTokens } from "../../utils/exclusiveOfferTokens";
import { filtersAsReadableText } from "../../utils/filterableContentOf";
import { defineType } from "sanity";
import {
  ConditionFilterValue,
  LocationFilterValue
} from "../../common/localization";

export default defineType({
  type: "object",
  name: "currencyToken",
  title: "Currency token",
  preview: {
    select: {
      name: "name",
      values: "values"
    },
    prepare({ name, values }) {
      return {
        title: name,
        subtitle: values
          .map(
            (x: {
              value: string;
              currency: string;
              locationFilters: LocationFilterValue[];
              conditionFilters: ConditionFilterValue[];
            }) =>
              `${x.value} ${x.currency}/${filtersAsReadableText(
                x.conditionFilters,
                x.locationFilters
              )}`
          )
          .join(", ")
      };
    }
  },
  fields: [
    {
      name: "name",
      type: "string",
      options: {
        list: customCurrencyTokens
      },
      title: "Name",
      validation: (Rule) => Rule.required()
    },
    {
      name: "values",
      type: "array",
      of: [{ type: filterableCurrency.name }]
    }
  ]
});
