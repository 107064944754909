export const linkablePages = [
  "page",
  "landingPage",
  "ship",
  "destination",
  "exclusiveOfferPerPromo",
  "cruiseGroupPerFare"
];

export const linkablePageTypes = linkablePages.map((l) => ({ type: l }));
