import { DashboardWidget, LayoutConfig } from "@sanity/dashboard";
import SanityStats from "./SanityStats";

export function sanityStatsWidget(
  config: { layout?: LayoutConfig } = {}
): DashboardWidget {
  return {
    name: "sanity-stats",
    component: SanityStats,
    layout: config.layout ?? { width: "small" }
  };
}
