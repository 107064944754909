// sanity.config.js
import { defineConfig } from "sanity";
import { structureTool } from "sanity/structure";
import { visionTool } from "@sanity/vision";
import schemas from "./schemas/schema";
import { dashboardTool } from "@sanity/dashboard";
import dashboardConfig from "./structure/dashboardConfig";
import { media } from "sanity-plugin-media";
import deskStructure from "./structure/main";
import widenVideo from "./plugins/widen-video";
import CustomStudioLayout from "./components/CustomStudioLayout";
import widenAssetSource from "./plugins/asset-source-widen";
import { CopySelectedFieldsAction } from "./plugins/copy-selected-fields-action/CopySelectedFieldsAction";
import PartialPublishAction from "./plugins/partial-publish-action/PartialPublishAction";
import { SUPPORTED_LANGUAGES } from "./common/localization";
import { internationalizedArray } from "sanity-plugin-internationalized-array";
import { languageFilter } from "@sanity/language-filter";
import languageFilterConfig from "./parts/languageFilterConfig";
import { defaultDocumentNodeResolver } from "./structure/defaultDocumentNodeResolver";
import cruiseDiagnosticDashboardConfig from "./structure/cruiseDiagnosticDashboardConfig";
import "./custom.css";

const dataset = process.env.SANITY_STUDIO_API_DATASET || "dev";

export default defineConfig({
  name: "Silversea",
  title: `Silversea (${dataset})`,
  projectId: "rd0y3pad",
  dataset: dataset,
  plugins: [
    internationalizedArray({
      languages: SUPPORTED_LANGUAGES,
      defaultLanguages: SUPPORTED_LANGUAGES.map((x) => x.id),
      fieldTypes: ["string", "text", "blockContent", "file", "slug"],
      buttonAddAll: true,
      buttonLocations: ["field"]
    }),
    structureTool({
      structure: deskStructure,
      defaultDocumentNode: defaultDocumentNodeResolver
    }),
    visionTool(),
    media(),
    languageFilter(languageFilterConfig),
    dashboardTool(dashboardConfig),
    dashboardTool(cruiseDiagnosticDashboardConfig),
    widenVideo()
  ],
  form: {
    image: {
      assetSources: (prev) => [...prev, widenAssetSource],
      directUploads: true
    }
  },
  document: {
    actions: [PartialPublishAction, CopySelectedFieldsAction],
    productionUrl: async (prev, context) => {
      // context includes the client and other details
      const { document } = context;
      const maybePath = (document as any)?.metadata?.routing?.path?.en;

      if (maybePath)
        return `https://preview.digital.silversea.com${maybePath}.html`;

      return prev;
    }
  },
  schema: {
    types: schemas
  },
  studio: {
    components: {
      layout: CustomStudioLayout
    }
  }
});
