import { getLabelForInclusionCode } from "../data-types/inclusionsTokens";
import { defineType } from "sanity";
import { multilingualStringPreview } from "../base/multilingualArrayString";

export default defineType({
  name: "expeditionInclusionModal",
  type: "document",
  title: "Expedition Inclusion Modal",
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualArrayString",
      options: { collapsible: false }
    },
    {
      name: "description",
      title: "Description",
      type: "multilingualArrayBlockContent",
      options: { collapsible: false }
    },
    {
      name: "inclusions",
      title: "Inclusions",
      type: "array",
      of: [{ type: "expeditionInclusionModalItem" }]
    },
    {
      name: "link",
      title: "Link",
      type: "reference",
      to: [{ type: "page" }]
    }
  ],
  preview: {
    select: {
      title: "genericContent.infographicTitle",
      codes: "codes",
      icon: "icon"
    },
    prepare({ title, codes }) {
      return {
        title: multilingualStringPreview(title),
        subtitle: codes?.map(getLabelForInclusionCode)?.join(", ")
      };
    }
  }
});
