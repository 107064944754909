import { defineType } from "sanity";
import { contentFilterPreview } from "../../common/localization";

export default defineType({
  type: "object",
  name: "menuItem",
  title: "Menu Item",
  preview: {
    select: {
      title: "target.title.en",
      title2: "target.heading.title.en",
      filters: "filters"
    },
    prepare({ title, title2, filters }) {
      return {
        title: title || title2,
        subtitle: contentFilterPreview(filters)
      };
    }
  },
  fields: [
    {
      name: "target",
      title: "Target",
      type: "reference",
      validation: (Rule) => Rule.required(),
      to: [
        { type: "page" },
        { type: "landingPage" },
        { type: "termsAndConditions" },
        { type: "offersTermsAndConditions" }
      ]
    },
    {
      name: "filters",
      title: "Filters",
      description:
        "If present, the menu item will only appear for the below selected filters",
      type: "array",
      of: [
        {
          type: "locationFilter"
        },
        {
          type: "airNonAirFilter"
        }
      ]
    }
  ]
});
