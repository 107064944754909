import { MdAirlineSeatIndividualSuite } from "react-icons/md";
import { defineType } from "sanity";

export default defineType({
  name: "suiteCategoryTemplate",
  title: "Suite Category Template",
  type: "document",
  icon: MdAirlineSeatIndividualSuite,
  fields: [
    {
      name: "isAvailableOnHeading",
      title: "Is Available On Heading",
      type: "sectionTitle"
    },
    {
      name: "otherSuitesHeading",
      title: "Other Suites Heading",
      type: "sectionTitle"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Suite Category Template"
      };
    }
  }
});
