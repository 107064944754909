import { useQuery } from "react-query";
import { stringIsPresent } from "../../utils/checks";
import { fetchFun } from "../commons/useDiagnosticApi";

export type SanityStatsResponse = {
  documents: SanityStatsDocuments;
  fields: SanityStatsFields;
  types: SanityStatsFields;
};

type SanityStatsDocuments = {
  count: SanityStatsCount;
  sizeSum: SanityStatsCount;
  jsonSizeSum: SanityStatsCount;
};

type SanityStatsFields = {
  count: SanityStatsCount;
};

type SanityStatsCountUnit = "number" | "byte";

export type SanityStatsCount = {
  value: number;
  limit: number;
  unit: SanityStatsCountUnit;
};

export const useSanityStatsApi = (dataset?: string) => {
  return useQuery({
    queryKey: ["sanity-stats", dataset],
    enabled: stringIsPresent(dataset),
    retry: false,
    queryFn: () =>
      fetchFun<SanityStatsResponse>(
        `https://rd0y3pad.api.sanity.io/v1/data/stats/${dataset}`
      )
  });
};

const numberFormatterOptions = {
  byte: {
    style: "unit",
    unit: "byte"
  },
  number: {}
};

export const formatNumber = (
  number: number,
  unit: SanityStatsCountUnit,
  infinite?: boolean
) =>
  number == 0 && infinite
    ? "∞"
    : new Intl.NumberFormat("en", numberFormatterOptions[unit]).format(number);
