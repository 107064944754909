import { FaNewspaper } from "react-icons/fa";
import { toSingleLanguage } from "../../common/localization";
import { metadataWithoutParent } from "../page-fragments";
import { defineField, defineType } from "sanity";

export default defineType({
  name: "pressRelease",
  title: "Press Release",
  type: "document",
  icon: FaNewspaper,
  fieldsets: [
    {
      name: "tags",
      title: "Tags",
      options: { collapsible: false }
    }
  ],
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  orderings: [
    {
      title: "Date",
      name: "date",
      by: [{ field: "date", direction: "desc" }]
    }
  ],
  preview: {
    select: {
      title: "title",
      date: "date",
      media: "card.image"
    },
    prepare: ({ title, media, date }) => {
      return {
        title: toSingleLanguage(title),
        media,
        subtitle: new Date(date).toLocaleDateString(undefined, {
          year: "numeric",
          month: "short",
          day: "numeric"
        })
      };
    }
  },
  fields: [
    defineField({
      fieldset: "tags",
      name: "tagsCategories",
      title: "Categories",
      type: "array",
      of: [
        {
          type: "string"
        }
      ],
      options: {
        list: [
          { title: "Destinations", value: "Destinations" },
          { title: "Itineraries", value: "Itineraries" },
          { title: "Miscellaneous", value: "Miscellaneous" },
          { title: "Royal Caribbean Group", value: "Royal Caribbean Group" },
          { title: "Ships", value: "Ships" },
          { title: "Sustainability", value: "Sustainability" },
          { title: "Trade", value: "Trade" }
        ]
      }
    }),
    {
      fieldset: "tags",
      name: "tagsShips",
      title: "Ships",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "ship" }]
        }
      ]
    },
    {
      fieldset: "tags",
      name: "date",
      title: "Date",
      type: "date"
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "body",
      title: "Body",
      type: "multilingualBlockContent"
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: metadataWithoutParent.name,
      group: "seo"
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    }
  ]
});
