import { StructureBuilder } from "sanity/structure";
import { BsFillMenuButtonWideFill } from "react-icons/bs";

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Main Menu")
    .icon(BsFillMenuButtonWideFill)
    .child(
      S.list()
        .title("Main Menu")
        .items([
          S.listItem()
            .title("Top Menu")
            .child(
              S.editor()
                .id("top-menu")
                .schemaType("topMenu")
                .documentId("top-menu")
            ),
          S.listItem()
            .title("Main Menu")
            .icon(BsFillMenuButtonWideFill)
            .child(
              S.editor()
                .id("main-menu")
                .schemaType("mainMenu")
                .documentId("main-menu")
            ),
          S.listItem()
            .title("Corporate Incentive Menu")
            .icon(BsFillMenuButtonWideFill)
            .child(
              S.editor()
                .id("corporate-incentive-menu")
                .schemaType("mainMenu")
                .documentId("corporate-incentive-menu")
            )
        ])
    );
