/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import { GiPuzzle } from "react-icons/gi";

import {
  DocumentActionComponent,
  DocumentActionDescription,
  SanityClient,
  useClient
} from "sanity";

import { SanityDocument } from "@sanity/client";
import { SchemaFieldset } from "./SelectableList";
import PartialPublishDialog from "./PartialPublishDialog";

async function partialPublish(
  client: SanityClient,
  selectedItems: SchemaFieldset[],
  draft: SanityDocument,
  published: SanityDocument
) {
  const transaction = client.transaction();
  const fieldsWithValues = selectedItems.reduce((acc, value) => {
    if (value.fieldNames) {
      value.fieldNames.map((f) => {
        acc[f] = draft[f];
      });
    }

    return acc;
  }, {} as Record<string, unknown>);

  transaction.patch(published._id, {
    set: fieldsWithValues,
    ifRevisionID: published._rev
  });

  await transaction.commit();
}

const PartialPublishAction: DocumentActionComponent = ({
  type,
  draft,
  published,
  onComplete
}) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const client = useClient({ apiVersion: "2021-10-21" });

  const onMigrate = useCallback(
    (selectedItems: SchemaFieldset[]) => {
      partialPublish(client, selectedItems, draft!, published!).then(
        onComplete
      );
      setDialogOpen(false);
    },
    [client, draft, onComplete, published]
  );

  return {
    label: `Partial Publish`,
    icon: GiPuzzle,
    onHandle: () => {
      setDialogOpen(true);
    },
    disabled: !draft,
    dialog: isDialogOpen && {
      type: "dialog",
      header: "Select the fields you want to publish",
      width: "full",

      onClose: () => {
        setDialogOpen(false);
      },
      content: <PartialPublishDialog onClick={onMigrate} type={type} />
    }
  } satisfies DocumentActionDescription;
};

export default PartialPublishAction;
