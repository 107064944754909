import { defineType } from "sanity";

export default defineType({
  name: "onboardEnrichments",
  type: "object",
  title: "Onboard Enrichments",
  fields: [
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent"
    },
    {
      name: "images",
      type: "array",
      of: [
        {
          type: "imageWithCaption"
        },
        {
          type: "videoWithPosterImage"
        }
      ]
    }
  ]
});
