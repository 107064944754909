import { defineType } from "sanity";

export default defineType({
  name: "callToAction",
  title: "Call to Action",
  type: "object",
  preview: {
    select: {
      title: "title.en"
    }
  },
  fields: [
    {
      type: "multilingualString",
      name: "title",
      title: "Title",
      validation: (Rule) => Rule.required()
    },
    {
      type: "string",
      name: "style",
      title: "Style",
      options: {
        list: [
          { title: "Primary", value: "Primary" },
          { title: "Secondary", value: "Secondary" }
        ],
        layout: "radio"
      },
      validation: (Rule) => Rule.required()
    },
    {
      type: "reference",
      name: "target",
      title: "Target",
      to: [{ type: "page" }]
    }
  ]
});
