import { defineField } from "sanity";
export default defineField({
  type: "string",
  name: "coloredIcon",
  title: "Icon",
  options: {
    list: [
      { title: "World (Color)", value: "colored-world" },
      { title: "Ship (Color)", value: "colored-ship" },
      { title: "Butler (Color)", value: "colored-butler" },
      { title: "Shorex (Color)", value: "colored-shorex" },
      { title: "Experience (Color)", value: "colored-experience" }
    ]
  }
});
