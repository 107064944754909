import React from "react";
import { FilterValue, contentFilterPreview } from "../common/localization";
import { BlockAnnotationProps } from "sanity";
import { Tooltip, Box, Text } from "@sanity/ui";

const LocationSpecificTextRenderer: React.FC<BlockAnnotationProps> = (
  props
) => {
  const locationFilters = props.value.locationFilters as FilterValue[];

  const filtersPreview = contentFilterPreview(locationFilters);
  return (
    <Tooltip
      content={
        <Box padding={2}>
          <Text muted size={1}>
            Visible in: {filtersPreview}
          </Text>
        </Box>
      }
      fallbackPlacements={["right", "left"]}
      placement="top"
      portal
    >
      {props.renderDefault(props)}
    </Tooltip>
  );
};

export default LocationSpecificTextRenderer;
