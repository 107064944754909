import { defineType } from "sanity";
import { contentFilterPreview } from "../../common/localization";
import { locationFilter } from "../base";

export default defineType({
  name: "baseLocationSpecificTextBox",
  type: "object",
  title: "Location Specific Text section",
  fields: [
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent"
    },
    {
      name: "locationFilters",
      title: "Location Filters",
      type: "array",
      of: [
        {
          type: locationFilter.name
        }
      ]
    }
  ],
  preview: {
    select: {
      title: "",
      locationFilters: "locationFilters"
    },
    prepare({ title, locationFilters }) {
      return {
        title: title || "",
        subtitle: contentFilterPreview(locationFilters)
      };
    }
  }
});
