import { FaLeanpub } from "react-icons/fa";
import { introSection } from "../page-fragments";
import { imageWithCaption, videoWithPosterImage } from "../objects";
import deck from "./deck";
import shipDiningArea from "./shipDiningArea";
import shipFacility from "./shipFacility";
import shipSuite from "./shipSuite";
import brochureGrouping from "./brochureGrouping";
import { defineType } from "sanity";

export default defineType({
  name: "shipVersion",
  title: "Ship Version",
  type: "document",
  icon: FaLeanpub,
  preview: {
    select: {
      validFrom: "validFrom",
      image: "image",
      lastRefurbishmentYear: "lastRefurbishmentYear",
      buildYear: "buildYear"
    },
    prepare({ validFrom, image, lastRefurbishmentYear, buildYear }) {
      return {
        title: lastRefurbishmentYear
          ? `Refurbished: ${lastRefurbishmentYear}`
          : `Built: ${buildYear}`,
        subtitle: `Valid From: ${new Date(validFrom).toLocaleDateString(
          "en-GB"
        )}`,
        media: image
      };
    }
  },
  fieldsets: [
    {
      name: "hero",
      title: "Hero",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "tech-data",
      title: "Tech data",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "deck-plan",
      title: "Deck Plan",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "specialFeatureContent",
      title: "Special feature content",
      description: "Add service rows after the introduction text",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  fields: [
    {
      name: "validFrom",
      title: "Valid From",
      type: "date",
      validation: (Rule) => Rule.required()
    },
    {
      name: "decks",
      title: "Decks",
      type: "array",
      of: [{ type: deck.name }]
    },
    {
      name: "suites",
      title: "Suites",
      type: "array",
      of: [
        {
          type: shipSuite.name
        }
      ]
    },
    {
      name: "diningAreas",
      title: "Dining Areas",
      type: "array",
      of: [{ type: shipDiningArea.name }]
    },
    {
      name: "facilities",
      title: "Facilities",
      type: "array",
      of: [{ type: shipFacility.name }]
    },
    {
      name: "shipNotReadyDisclaimer",
      title: "Ship not ready disclaimer",
      type: "multilingualString"
    },
    {
      name: "image",
      title: "Image",
      type: "image",
      options: {
        hotspot: true
      },
      validation: (Rule) => Rule.required(),
      fieldset: "hero"
    },
    {
      name: "customBrochuresLink",
      title: "Custom Brockures CTA link",
      type: "multilingualString",
      description: "Add only if different from the standard url",
      fieldset: "hero"
    },
    {
      name: "description",
      title: "Description",
      type: introSection.name,
      fieldset: "hero",
      options: {
        collapsible: false
      }
    },
    {
      name: "lateralViewImage",
      title: "Lateral View Image",
      type: "image",
      options: {
        hotspot: true
      }
    },
    {
      name: "slideshow",
      title: "Slideshow",
      type: "array",
      of: [
        {
          type: imageWithCaption.name
        },
        {
          type: videoWithPosterImage.name
        }
      ]
    },
    {
      name: "shipType",
      title: "Type",
      type: "string",
      options: {
        list: [
          { title: "Classic", value: "Classic" },
          { title: "Expedition", value: "Expedition" }
        ]
      },
      validation: (Rule) => Rule.required(),
      fieldset: "tech-data"
    },
    {
      name: "passengerCount",
      title: "Passenger Count",
      type: "number",
      validation: (Rule) => Rule.required().positive().integer(),
      fieldset: "tech-data"
    },
    {
      name: "crewCount",
      title: "Crew Count",
      type: "number",
      fieldset: "tech-data"
    },
    {
      name: "buildYear",
      title: "Year of construction",
      type: "number",
      fieldset: "tech-data"
    },
    {
      name: "lastRefurbishmentYear",
      title: "Last Refurbishment Year",
      type: "number",
      fieldset: "tech-data"
    },
    {
      name: "tonnage",
      title: "Tonnage",
      type: "number",
      fieldset: "tech-data"
    },
    {
      name: "diningOptions",
      title: "Dining Options",
      type: "number",
      fieldset: "tech-data"
    },
    {
      name: "suiteCount",
      title: "Suite Count",
      type: "number",
      fieldset: "tech-data"
    },
    {
      name: "deckPlanPdf",
      title: "Deck Plan PDF",
      type: "file",
      fieldset: "deck-plan"
    },
    {
      name: "deckPlanDescription",
      title: "Deck Plan Description",
      type: "multilingualBlockContent",
      fieldset: "deck-plan"
    },
    {
      name: "specialFeatures",
      type: "array",
      of: [
        {
          type: "shipSpecialFeatureContent"
        }
      ],
      fieldset: "specialFeatureContent"
    },
    {
      name: "brochureGroup",
      title: "Brochure Group",

      type: "reference",
      to: [{ type: brochureGrouping.name }]
    }
  ]
});
