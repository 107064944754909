import * as pageBuilderSections from "../schemas/page-builder";

type SectionName = keyof typeof pageBuilderSections;
export type PageBuilderSection = {
  _key: string;
  _type: SectionName;
  [k: string]: unknown;
};

export default () => {
  return Object.keys(pageBuilderSections)
    .sort((a: string, b: string) => {
      const titleA = pageBuilderSections[a as SectionName].title || "";
      const titleB = pageBuilderSections[b as SectionName].title || "";

      return titleA.localeCompare(titleB);
    })
    .map((type) => {
      return { type };
    });
};
