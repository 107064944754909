import { FARE_CODES } from "@silversea-ssc/web-fares";
import { startCase } from "lodash";

export const fareCodes = FARE_CODES.map((c) => ({
  title: startCase(c),
  value: c
}));

export const getLabelForFareCode = (code: string) =>
  fareCodes.find((c) => c.value == code)?.title || code;
