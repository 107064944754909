import { GiPriceTag } from "react-icons/gi";
import { defineType } from "sanity";

export default defineType({
  title: "3. [Fares] Mosaic",
  name: "faresMosaic",
  type: "object",
  icon: GiPriceTag,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "fares",
      title: "Fares",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "farePage" }]
        }
      ]
    },
    {
      name: "variant",
      title: "Card Variant",
      type: "string",
      description: "if empty, is expanded by default",
      options: {
        list: [
          { title: "Mini", value: "mini" },
          { title: "Collapsed", value: "collapsed" },
          { title: "Expanded", value: "expanded" }
        ]
      }
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      fares: "fares"
    },
    prepare(selection) {
      const { title, fares } = selection;
      return {
        title: title || "([Fares] Mosaic)",
        subtitle: `${fares?.length} fares`
      };
    }
  }
});
