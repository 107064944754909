import { defineType } from "sanity";
import { filterableMultilingualBlockContent } from "../base";

export default defineType({
  name: "asCardAirCondition",
  title: "Render as Card",
  description: "Control how this page will display in sliders etc.",
  options: {
    collapsible: true,
    collapsed: true
  },
  type: "object",
  fields: [
    {
      title: "Card Image",
      name: "image",
      type: "imageWithAlt"
    },
    {
      name: "title",
      title: "Card Title",
      description:
        "When set, the card will use this title instead of the main page title.",
      type: "array",
      of: [
        {
          type: filterableMultilingualBlockContent.name,
          title: "Title by air condition"
        }
      ]
    },
    {
      name: "description",
      title: "Description on Card",
      description: "Content to appear in the card, below the title",
      type: "array",
      of: [
        {
          type: filterableMultilingualBlockContent.name,
          title: "Description by air condition"
        }
      ]
    }
  ]
});
