import { MdSettings } from "react-icons/md";
import { defineType } from "sanity";

export default defineType({
  name: "cruisesSettings",
  title: "Cruises Settings",
  type: "document",
  icon: MdSettings,
  fields: [
    {
      name: "farePricesValidUntilDate",
      title: "Fare prices valid until date",
      description: "The date when the current prices will change",
      type: "date"
    },
    {
      name: "farePricesMayIncreaseOnDate",
      title: "Fare prices may increase ondate",
      description: "The date when the current prices may increase",
      type: "date"
    },
    {
      name: "showVSPreSaleMessage",
      title: "Show VS Pre-sale message",
      description: "list of cruise codes (one per row) without any comma",
      type: "text"
    }
  ],
  preview: {
    prepare() {
      return {
        title: "Cruises Settings"
      };
    }
  }
});
