import { defineType } from "sanity";
import { multilingualBlockContent, packageIcon } from "../base";
import { multilingualBlockContentPreview } from "../base/multilingualBlockContent";

export default defineType({
  name: "asCardWithIcon",
  title: "Render as Card",
  description: "Control how this page will display in sliders without images",
  options: {
    collapsible: true,
    collapsed: true
  },
  type: "object",
  fields: [
    {
      title: "Card Icon",
      name: "icon",
      type: packageIcon.name,
      validation: (Rule) => Rule.required()
    },
    {
      name: "title",
      title: "Card Title",
      description:
        "When set, the card will use this title instead of the main page title.",
      type: multilingualBlockContent.name,
      validation: (Rule) => Rule.required()
    },
    {
      name: "description",
      title: "Description on Card",
      description: "Content to appear in the card, below the title",
      type: multilingualBlockContent.name
    }
  ],
  toReadableText: (card: any) =>
    multilingualBlockContentPreview(100)(card?.title)
});
