import { defineType } from "sanity";

export default defineType({
  name: "linkCTA",
  type: "object",
  title: "Link CTA",
  fields: [
    {
      name: "label",
      type: "multilingualString",
      description: 'leave empty for "Discover More"'
    },
    {
      name: "url",
      type: "multilingualUrl",
      description: "",
      validation: (Rule) => Rule.required()
    },
    {
      name: "type",
      type: "buttonType"
    },
    {
      title: "Open in new tab",
      name: "openInNewTab",
      type: "boolean"
    }
  ],
  preview: {
    select: {
      title: "label.en"
    },
    prepare({ title }) {
      return {
        title: title || "Discover more"
      };
    }
  }
});
