import { documentListWidget } from "sanity-plugin-dashboard-widget-document-list";
import { sscCruiseCodeSearchWidget } from "../plugins/ssc-cruise-code-search";
import { sscShopApiLinksWidget } from "../plugins/ssc-shop-api-links";
import { sscValidationChecksWidget } from "../plugins/ssc-validation-checks";
import { DashboardPluginConfig } from "@sanity/dashboard";
import { LiaShipSolid } from "react-icons/lia";

export default {
  name: "cruises-diagnostic",
  title: "Cruises Diagnostics",
  icon: LiaShipSolid,
  widgets: [
    sscCruiseCodeSearchWidget({
      layout: {
        width: "full",
        height: "auto"
      },
      silverseaApiRootUrl: process.env.SANITY_STUDIO_SILVERSEA_API
    }),
    sscShopApiLinksWidget({
      layout: {
        width: "medium",
        height: "auto"
      }
    }),
    sscValidationChecksWidget({
      layout: {
        width: "medium",
        height: "auto"
      },
      silverseaApiRootUrl: process.env.SANITY_STUDIO_SILVERSEA_API
    }),

    documentListWidget({
      title: "Unpublished/Slug-less Cruises with Visibility True",
      query:
        '*[_type == "cruise" && (_id in path("drafts.**") || !defined(metadata.routing.slug.en))  &&  data->isVisible == true] | order(_updatedAt desc)',
      limit: 1000,
      layout: {
        width: "medium",
        height: "auto"
      }
    }),
    documentListWidget({
      title: "Cruises with map override",
      query:
        '*[_type == "cruise" && defined(mapOverride.asset) && defined(data) && data->cruiseGroup != "AntarcticaBridge"]',
      limit: 1000,
      layout: {
        width: "medium",
        height: "auto"
      }
    })
  ]
} satisfies DashboardPluginConfig;
