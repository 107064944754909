import { defineType } from "sanity";

export default defineType({
  name: "heroSlideImage",
  type: "object",
  title: "Slide Image",
  fields: [
    {
      name: "image",
      type: "imageWithAlt",
      options: {
        collapsible: true,
        collapsed: false
      }
    }
  ],
  preview: {
    select: {
      imageUrl: "image.asset.url"
    }
  }
});
