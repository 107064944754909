import { defineType } from "sanity";
import { multilingualBlockContent, multilingualString } from "../base";
import imageWithAlt from "./imageWithAlt";

export default defineType({
  name: "suiteConfiguration",
  title: "Suite Configuration",
  type: "object",
  preview: {
    select: {
      title: "code",
      name: "name.en",
      image: "plan"
    },
    prepare(value) {
      return Object.assign({}, value, {
        title: value.title,
        subtitle: value.name,
        media: value.image
      });
    }
  },
  fields: [
    {
      name: "code",
      title: "Code",
      description: "e.g. O2",
      type: "string",
      validation: (Rule) => Rule.required()
    },
    {
      name: "name",
      title: "Name",
      description: "e.g. One bedroom, Accessible",
      type: multilingualString.name
    },
    {
      name: "shortSizeDescriptionInSquareFeet",
      title: "Short size description (sqft)",
      description: "e.g. 'Up to 77 ft² including veranda'",
      type: multilingualString.name
    },
    {
      name: "shortSizeDescriptionInSquareMetres",
      title: "Short size description (sqm)",
      description: "e.g. 'Up to 827 m² including veranda",
      type: multilingualString.name
    },
    {
      name: "verandaSizeInSquareFeet",
      title: "Veranda size (sqft)",
      description: "e.g. 'Up to 77 ft²",
      type: multilingualString.name
    },
    {
      name: "verandaSizeInSquareMetres",
      title: "Veranda size (sqm)",
      description: "e.g. 'Up to 827 m²",
      type: multilingualString.name
    },
    {
      name: "configurationInformation",
      title: "Configuration Information",
      description:
        "e.g. Please note that the 3rd guest will sleep on a comfortable sofa bed in the reception area of the suite.",
      type: multilingualBlockContent.name,
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "plan",
      title: "Plan",
      type: imageWithAlt.name
    },
    {
      name: "roomNumberFilter",
      title: "Room Number Filter",
      type: "array",
      of: [
        {
          type: "number"
        }
      ]
    }
  ]
});
