import { defineType } from "sanity";

export default defineType({
  name: "confirmedShorex",
  title: "Confirmed Shore Excursion",
  type: "object",
  preview: {
    select: {
      name: "shorexWebCode",
      includedInAllInclusive: "includedInAllInclusive"
    },
    prepare({ name, includedInAllInclusive }) {
      return {
        title: `${name} (${includedInAllInclusive ? "Included" : "Selected"})`
      };
    }
  },
  fields: [
    {
      title: "Shorex WebCode",
      description: "The web code of the shorex definition under the port.",
      name: "shorexWebCode",
      type: "string",
      readOnly: true
    },
    {
      name: "includedInAllInclusive",
      title: "Included in All Inclusive?",
      type: "boolean",
      readOnly: true
    },
    {
      name: "fareCodes",
      title: "Fare Codes",
      type: "array",
      of: [{ type: "string" }],
      readOnly: true
    }
  ]
});
