import { defineType } from "sanity";
import WordPressArticleSelector from "../../components/WordPressArticleSelector";

export default defineType({
  name: "blogArticle",
  title: "Blog Article",
  type: "object",
  preview: {
    select: {
      title: "wordPressArticleRef.title",
      subtitle: "wordPressArticleRef.url"
    }
  },
  fields: [
    {
      name: "wordPressArticleRef",
      title: "Word Press Article",
      type: "wordPressArticle",
      components: {
        input: WordPressArticleSelector
      }
    },
    {
      name: "ctaLabel",
      title: "CTA Label",
      type: "multilingualString"
    },
    {
      name: "categories",
      title: "Categories",
      type: "array",
      of: [
        {
          type: "string"
        }
      ]
    }
  ]
});
