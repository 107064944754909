import { FaPiggyBank } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  name: "signupForOffers",
  title: "Signup for Offers Page",
  icon: FaPiggyBank,
  type: "document",
  groups: [
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "heading",
      title: "Heading",
      type: "openingText"
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Signup for Offers Page"
      };
    }
  }
});
