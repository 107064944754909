import { FaLayerGroup } from "react-icons/fa";
import brochure from "./brochure";
import { defineType } from "sanity";

export default defineType({
  name: "brochureGrouping",
  title: "Brochure Group",
  type: "document",
  icon: FaLayerGroup,
  preview: {
    select: {
      name: "name"
    },
    prepare(value) {
      return {
        title: value.name
      };
    }
  },
  fields: [
    {
      name: "name",
      title: "Name",
      type: "string"
    },
    {
      name: "brochures",
      title: "Brochures",
      type: "array",
      of: [
        {
          name: "brochure",
          title: "Brochure",
          type: "reference",
          to: [{ type: brochure.name }]
        }
      ]
    }
  ]
});
