import { defineField } from "sanity";
import LocationFilterInput from "../../components/LocationFilterInput";

export default defineField({
  type: "object",
  name: "locationFilter",
  title: "Location",
  fields: [
    defineField({
      name: "locationFilter",
      type: "string",
      title: "Location",
      components: {
        input: LocationFilterInput
      }
    })
  ]
});
