export const customDateTokens = [
  "special_package_start_date",
  "special_package_end_date",
  "svalbard_petra_package_start_date",
  "frenchp_package_start_date",
  "frenchp_package_end_date"
];
export const customCurrencyTokens = [
  "svalbard_saving_per_suite",
  "svalbard_saving_per_person",
  "petra_saving_per_suite",
  "petra_saving_per_person",
  "denali_saving_per_person",
  "denali_saving_per_suite",
  "hotel_price",
  "far_east_package_per_person",
  "far_east_package_per_suite",
  "machu_picchu_saving_per_person",
  "machu_picchu_saving_per_suite",
  "frenchp_saving_per_person",
  "frenchp_saving_per_suite",

  "d2d_wave_savings_pp",
  "d2d_wave_savings_ps",
  "p2p_wave_savings_pp",
  "p2p_wave_savings_ps",
  "ess_wave_savings_pp",
  "ess_wave_savings_ps",
  "d2d_amount_discount_pp",
  "d2d_amount_discount_ps",
  "p2p_amount_discount_pp",
  "p2p_amount_discount_ps",
  "ess_amount_discount_pp",
  "ess_amount_discount_ps"
];

export default [
  "air_price",
  "air_price_business",
  "air_price_business_rt",
  "currency",
  "deposit",
  "ebb_discount",
  "expiration_date",
  "non_use_air_credit",
  "saving_per_person",
  "saving_per_suite",
  "saving_referral",
  "saving_referral_upto",
  "saving_referral_gv",
  "saving_referral_gv_upto",
  "saving_referral_wc",
  "saving_referral_wc_upto",
  "ship_board_credit",
  "ship_board_credit_ps",
  "ship_board_credit_pp",
  "start_date",
  "door_to_door",
  "combo_ship_board_credit",
  "p2p_prepaid",
  ...customDateTokens,
  ...customCurrencyTokens
];
