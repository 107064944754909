import React, { useState, useCallback } from "react";
import WidenAssetSearch from "../../widen/WidenAssetSearch";
import { DownloadIcon, RemoveIcon, AddIcon } from "@sanity/icons";
import {
  ObjectInputProps,
  ObjectSchemaType,
  set,
  setIfMissing,
  unset
} from "sanity";
import { Button, Card, Flex } from "@sanity/ui";
import { WidenAsset } from "../../widen/types";

const WidenVideoInput: React.FC<
  ObjectInputProps<
    {
      videoId?: string;
      posterImageDownloadUrl?: string;
      previewImageUrl?: string;
    },
    ObjectSchemaType
  >
> = (props) => {
  const { onChange, value } = props;
  const attributeName = props.schemaType.name;

  const handleSelectAsset = useCallback(
    (asset: WidenAsset) => {
      onChange([
        setIfMissing({ _type: attributeName }),
        set(asset.external_id, ["videoId"]),

        set(asset.thumbnails["600px"].url, ["previewImageUrl"]),

        set(`${asset.embeds.video_poster.url}&download=true`, [
          "posterImageDownloadUrl"
        ])
      ]);

      setShowDialog(false);
    },
    [onChange, attributeName]
  );

  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, []);

  const handleDownloadImage = useCallback(
    (e: unknown) => {
      if (value?.posterImageDownloadUrl)
        window.location.href = value.posterImageDownloadUrl;
    },
    [value?.posterImageDownloadUrl]
  );
  const handleRemoveAsset = useCallback(
    (e: unknown) => {
      onChange([unset()]);
      setShowDialog(false);
    },
    [onChange]
  );

  const [showDialog, setShowDialog] = useState(false);

  return (
    <React.Fragment>
      {value?.previewImageUrl && (
        <Card sizing="border">
          <img
            src={value.previewImageUrl}
            alt=""
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Card>
      )}

      <Button
        onClick={() => setShowDialog(true)}
        tone="primary"
        text="Select a video from Widen"
        icon={AddIcon}
      />
      {showDialog && (
        <WidenAssetSearch
          onClose={handleClose}
          handleSelect={handleSelectAsset}
          fileTypes="video"
        />
      )}

      {value && (
        <Flex gap={2}>
          <Button
            icon={DownloadIcon}
            onClick={handleDownloadImage}
            tone="default"
            text="Download Poster Image"
          />

          <Button
            mode="ghost"
            tone="critical"
            onClick={handleRemoveAsset}
            text="Remove video"
            icon={RemoveIcon}
            style={{ marginLeft: "auto" }}
          />
        </Flex>
      )}
    </React.Fragment>
  );
};

export default WidenVideoInput;
