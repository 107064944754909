import { filterableContentOf } from "../../utils/filterableContentOf";
import { multilingualBlockContentPreview } from "./multilingualBlockContent";
import { multilingualBlockContent } from "./index";

const subtitleMaxLength = 100;
const subtitlePreview = multilingualBlockContentPreview(subtitleMaxLength);

export default filterableContentOf(
  multilingualBlockContent.name,
  subtitlePreview
);
