import { defineType } from "sanity";
import metadataCommonFields from "./metadataCommonFields";
import routingWithoutParent from "./routingWithoutParent";

export default defineType({
  name: "metadataWithoutParent",
  title: "SEO, Routing & Tracking",
  options: {
    collapsible: true,
    collapsed: false
  },
  type: "object",
  fields: [
    {
      name: "routing",
      type: routingWithoutParent.name,
      title: "Routing",
      description: "Manage the URL of the page."
    },
    ...metadataCommonFields
  ]
});
