import { formatThisAndThatList } from "../../common/shared";
import airCondition from "./airCondition";
import { defineArrayMember, defineField } from "sanity";

export default defineField({
  name: "airConditions",
  title: "Air condition s",
  description:
    "The content above will be shown if the user's condition match any of the following.",
  type: "array",
  of: [
    defineArrayMember({
      type: airCondition.name
    })
  ],
  toReadableText: (conditions: string[] | undefined) =>
    formatThisAndThatList((conditions || []).map(airCondition.toReadableText!))
});
