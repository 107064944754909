import { defineType } from "sanity";

export default defineType({
  name: "shipSections",
  title: "Ship Sections",
  type: "object",
  fields: [
    {
      name: "forward",
      title: "Forward",
      type: "boolean"
    },
    {
      name: "midShip",
      title: "Mid-ship",
      type: "boolean"
    },
    {
      name: "aft",
      title: "Aft",
      type: "boolean"
    },
    {
      name: "port",
      title: "Port Side",
      type: "boolean"
    },
    {
      name: "starboard",
      title: "Starboard Side",
      type: "boolean"
    }
  ]
});
