import { defineType } from "sanity";
import { multilingualStringPreview } from "../base/multilingualArrayString";
import { GiTeacher } from "react-icons/gi";

export default defineType({
  name: "expeditionTeamMember",
  type: "document",
  icon: GiTeacher,
  title: "Expedition Team Member",
  fields: [
    {
      name: "picture",
      title: "Picture",
      type: "imageWithAlt"
    },
    {
      name: "title",
      type: "multilingualArrayString",
      title: "Title"
    },
    {
      name: "name",
      type: "string",
      title: "Name"
    },
    {
      name: "shortBio",
      type: "multilingualArrayBlockContent",
      title: "Short Bio"
    },
    {
      name: "destinationBios",
      type: "array",
      title: "Destination Bios",
      of: [
        {
          type: "expeditionTeamMemberDestinationBio"
        }
      ]
    }
  ],
  preview: {
    select: {
      name: "name",
      title: "title",
      media: "picture"
    },
    prepare({ title, name, media }) {
      return {
        title: name,
        subtitle: multilingualStringPreview(title),
        media
      };
    }
  }
});
