import { orderableDocumentListDeskItem } from "@sanity/orderable-document-list";
import { StructureBuilder, StructureResolverContext } from "sanity/structure";
import { redirect } from "../schemas/content-types";
import { ComponentType } from "react";

export default (S: StructureBuilder, context: StructureResolverContext) => {
  return orderableDocumentListDeskItem({
    type: redirect.name,
    S,
    context,
    icon: redirect.icon as ComponentType,
    title: "Redirects"
  });
};
