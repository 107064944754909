import { FaPiggyBank } from "react-icons/fa";
import { RiDraftLine } from "react-icons/ri";
import { fareCodes } from "../schemas/data-types/faresTokens";
import { StructureBuilder } from "sanity/structure";

function byFareCode(S: StructureBuilder, fareCode: string) {
  return S.listItem()
    .title(`${fareCode} Cruise Group Pages`)
    .child(
      S.documentTypeList("cruiseGroupPerFare")
        .title(`${fareCode} Cruise Group Pages`)
        .filter(`_type == 'cruiseGroupPerFare' && fareCode == "${fareCode}"`)
    );
}

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Offer")
    .icon(FaPiggyBank)
    .child(
      S.list()
        .title("Offers")
        .items([
          S.listItem()
            .title("Offers Per Promo")
            .schemaType("exclusiveOfferPerPromo")
            .child(S.documentTypeList("exclusiveOfferPerPromo").title("Offer")),
          S.listItem()
            .title("Promo Configurations")
            .schemaType("promoConfiguration")
            .child(
              S.documentTypeList("promoConfiguration").title(
                "Promo Configurations"
              )
            ),
          S.divider(),
          S.listItem()
            .title("All Cruise Group Pages")
            .schemaType("cruiseGroupPerFare")
            .child(S.documentTypeList("cruiseGroupPerFare").title("Offer")),
          S.divider(),

          S.listItem()
            .title(`Cruise Group Pages`)
            .child(
              S.list()
                .title(`Cruise Group Pages`)
                .items([
                  S.listItem()
                    .title("All Cruise Group Pages")
                    .icon(RiDraftLine)
                    .schemaType("cruiseGroupPerFare")
                    .child(
                      S.documentTypeList("cruiseGroupPerFare")
                        .title("All Cruise Group Pages")
                        .filter(`_type == 'cruiseGroupPerFare'`)
                    ),
                  S.listItem()
                    .title("Draft Cruise Group Pages")
                    .icon(RiDraftLine)
                    .schemaType("cruiseGroupPerFare")
                    .child(
                      S.documentTypeList("cruiseGroupPerFare")
                        .title("Draft Landing Pages")
                        .filter(
                          `_type == 'cruiseGroupPerFare' && (_id in path("drafts.**"))`
                        )
                    ),
                  ...fareCodes.map(({ title, value }) => byFareCode(S, value)),
                  S.divider(),
                  S.listItem()
                    .title("Landing Pages with offerDescriptionRow")
                    .icon(RiDraftLine)
                    .child(
                      S.documentTypeList("landingPage")
                        .title(`test`)
                        .filter(
                          `_type == "landingPage" && archived != true && count(sections) > 0 && sections[]._type == "offerDescriptionRow"`
                        )
                    ),
                  S.listItem()
                    .title("Pages with offerDescriptionRow")
                    .icon(RiDraftLine)
                    .child(
                      S.documentTypeList("page")
                        .title(`test`)
                        .filter(
                          `_type == "page" && count(sections) > 0 && sections[]._type == "offerDescriptionRow"`
                        )
                    )
                ])
            )
        ])
    );
