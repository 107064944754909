import { defineType } from "sanity";
import { multilingualArrayBlockContent } from "../base";

export default defineType({
  name: "packageInfographicCruiseCard",
  title: "Package Infographic Cruise Card",
  type: "object",
  fields: [
    {
      title: "Title",
      name: "title",
      type: multilingualArrayBlockContent.name
    },
    {
      title: "Subtitle",
      name: "subtitle",
      type: multilingualArrayBlockContent.name
    },
    {
      title: "Disclaimer",
      name: "disclaimer",
      type: multilingualArrayBlockContent.name
    }
  ]
});
