import { defineType } from "sanity";
import { multilingualStringPreview } from "../../base/multilingualString";
import { multilingualStringPreview as multilingualArrayStringPreview } from "../../base/multilingualArrayString";

export default defineType({
  name: "mainMenuSection",
  title: "Section",
  type: "object",
  preview: {
    select: {
      title: "title",
      card: "page.card"
    },
    prepare({ title, card }) {
      const pageTitle = card?.title
        ? multilingualStringPreview(card.title)
        : undefined;
      const overrideTitle = multilingualArrayStringPreview(title);

      return {
        title: overrideTitle || pageTitle
      };
    }
  },
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualArrayString"
    },
    {
      name: "page",
      title: "Page",
      type: "reference",
      to: [
        { type: "page" },
        { type: "landingPage" },
        { type: "corporateIncentivePage" }
      ]
    },
    {
      name: "trackingName",
      title: "Tracking Name",
      type: "string",
      description: "If empty, the page-specific tracking name will be used"
    },
    {
      name: "items",
      title: "Items",
      type: "array",
      of: [
        {
          type: "mainMenuGroup"
        }
      ]
    },
    {
      name: "trendingNow",
      title: "Trending Now",
      type: "array",
      of: [
        {
          type: "mainMenuItem"
        },
        {
          type: "locationSpecificMainMenuItem"
        },
        {
          type: "reference",
          to: [{ type: "specialVoyage" }, { type: "exclusiveOfferPerPromo" }]
        }
      ],
      validation: (Rule) => Rule.max(1)
    }
  ]
});
