import { defineType } from "sanity";
import { multilingualStringPreview } from "../base/multilingualArrayString";

export default defineType({
  title: "Micro Content Escursion Activity Level",
  name: "microContentExcursionActivityLevel",
  type: "object",
  fields: [
    {
      name: "iconStep",
      title: "Icon Step",
      description: "From 1 to 4",
      type: "number",
      validation: (Rule) => Rule.required().min(1).max(4)
    },

    {
      name: "label",
      title: "Label",
      type: "multilingualArrayString"
    },

    {
      name: "content",
      title: "Content",
      type: "multilingualArrayText"
    }
  ],
  preview: {
    select: {
      title: "label",
      iconStep: "iconStep"
    },
    prepare({ title, iconStep }) {
      return {
        title: multilingualStringPreview(title),
        subtitle: iconStep
      };
    }
  }
});
