import ship from "../content-types/ship";
import corporateIncentivePage from "../content-types/corporateIncentivePage";
import destination from "../content-types/destination";
import page from "../content-types/page";
import {
  requestAQuote,
  requestBrochure,
  signupForOffers
} from "../custom-pages";
import termsAndConditions from "../custom-pages/termsAndConditions";
import { pathField, slugField } from "./routingCommonFields";
import { defineType } from "sanity";

export default defineType({
  name: "routing",
  title: "Routing",
  validation: (Rule) => Rule.required(),
  options: {
    collapsible: true,
    collapsed: true
  },
  type: "object",
  fields: [
    slugField,
    {
      name: "parent",
      title: "Parent",
      description:
        "The page above this one in the path, e.g. 'Destinations' in 'es/destinos/cucero-antartida'",
      type: "reference",
      to: [
        page.name,
        destination.name,
        termsAndConditions.name,
        corporateIncentivePage.name,
        signupForOffers.name,
        requestAQuote.name,
        requestBrochure.name,
        ship.name
      ].map((type) => ({ type }))
    },
    pathField
  ]
});
