/* eslint-disable react/default-props-match-prop-types */
import React, { useCallback, useDeferredValue, useId, useState } from "react";
import { decode } from "html-entities";
import wordPressArticle from "../schemas/objects/wordPressArticle";
import {
  Autocomplete,
  BaseAutocompleteOption,
  Box,
  Card,
  Container,
  Flex,
  Stack,
  Text
} from "@sanity/ui";
import { ObjectInputProps, set, unset } from "sanity";
import { SearchIcon } from "@sanity/icons";
import { useQuery } from "react-query";

type WpPost = {
  id: number;
  title: string;
  url: string;
  type: string;
  subtype: string;
  _links: Links;
};

export interface Links {
  self: {
    embeddable: boolean;
    href: string;
  }[];
  about: { href: string }[];
  collection: { href: string }[];
}

const useWordpressSearch = (query: string | null) => {
  return useQuery({
    enabled: !!query && query?.length > 0,
    queryKey: ["wp", query],
    queryFn: async (): Promise<WpPost[]> => {
      const response = await fetch(
        `https://discover.silversea.com/wp-json/wp/v2/search?search=${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }
  });
};

const WordPressArticleSelector: React.FC<ObjectInputProps> = ({
  value: currentValue,
  onChange: originalOnChange
}) => {
  const [query, setQuery] = useState<string | null>(null);
  const deferredQuery = useDeferredValue(query);

  const state = useWordpressSearch(deferredQuery);
  const onHandleSearch = useCallback((query: string | null) => {
    setQuery(query);
  }, []);

  const inputId = useId();

  const onChange = useCallback(
    (value: string) => {
      if (!value || value.length == 0) originalOnChange(unset());
    },
    [originalOnChange]
  );

  const onSelect = useCallback(
    (value: string) => {
      const item = state.data?.find((x) => x.id.toString() == value);
      if (item)
        originalOnChange(
          set({
            id: item.id,
            title: decode(item.title),
            url: item.url,
            _key: item.id.toString(),
            _type: wordPressArticle.name
          })
        );
    },
    [originalOnChange, state.data]
  );

  const renderValue = useCallback(
    (
      value: string,
      option: (BaseAutocompleteOption & { options: WpPost }) | undefined
    ): string => {
      return (
        option?.options.title ||
        state.data?.find((x) => x.id.toString() == value.toString())?.title ||
        currentValue?.title ||
        value
      );
    },
    [currentValue, state.data]
  );
  return (
    <Container>
      <Autocomplete
        fontSize={[2, 2, 3]}
        icon={SearchIcon}
        id={inputId}
        options={state.data?.map<BaseAutocompleteOption & { options: WpPost }>(
          (x) => ({ value: `${x.id}`, options: x })
        )}
        placeholder={
          currentValue?.title ? currentValue.title : "Type to search..."
        }
        value={currentValue?.title}
        onQueryChange={onHandleSearch}
        onSelect={onSelect}
        onChange={onChange}
        filterOption={(query, option) => {
          return true;
        }}
        renderOption={(item) => {
          const urlWithoutDomain = new URL(item.options.url).pathname;
          return (
            <Card as="button">
              <Flex align="center">
                <Box flex={1} padding={3}>
                  <Stack space={3}>
                    <Text size={2} weight="medium">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.options.title
                        }}
                      />
                    </Text>
                    <Text muted size={1}>
                      {urlWithoutDomain}
                    </Text>
                  </Stack>
                </Box>
              </Flex>
            </Card>
          );
        }}
        // custom value render function
        renderValue={renderValue}
      />
    </Container>
  );
};

export default WordPressArticleSelector;
