import { MdViewCarousel } from "react-icons/md";
import { defineType } from "sanity";

export default defineType({
  title: "3. [Collection] Slider",
  name: "collectionSlider",
  type: "object",
  icon: MdViewCarousel,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "collection",
      title: "Collection",
      type: "string",
      options: {
        list: [
          { title: "Destinations", value: "destinations" },
          { title: "Offers", value: "offers" },
          { title: "Ships", value: "ships" }
        ]
      }
    },
    {
      name: "filterByType",
      title: "Filter by Type",
      type: "string",
      description: "leave empty to show all items",
      options: {
        list: [
          { title: "Ocean/Classic", value: "classic" },
          { title: "Expeditions", value: "expeditions" }
        ]
      },
      hidden: ({ parent }) =>
        !["destinations", "ships"].includes(parent?.collection)
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      collection: "collection"
    },
    prepare(selection) {
      const { title, collection } = selection;
      return {
        title: title || "([Collection] Slider)",
        subtitle: `Collection Slider: ${collection}`
      };
    }
  }
});
