import { FaFileContract } from "react-icons/fa";
import { StructureBuilder, StructureResolverContext } from "sanity/structure";

export default (S: StructureBuilder, context: StructureResolverContext) =>
  S.listItem()
    .title("Terms And Conditions")
    .icon(FaFileContract)
    .child(
      S.list()
        .title("Terms And Conditions")
        .items([
          S.listItem()
            .schemaType(`termsAndConditionsByMarket`)
            .title("By Market")
            .child(S.documentTypeList("termsAndConditionsByMarket")),

          S.divider(),
          S.listItem()
            .schemaType(`termsAndConditions`)
            .title("General pages")
            .child(S.documentTypeList("termsAndConditions")),

          S.documentListItem()
            .schemaType(`offersTermsAndConditions`)
            .icon(FaFileContract)
            .id(`offersTermsAndConditions-termsAndConditions`)
            .title(`Offers General Page`)
        ])
    );
