import { RiRefund2Fill } from "react-icons/ri";
import cancellationPolicyModalSectionContent from "../data-types/cancellationPolicyModalSectionContent";
import { defineType } from "sanity";

export default defineType({
  name: "cancellationPolicyModal",
  title: "Cancellation Policy Modal",
  icon: RiRefund2Fill,
  type: "document",
  fields: [
    {
      name: "cancelledBy",
      title: "Cancelled By",
      type: "array",
      of: [{ type: cancellationPolicyModalSectionContent.name }]
    }
  ],
  preview: {
    prepare() {
      return {
        title: "Cancellation Policy Modal"
      };
    }
  }
});
