import { defineField, defineType } from "sanity";

export default defineType({
  name: "videoWithPosterImage",
  title: "Video",
  type: "object",
  preview: {
    select: {
      title: "caption.en",
      media: "posterImage"
    }
  },
  fields: [
    {
      name: "file",
      title: "Video File",
      type: "widen.video"
    },
    {
      name: "trackingTitle",
      title: "Tracking Title",
      description: "(in English, for tracking only)",
      type: "string"
    },
    {
      name: "posterImage",
      title: "Poster Image",
      type: "image",
      options: {
        hotspot: true
      }
    },
    defineField({
      name: "caption",
      title: "Caption",
      type: "multilingualString",
      options: {
        collapsible: true,
        collapsed: true
      }
    })
  ]
});
