import { defineType } from "sanity";

export default defineType({
  title: "Simple Section Title",
  name: "simpleSectionTitle",
  type: "object",
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualArrayString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualArrayText"
    }
  ],
  preview: {
    select: {
      title: "title.en"
    },
    prepare({ title }) {
      return {
        title: title || ""
      };
    }
  }
});
