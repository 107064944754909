import { defineType } from "sanity";
import { contentFilterPreview } from "../../common/localization";
import { airNonAirFilter, locationFilter } from "../base";

export default defineType({
  name: "serviceRow",
  type: "object",
  title: "Service Row",
  fields: [
    {
      name: "tagline",
      title: "Tagline",
      type: "multilingualString"
    },
    {
      name: "title",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "description",
      type: "multilingualBlockContent",
      validation: (Rule) => Rule.required()
    },
    {
      name: "images",
      title: "Images",
      description: "if more than 1, a slideshow is shown",
      type: "array",
      of: [
        {
          type: "imageWithAlt"
        },
        {
          type: "videoWithPosterImage"
        }
      ]
    },
    {
      name: "link",
      title: "CTA Link",
      description: "deprecated, use ctaButtons",
      deprecated: {
        reason: "not used anymore"
      },
      type: "reference",
      to: { type: "page" }
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "locationFilters",
      title: "Location Filters",
      type: "array",
      of: [
        {
          type: locationFilter.name
        },
        {
          type: airNonAirFilter.name
        }
      ]
    }
    // {
    //   name: "layout",
    //   type: "string",
    //   validation: (Rule) => Rule.required(),
    //   options: {
    //     list: [
    //       { title: "Image left", value: "left" },
    //       { title: "Image right", value: "right" }
    //     ]
    //   }
    // }
  ],
  preview: {
    select: {
      title: "title.en",
      images: "images",
      locationFilters: "locationFilters"
    },
    prepare({ title, images, locationFilters }) {
      return {
        title,
        media: images[0],
        subtitle: contentFilterPreview(locationFilters)
      };
    }
  }
});
