import { nanoid } from "nanoid";
import { cloneBlockContentValue } from "../../utils/cloneBlockContentValue";

type Values = any | { _key: string } | {} | any[];

export const assignArrayKeys = (obj: Values): Values => {
  if (!obj) return obj;

  if (Array.isArray(obj)) {
    return obj.map(assignArrayKeys);
  }

  if (typeof obj == "object") {
    if (
      "_type" in obj &&
      typeof obj._type == "string" &&
      obj._type.includes("BlockContent")
    ) {
      return { ...cloneBlockContentValue(obj) };
    }

    const newObj = Object.fromEntries(
      Object.entries({ ...obj }).map(([key, value]) => {
        return [key, assignArrayKeys(value)];
      })
    );

    if (obj._key) {
      newObj._key = nanoid();
    }
    return newObj;
  }

  return obj;
};
