import { StructureBuilder } from "sanity/structure";
import * as microContent from "../schemas/micro-content";

const generateChildren = (S: StructureBuilder) =>
  Object.values(microContent).map((item) =>
    S.listItem()
      .title(item.title || item.name)
      .icon(item.icon as any)
      .child(
        S.document()
          .schemaType(item.name)
          .documentId(item.name)
          .title(item.title || item.name)
      )
  );

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Website Micro-Content")
    .child(S.list().title("Website Micro-Content").items(generateChildren(S)));
