import { DefaultDocumentNodeResolver } from "sanity/structure";
import DocumentsPane from "sanity-plugin-documents-pane";

export const defaultDocumentNodeResolver: DefaultDocumentNodeResolver = (S) =>
  S.document().views([
    S.view.form(),
    S.view
      .component(DocumentsPane)
      .options({
        query: `*[references($id)]`,
        params: { id: `_id` }
      })
      .title("Referenced by")
  ]);
