import { defineField } from "sanity";
export default defineField({
  title: "Button Type",
  type: "string",
  name: "buttonType",
  description: "leave empty for default",
  options: {
    list: [
      { title: "Primary", value: "primary" },
      { title: "Secondary", value: "secondary" },
      { title: "Secondary (gray)", value: "secondary-gray" },
      { title: "Tertiary", value: "tertiary" },
      { title: "Link", value: "link" }
    ]
  }
});
