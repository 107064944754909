import { defineType } from "sanity";
import { pathField, slugField } from "./routingCommonFields";

export default defineType({
  name: "routingWithoutParent",
  title: "Routing",
  validation: (Rule) => Rule.required(),
  options: {
    collapsible: true,
    collapsed: false
  },
  type: "object",
  //inputComponent: RoutingInput,
  fields: [slugField, pathField]
});
