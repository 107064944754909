import { defineField } from "sanity";
import {
  DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGE_IDS,
  ArrayLocalizedValueType
} from "../../common/localization";
import { stringIsPresent } from "../../utils/checks";

const firstValidTranslation = (selection: MultilingualArrayStringValue) =>
  SUPPORTED_LANGUAGE_IDS.map(
    (l) => selection?.langs?.find((x) => x._key == l)?.value
  ).filter(stringIsPresent)?.[0];

export type MultilingualArrayStringValue = {
  _type: "multilingualArrayString";
} & ArrayLocalizedValueType<"internationalizedArrayStringValue", string>;

export default defineField({
  name: "multilingualArrayString",
  title: "Multi-lingual String",
  type: "object",
  preview: {
    select: {
      langs: "langs"
    },
    prepare({ langs }) {
      return {
        title: (langs as MultilingualArrayStringValue["langs"])?.find(
          (x) => x._key == DEFAULT_LANGUAGE.id
        )?.value
      };
    }
  },
  fields: [
    defineField({
      title: " ", // yes, is correct
      name: "langs",
      type: "internationalizedArrayString"
    })
  ],

  toReadableText: (multilingualString: MultilingualArrayStringValue) =>
    firstValidTranslation(multilingualString)
});

export function multilingualStringPreview(
  multilingualString: MultilingualArrayStringValue
) {
  return (multilingualString || {}).langs?.find(
    (x) => x._key == DEFAULT_LANGUAGE.id
  )?.value;
}
