import { defineType } from "sanity";
import Input from "../components/Input";

export default defineType({
  name: "widen.video",
  type: "object",
  title: "Widen Video",
  fields: [
    {
      title: "Id",
      name: "videoId",
      type: "string"
    },
    {
      title: "Poster Image Download Url",
      name: "posterImageDownloadUrl",
      type: "string"
    },
    {
      title: "Preview Image Url",
      name: "previewImageUrl",
      type: "string"
    }
  ],
  components: {
    input: Input
  },
  preview: {
    select: {
      title: "title",
      image: "previewImageUrl"
    }
    // component: Preview
  }
});
