import { Label } from "@sanity/ui";
import styled from "styled-components";

const ValueLabel = styled(Label)`
  color: var(--card-muted-fg-color);
  text-align: left;
  white-space: nowrap;
  font-weight: 400;
`;

export default ValueLabel;
