import React, { ChangeEvent } from "react";

import {
  marketOptions,
  regionOptions,
  countryOptions,
  pathToLocation,
  locationToPath,
  sameSegmentAs,
  LocationOption
} from "../common/localization";
import { StringInputProps, set, unset } from "sanity";
import { Inline, Select } from "@sanity/ui";

type SegmentDropDownProps = {
  onChange: (value: string) => void;
  value: LocationOption | undefined;
  defaultText: string;
  values: LocationOption[] | undefined;
};

const SegmentDropDown: React.FC<SegmentDropDownProps> = ({
  onChange,
  value,
  defaultText,
  values = []
}) => {
  const options = [{ title: defaultText, value: "" }, ...values];

  const internalOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <Select
      onChange={internalOnChange}
      value={value?.value}
      //ref={ref}
    >
      {options.map((x) => (
        <option key={x.value} value={x.value}>
          {x.title}
        </option>
      ))}
    </Select>
  );
};

const createPatchFrom = (value: string) => {
  return value === "" ? unset() : set(value);
};

const LocationFilterInput: React.FC<StringInputProps> = ({
  value,
  onChange
}) => {
  const { market, region, countryCode } = pathToLocation(value || "");

  const onSegmentChange = (
    market: string,
    subregion?: string,
    country?: string
  ) => {
    return onChange(
      createPatchFrom(locationToPath(market, subregion, country))
    );
  };

  const filteredRegionOptions = regionOptions(market);
  const filteredCountryOptions = countryOptions(market, region);
  const sameMarket = sameSegmentAs(market);
  const sameRegion = sameSegmentAs(region);
  const sameCountry = sameSegmentAs(countryCode);

  const marketOption = marketOptions.find((m) => sameMarket(m.value));
  const regionOption = filteredRegionOptions.find((r) => sameRegion(r.value));
  const countryOption = filteredCountryOptions.find((c) =>
    sameCountry(c.value)
  );

  return (
    <Inline space={2}>
      <SegmentDropDown
        onChange={(value: string) => onSegmentChange(value)}
        value={marketOption}
        defaultText="(please select)"
        values={marketOptions}
      />
      {market && market !== "*" && (
        <SegmentDropDown
          onChange={(value) => onSegmentChange(market, value)}
          value={regionOption}
          defaultText={`(all ${market})`}
          values={filteredRegionOptions}
        />
      )}
      {region && (
        <SegmentDropDown
          onChange={(value) => onSegmentChange(market, region, value)}
          value={countryOption}
          defaultText={`(all ${region})`}
          values={filteredCountryOptions}
        />
      )}
    </Inline>
  );
};

export default LocationFilterInput;
