import { packageIcon } from "../base";
import airConditions from "../base/airConditions";
import { multilingualBlockContentPreview } from "../base/multilingualBlockContent";
import locationFilter from "../base/locationFilter";
import { filtersAsReadableText } from "../../utils/filterableContentOf";
import { defineType } from "sanity";

export default defineType({
  name: "exclusiveOfferFeature",
  title: "Feature by air condition",
  type: "object",
  preview: {
    select: {
      name: "name",
      conditionFilters: "conditionFilters",
      locationFilters: "locationFilters"
    },
    prepare: ({ name, conditionFilters, locationFilters }) => ({
      title: multilingualBlockContentPreview(100)(name),
      subtitle: filtersAsReadableText(conditionFilters, locationFilters)
    })
  },
  fields: [
    {
      name: "name",
      title: "Name",
      type: "multilingualBlockContent",
      validation: (Rule) => Rule.required()
    },
    {
      name: "description",
      title: "Description",
      type: "multilingualBlockContent"
    },
    {
      name: "icon",
      title: "Icon",
      type: packageIcon.name,
      validation: (Rule) => Rule.required()
    },
    {
      name: "conditionFilters",
      title: "Condition Filters",
      type: airConditions.name
    },
    {
      name: "locationFilters",
      title: "Location Filters",
      description:
        "The content above will be shown if the user's country matches any of the following (unless there is more specific content available for that country).",
      type: "array",
      of: [
        {
          type: locationFilter.name
        }
      ]
    }
  ]
});
