import { GiPathDistance } from "react-icons/gi";
import { imageWithCaption, videoWithPosterImage } from "../objects";
import { multilingualBlockContent } from "../base";
import { metadataWithoutParent } from "../page-fragments";
import { defineType } from "sanity";

export default defineType({
  name: "cruise",
  title: "Cruise",
  type: "document",
  orderings: [
    {
      title: "Cruise Code",
      name: "cruiseCodeAsc",
      by: [{ field: "data.cruiseCode", direction: "asc" }]
    }
  ],
  icon: GiPathDistance,
  fieldsets: [
    { name: "promotions", title: "Promotions" },
    { name: "features", title: "Features" },
    { name: "specialOffers", title: "Special Offers" },
    {
      name: "blog",
      title: "Blog",
      options: { collapsible: true, collapsed: true }
    }
  ],
  groups: [
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  preview: {
    select: {
      cruiseCode: "data.cruiseCode",
      departurePort: "data.departurePort.name.en",
      arrivalPort: "data.arrivalPort.name.en",
      media: "cardImage",
      departureDate: "data.departureDate",
      cruiseCodeCopy: "cruiseCode",
      id: "_id"
    },
    prepare({
      cruiseCode,
      departurePort,
      arrivalPort,
      media,
      departureDate,
      cruiseCodeCopy,
      id
    }) {
      return {
        title:
          cruiseCode ||
          `(deleted cruise) ${cruiseCodeCopy || id.replace("cruise-", "id: ")}`,
        subtitle:
          departurePort && arrivalPort
            ? `${departureDate} - ${departurePort} to ${arrivalPort}`
            : "(no Shop API data)",
        media
      };
    }
  },
  fields: [
    {
      name: "data",
      title: "Data",
      type: "reference",
      to: [{ type: "cruiseData" }],
      readOnly: true
    },
    {
      name: "cardImage",
      title: "Card Image",
      type: "image",
      options: {
        hotspot: true
      },
      validation: (Rule) => Rule.required()
    },
    {
      name: "specialType",
      title: "Special Type",
      type: "string",
      options: {
        list: [
          { title: "Top Cruise", value: "TopCruise" },
          { title: "Antarctica Bridge", value: "AntarcticaBridge" }
        ]
      }
    },
    {
      name: "slideshow",
      title: "Slideshow",
      type: "array",
      of: [
        {
          type: imageWithCaption.name
        },
        {
          type: videoWithPosterImage.name
        }
      ]
    },
    {
      name: "starText",
      title: "Star Text",
      type: "multilingualString"
    },
    {
      name: "keywords",
      title: "Searchable Keywords",
      type: "array",
      of: [
        {
          type: "multilingualString"
        }
      ]
    },
    {
      name: "itineraryDescription",
      title: "Itinerary Description",
      description: "Description text to appear below 'Itinerary & Excursions'",
      type: multilingualBlockContent.name
    },
    {
      name: "showCovidProcedure",
      title: "Show Covid procedure",
      description:
        "Show the standard Covid procedure message (unless overridden below)",
      type: "boolean"
    },
    {
      name: "overrideCovidProcedure",
      title: "Override COVID Procedure",
      description:
        "if empty, the standard COVID procedure text and link for the ship will be used",
      type: multilingualBlockContent.name
    },
    {
      name: "overrideItineraryDisclaimer",
      title: "Override Itinerary Disclaimer",
      description: "if empty, the standard disclaimer will be used",
      type: multilingualBlockContent.name
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: metadataWithoutParent.name,
      group: "seo"
    },
    {
      name: "cruiseCode",
      title: "Cruise Code",
      type: "string",
      readOnly: true
    },
    {
      name: "arrivalDate",
      title: "Arrival Date",
      type: "string",
      readOnly: true,
      hidden: true
    },
    {
      name: "mapOverride",
      title: "Map Override (optional, overrides image from ECM)",
      type: "image",
      options: {
        hotspot: true
      }
    },
    {
      name: "events",
      title: "Events",
      type: "array",
      of: [{ type: "cruiseEvent" }]
    },
    {
      name: "expeditionTeam",
      title: "Expedition Team",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "expeditionTeamMember" }]
        }
      ]
    },
    {
      name: "blog",
      title: "Blog Posts",
      type: "blogSection",
      fieldset: "blog"
    }
  ]
});
