import { defineType } from "sanity";
import { multilingualBlockContentPreview } from "../base/multilingualBlockContent";
import { filtersAsReadableText } from "../../utils/filterableContentOf";

export default defineType({
  name: "inclusionSpecificContent",
  title: "Inclusion Specific Content",
  type: "object",
  preview: {
    select: {
      name: "title",
      conditionFilters: "conditionFilters",
      locationFilters: "locationFilters"
    },
    prepare: ({ name, conditionFilters, locationFilters }) => ({
      title: multilingualBlockContentPreview(100)(name),
      subtitle: filtersAsReadableText(conditionFilters, locationFilters)
    })
  },
  fields: [
    {
      name: "infographicTitle",
      title: "Infographic title",
      type: "multilingualArrayString"
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualArrayBlockContent",
      options: { collapsible: false }
    },
    {
      name: "description",
      title: "Description",
      type: "multilingualArrayBlockContent",
      options: { collapsible: false }
    },
    {
      name: "footnote",
      title: "Footnote",
      type: "multilingualArrayString",
      options: { collapsible: false }
    }
  ]
});
