import { defineType } from "sanity";
import textTruncate from "../../utils/textTruncate";

export default defineType({
  name: "introSection",
  title: "Intro Section",
  options: {
    collapsible: true,
    collapsed: true
  },
  type: "object",
  fields: [
    {
      name: "leadIn",
      title: "Lead In",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent",
      validation: (Rule) => Rule.required()
    }
  ],
  preview: {
    select: {
      leadIn: "leadIn"
    },
    prepare(selection) {
      const { leadIn } = selection;
      return {
        title: textTruncate(leadIn.en)
      };
    }
  }
});
