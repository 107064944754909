import locationFilterSchema from "../schemas/base/locationFilter";
import airNonAirFilterSchema from "../schemas/base/airNonAirFilter";
import { countries, markets, regions } from "./countries";
import { formatThisAndThatList } from "./shared";
import { isPresent } from "../utils/checks";

export type LocationOption = {
  title: string;
  value: string;
};

export type LanguageCode = "en" | "es" | "pt_br" | "de" | "fr";

export type LocalizedValueType<T> = {
  [K in LanguageCode]?: T;
};

export type ArrayLocalizedValueType<
  T extends
    | "internationalizedArrayStringValue"
    | "internationalizedArrayTextValue"
    | "internationalizedArrayBlockContentValue",
  C
> = {
  langs?: {
    _key: LanguageCode;
    value: C;
    _type: T;
  }[];
};

export const DEFAULT_LANGUAGE: { id: LanguageCode; title: string } = {
  id: "en",
  title: "English"
};

export const SUPPORTED_LANGUAGES: { id: LanguageCode; title: string }[] = [
  DEFAULT_LANGUAGE,
  { id: "es", title: "Español" },
  { id: "pt_br", title: "Português" },
  { id: "de", title: "Deutsch" },
  { id: "fr", title: "Français" }
];

export const SUPPORTED_LANGUAGE_IDS = SUPPORTED_LANGUAGES.map((x) => x.id);

export const SUPPORTED_LANGUAGES_MAP = SUPPORTED_LANGUAGE_IDS.reduce(
  (s, i) => ({ ...s, [i]: i }),
  {}
);

type GenericMultilanguageValue = Record<LanguageCode, any>;

const pickFirst = <T extends Record<string, unknown>>(
  obj: T,
  keys: string[]
) => {
  if (!obj || typeof obj !== "object") {
    return obj;
  }
  const found = keys.find((key) => key in obj)!;
  return obj[found];
};

const takeWhile = (fn: (x: unknown) => boolean, a: string[]): string[] =>
  a.length && fn(a[0]) ? [a[0], ...takeWhile(fn, a.slice(1))] : [];

const hasValue = <T>(x: unknown): x is T => !!x;

export const toSingleLanguage = <T extends GenericMultilanguageValue>(
  multiLanguageValue: T
): T["en"] =>
  multiLanguageValue
    ? pickFirst(multiLanguageValue, SUPPORTED_LANGUAGE_IDS)
    : "";

export const pathToLocation = (value: string) => {
  const [market, region, countryCode] = (value || "").split("/");
  return {
    market,
    region,
    countryCode
  };
};

export const locationToPath = (...segments: (string | undefined)[]) =>
  takeWhile(hasValue, segments.filter(isPresent)).join("/") || "";

export const marketOptions: LocationOption[] = [
  { value: "*", title: "All markets" },
  ...markets.map<LocationOption>((x) => ({ value: x, title: x }))
];

function normalizeSegment(left: string | null) {
  return (left || "").toUpperCase().replace(" ", "-");
}

export const sameSegmentAs = (left: string) => (right: string | null) =>
  normalizeSegment(left) === normalizeSegment(right);

export const regionOptions = (market: string) => {
  const sameMarket = sameSegmentAs(market);
  return regions
    .filter((region) => sameMarket(region.market))
    .map<LocationOption>((region) => ({
      value: region.name,
      title: region.name
    }));
};

export const countryOptions = (market: string, region: string) => {
  const sameRegion = sameSegmentAs(`${market}/${region}`);
  return countries
    .filter((country) => sameRegion(country.regionKey))
    .map<LocationOption>((country) => ({
      title: `${country.name} (${country.code})`,
      value: country.code
    }));
};

const locationPreview = (path: string): string => {
  const { market, region, countryCode } = pathToLocation(path);
  if (!market) return "No location";
  if (market === "*") return "All markets";
  if (!region) return `${market} market`;
  if (!countryCode) return `${region} region`;

  return countries.find((x) => x.code === countryCode)?.name || "";
};

export const locationFilterPreview = (locationFilters: string[] | undefined) =>
  locationFilters
    ? `[${formatThisAndThatList(locationFilters.map(locationPreview))}]`
    : "";

const airNonAirFilterPreview = (value: string) => value;

const singleContentFilterPreview = (filter: FilterValue) => {
  switch (filter._type) {
    case locationFilterSchema.name:
      return locationPreview(filter.locationFilter);
    case airNonAirFilterSchema.name:
      return airNonAirFilterPreview(filter.airNonAirFilter);
    default:
      return "Unknown filter";
  }
};

export type LocationFilterValue = {
  _key: string;
  _type: "locationFilter";
  locationFilter: string;
};
export type ConditionFilterValue = {
  _key: string;
  _type: "airNonAirFilter";
  airNonAirFilter: string;
};

export type FilterValue = LocationFilterValue | ConditionFilterValue;

export const contentFilterPreview = (
  filters: FilterValue[] | undefined,
  filterExclusions?: FilterValue[] | undefined
): string => {
  const individualFilters = filters && filters.map(singleContentFilterPreview);
  const individualFilterExclusions =
    filterExclusions && filterExclusions.map(singleContentFilterPreview);

  return individualFilters && individualFilters.length
    ? `[${formatThisAndThatList(individualFilters)}${
        individualFilterExclusions
          ? `, except ${formatThisAndThatList(individualFilterExclusions)}`
          : ""
      }]`
    : "";
};
