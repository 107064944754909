import * as tokens from "../schemas/tokens";

type TokenName = keyof typeof tokens;

export default () => {
  return Object.keys(tokens).map((type) => {
    return {
      type: type
    };
  });
};

export const allTokenTypes = () =>
  Object.keys(tokens).map((type) => tokens[type as TokenName]);
