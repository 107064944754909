import { FaWalking } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  name: "shorex",
  title: "Shore Excursion",
  type: "document",
  icon: FaWalking,
  preview: {
    select: {
      name: "name",
      code: "code"
    },
    prepare({ name, code }) {
      return {
        title: name,
        subtitle: code
      };
    }
  },
  fields: [
    {
      name: "port",
      type: "reference",
      weak: true,
      to: [{ type: "port" }],
      readOnly: true
    },
    {
      name: "shorexWebCode",
      title: "Shorex Web Code",
      description:
        "shorexWebCode from Shop API, used to match itineraryDay.confirmedShorexes",
      type: "string",
      validation: (Rule) => Rule.required(),
      readOnly: true
    },
    {
      name: "name",
      title: "Name",
      type: "string",
      validation: (Rule) => Rule.required(),
      readOnly: true
    },
    {
      name: "code",
      title: "Code",
      type: "string",
      validation: (Rule) => Rule.required(),
      readOnly: true
    },
    {
      name: "activityLevel",
      title: "Activity Level",
      description:
        "1 - Minimal, 2 - Moderate, 3 - Extensive, 4 - Physically fit",
      type: "number",
      readOnly: true
    },
    {
      name: "category",
      title: "Category",
      type: "string",
      readOnly: true
    },
    {
      name: "accessibility",
      title: "Accessibility",
      type: "string",
      readOnly: true
    },
    {
      name: "features",
      title: "Features (icons)",
      type: "array",
      of: [{ type: "string" }],
      readOnly: true
    },
    {
      name: "duration",
      title: "Duration",
      type: "string"
    },
    {
      name: "image",
      title: "Image",
      description: "Image used in the popup (where available)",
      type: "image",
      options: {
        hotspot: true
      },
      readOnly: true
    },
    {
      name: "descriptionHtml",
      title: "Description (Raw HTML)",
      description: "HTML used in the popup",
      type: "text",
      readOnly: true
    },
    {
      name: "noteHtml",
      title: "Note (Raw HTML)",
      description: "HTML used in the popup",
      type: "text",
      readOnly: true
    }
  ]
});
