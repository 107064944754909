import { FaBook } from "react-icons/fa";
import { defineType } from "sanity";
import { multilingualStringPreview } from "../base/multilingualArrayString";

export default defineType({
  name: "termsAndConditionsSection",
  title: "Terms And Conditions (Section)",
  type: "object",
  icon: FaBook,
  preview: {
    select: {
      title: "title"
    },
    prepare({ title }) {
      return {
        title: multilingualStringPreview(title)
      };
    }
  },
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualArrayString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualArrayBlockContent"
    }
  ]
});
