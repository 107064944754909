import {
  SUITE_FEATURE_GROUPINGS,
  SuiteFeature
} from "@silversea-ssc/web-suite-features";
import { multilingualString } from "../base";
import { defineField, defineType } from "sanity";
type SuiteFeatureGrouping = (typeof SUITE_FEATURE_GROUPINGS)[number];

const mainField = (grouping: SuiteFeatureGrouping, feature: SuiteFeature) => {
  if (feature.type === "boolean")
    return defineField({
      name: feature.key,
      type: "boolean",
      title: feature.title,
      fieldset: grouping.key
    });
  if (feature.type === "options")
    return defineField({
      name: feature.key,
      type: "string",
      title: feature.title,
      fieldset: grouping.key,
      options: {
        list: [
          ...(feature.notPresentLabel
            ? [{ value: undefined, title: feature.notPresentLabel }]
            : []),
          ...feature.options.map(({ key, label }) => ({
            title: label,
            value: key
          }))
        ],
        layout: "radio",
        direction: "vertical"
      }
    });
  if ((feature as any).type === "freeText")
    // TODO: check if really needed
    return defineField({
      name: (feature as any).key,
      type: multilingualString.name,
      title: (feature as any).title,
      fieldset: grouping.key,
      validation: (Rule) => Rule.required()
    });
};

const caveatField = (grouping: SuiteFeatureGrouping, feature: SuiteFeature) =>
  defineField({
    name: `${feature.key}_caveat`,
    type: multilingualString.name,
    title: `${feature.title} (Caveats)`,
    fieldset: grouping.key
  });

export default defineType({
  name: "comparableFeatures",
  title: "Features",
  type: "object",
  fieldsets: SUITE_FEATURE_GROUPINGS.map((grouping) => ({
    name: grouping.key,
    title: grouping.title
  })),
  fields: SUITE_FEATURE_GROUPINGS.flatMap((grouping) =>
    grouping.features.flatMap((feature) => {
      const main = mainField(grouping, feature);
      return [
        ...(main ? [main] : []),
        ...(feature.caveat ? [caveatField(grouping, feature)] : [])
      ];
    })
  )
});
