import { MdSettingsInputComponent } from "react-icons/md";
import { defineType } from "sanity";

export default defineType({
  title: "6. [Dev] custom component",
  name: "customComponent",
  icon: MdSettingsInputComponent,
  type: "object",
  fields: [
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "componentName",
      title: "Component Name",
      type: "string"
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      componentName: "componentName"
    },
    prepare({ title, componentName }) {
      return {
        title: title || "([Dev] custom component)",
        subtitle: componentName
      };
    }
  }
});
