import { FaBus } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  name: "landProgramme",
  title: "Land Programme",
  type: "document",
  icon: FaBus,
  preview: {
    select: {
      name: "name"
    },
    prepare(value) {
      return {
        title: value.name
      };
    }
  },
  fields: [
    {
      name: "port",
      type: "reference",
      weak: true,
      to: [{ type: "port" }],
      readOnly: true
    },
    {
      name: "landProgrammeId",
      title: "Land Programme ID",
      description:
        "land_id from Shop API, used to match itineraryDay.confirmedLandProgrammes",
      type: "number",
      validation: (Rule) => Rule.required(),
      readOnly: true
    },
    {
      name: "name",
      title: "Name",
      type: "string",
      validation: (Rule) => Rule.required(),
      readOnly: true
    },
    {
      name: "code",
      title: "Code",
      type: "string",
      validation: (Rule) => Rule.required(),
      readOnly: true
    },
    {
      name: "duration",
      title: "Duration",
      type: "number",
      readOnly: true
    },
    {
      name: "image",
      title: "Image",
      description: "Image used in the popup (where available)",
      type: "image",
      options: {
        hotspot: true
      },
      readOnly: true
    },
    {
      name: "descriptionHtml",
      title: "Description (Raw HTML)",
      description: "HTML used in the popup",
      type: "text",
      readOnly: true
    }
  ]
});
