import { FaAnchor } from "react-icons/fa";
import { publishAll } from "../utils/publishAll";
import { StructureBuilder } from "sanity/structure";

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Ports & Excursions")
    .icon(FaAnchor)
    .child(
      S.list()
        .title("Ports & Excursions")
        .items([
          S.listItem()
            .title("Ports")
            .schemaType("port")
            .child(
              S.documentTypeList("port").menuItems([
                ...(S.documentTypeList("port").getMenuItems() || []),
                S.menuItem()
                  .title("Publish all")
                  .action(() =>
                    publishAll("port", S.context.getClient, S.context.schema)
                  )
              ])
            ),
          S.listItem()
            .title("Shore Excursions")
            .schemaType("shorex")
            .child(S.documentTypeList("shorex")),
          S.listItem()
            .title("Land Programmes")
            .schemaType("landProgramme")
            .child(S.documentTypeList("landProgramme")),
          S.listItem()
            .title("Hotels")
            .schemaType("hotel")
            .child(S.documentTypeList("hotel"))
        ])
    );
