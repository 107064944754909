import { FaGlobe } from "react-icons/fa";
import { featureToggles, imageWithAlt } from "../objects";
import { page } from ".";
import { defineType } from "sanity";

export default defineType({
  name: "site",
  title: "Site",
  icon: FaGlobe,
  type: "document",
  fieldsets: [
    {
      name: "featureToggles",
      title: "Feature Toggles"
    },
    {
      name: "menu",
      title: "Main Menu",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "redirects",
      title: "Redirects",
      options: { collapsible: true, collapsed: true }
    }
  ],
  fields: [
    {
      title: "Feature Toggles",
      name: "featureToggles",
      description: "Features to be toggled off on live until sign-off",
      type: featureToggles.name
    },
    {
      title: "Pre-launch Cruise Codes",
      name: "preLaunchCruiseCodes",
      description:
        "List of cruise codes to be published in the next big launch. Cruise codes listed here will be included in diagnostic checks alongside visible/live cruises. (One cruise code per line)",
      type: "text"
    },
    {
      title: "Open graph",
      name: "openGraph",
      description:
        "These will be the default meta tags on all pages that have not set their own",
      type: "openGraph",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      fieldset: "menu",
      name: "ashoreMenuItems",
      title: "Ashore Menu Items",
      type: "array",
      of: [{ type: "menuItem" }]
    },
    {
      fieldset: "menu",
      name: "footerMenuGroups",
      title: "Footer Menu Groups",
      type: "array",
      of: [{ type: "menuGroup" }]
    },
    {
      fieldset: "menu",
      name: "menuPrimaryCTA",
      title: "Menu Primary CTA",
      type: "customMenuItem",
      description:
        "Primary CTA in the main menu, if empty FYC link will be shown",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      title: "Default Port Image",
      name: "defaultPortImage",
      type: imageWithAlt.name
    },
    {
      name: "worldCruisesPage",
      title: "World Cruises Page",
      type: "reference",
      to: [{ type: page.name }],
      validation: (Rule) => Rule.required(),
      readOnly: true
    },
    {
      name: "grandVoyagesPage",
      title: "Grand Voyages Page",
      type: "reference",
      to: [{ type: page.name }],
      validation: (Rule) => Rule.required(),
      readOnly: true
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Site"
      };
    }
  }
});
