import { defineType } from "sanity";

export default defineType({
  name: "raqCTA",
  type: "object",
  title: "RAQ CTA",
  fields: [
    {
      name: "label",
      type: "multilingualString",
      description: 'leave empty for "Request a Quote"'
    },
    {
      name: "cruise",
      title: "Cruise",
      type: "reference",
      to: [{ type: "cruise" }, { type: "specialVoyage" }]
    },
    {
      name: "type",
      type: "buttonType"
    }
  ],
  preview: {
    select: {
      title: "label.en"
    },
    prepare({ title }) {
      return {
        title: title || "Request a Quote"
      };
    }
  }
});
