import { DashboardWidgetContainer } from "@sanity/dashboard";
import { Label } from "@sanity/ui";
import styled from "styled-components";
import links from "./links";

const ValueLabel = styled(Label)`
  color: var(--card-muted-fg-color);
  text-align: left;
  white-space: nowrap;
  font-weight: 400;
`;

const ShopApiLinks: React.FC = () => (
  <DashboardWidgetContainer header="Shop API Links">
    <table cellSpacing="21px">
      <tbody>
        {links.map((link, i) => (
          <tr key={i}>
            <th>
              <ValueLabel>{link.name}</ValueLabel>
            </th>

            <td>
              <a
                href={`https://shop.silversea.com${link.url}${
                  link.url.indexOf("?") > -1 ? "&" : "?"
                }per_page=10&page=1&envelope=true`}
              >
                {link.url}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </DashboardWidgetContainer>
);

export default ShopApiLinks;
