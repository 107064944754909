import { SanityDocument, defineField, defineType } from "sanity";

export default defineType({
  name: "milestoneItem",
  type: "object",
  title: "Milestone Item",
  fields: [
    defineField({
      name: "icon",
      title: "Colored Icon",
      type: "coloredIcon",
      validation: (Rule) =>
        Rule.custom<string>((field, context) => {
          return (context?.parent as SanityDocument)?.normalIcon ===
            undefined && field === undefined
            ? "Either Colored Icon or Normal Icon must have a value"
            : true;
        })
    }),
    defineField({
      name: "normalIcon",
      title: "Normal Icon",
      type: "designSystemIcon",
      validation: (Rule) =>
        Rule.custom((field, context) =>
          (context?.parent as SanityDocument)?.icon === undefined &&
          field === undefined
            ? "Either Colored Icon or Normal Icon must have a value"
            : true
        )
    }),
    {
      name: "title",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "description",
      type: "multilingualText",
      validation: (Rule) => Rule.required()
    }
  ],
  preview: {
    select: {
      title: "title.en",
      media: "image"
    },
    prepare({ title, media }) {
      return {
        title,
        media
      };
    }
  }
});
