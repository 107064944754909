import { FaFlag } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  name: "region",
  title: "Region",
  type: "document",
  icon: FaFlag,
  orderings: [
    {
      title: "Name",
      name: "nameAsc",
      by: [{ field: "name.en", direction: "asc" }]
    }
  ],
  preview: {
    select: {
      title: "name.en"
    }
  },
  fields: [
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "country",
      title: "Country",
      type: "reference",
      to: [{ type: "country" }],
      validation: (Rule) => Rule.required()
    }
  ]
});
