import { AiOutlineForm } from "react-icons/ai";
import { StructureBuilder } from "sanity/structure";

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Signup for Offers")
    .icon(AiOutlineForm)
    .child(
      S.list()
        .title("Signup for Offers")
        .items([
          S.listItem()
            .title("Signup for Offers Page")
            .icon(AiOutlineForm)
            .child(
              S.editor()
                .id("signup-for-offers-page")
                .schemaType("signupForOffers")
                .documentId("signup-for-offers-page")
            ),
          S.listItem()
            .title("Signup for Offers - Thank you page")
            .icon(AiOutlineForm)
            .child(
              S.editor()
                .id("signup-for-offers-thankyou-page")
                .schemaType("signupForOffersThankYou")
                .documentId("signup-for-offers-thankyou-page")
            )
        ])
    );
