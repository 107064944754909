import { defineType } from "sanity";

export default defineType({
  name: "destinationPackage",
  title: "Package",
  type: "object",
  preview: {
    select: {
      title: "name.en"
    }
  },
  fields: [
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "items",
      title: "Items",
      type: "array",
      of: [{ type: "destinationPackageItem" }]
    },
    {
      name: "showSpecialCruisesFilterLink",
      title: "Show Special Cruises Filter Link",
      type: "boolean",
      options: {
        layout: "checkbox"
      }
    }
  ]
});
