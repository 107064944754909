import { defineType } from "sanity";
import { designSystemIconPreview } from "../../components/DesignSystemIconInput";

export default defineType({
  name: "iconHighlight",
  type: "object",
  title: "Icon Highlights",
  fields: [
    {
      name: "icon",
      title: "Icon",
      type: "designSystemIcon"
    },
    {
      name: "label",
      title: "Label",
      type: "multilingualString"
    }
  ],
  preview: {
    select: {
      title: "label.en",
      icon: "icon"
    },
    prepare({ title, icon }) {
      return {
        title: title,
        media: designSystemIconPreview(icon)
      };
    }
  }
});
