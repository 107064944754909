import { defineType } from "sanity";

export default defineType({
  name: "eCommerceBenefitsContent",
  title: "ECommerce Benefits Content",
  type: "object",
  preview: {
    select: {
      title: "title.en"
    }
  },
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualArrayString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "benefits",
      title: "Benefits",
      type: "array",
      of: [{ type: "multilingualArrayString" }]
    }
  ]
});
