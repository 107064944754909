import imageWithCaption from "../objects/imageWithCaption";
import { multilingualBlockContent } from "../base";
import { defineType } from "sanity";
import publicAreaCategory from "./publicAreaCategory";

export default defineType({
  name: "shipFacility",
  title: "Ship Facility",
  type: "object",
  preview: {
    select: {
      title: "name.en",
      imageUrl: "images.0.asset.url"
    }
  },
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "category",
      title: "Category",
      type: "reference",
      to: [{ type: publicAreaCategory.name }],
      validation: (Rule) => Rule.required()
    },
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      validation: (Rule) => Rule.required(),
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "description",
      title: "Description",
      type: multilingualBlockContent.name,
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "images",
      title: "Images",
      type: "array",
      of: [{ type: imageWithCaption.name }]
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    }
  ]
});
