import { defineType } from "sanity";
import { multilingualStringPreview } from "../../base/multilingualString";

export default defineType({
  name: "mainMenuItem",
  title: "Item",
  type: "object",
  preview: {
    select: {
      title: "title",
      page: "page.card"
    },
    prepare({ page }) {
      const { title } = page || {};
      return {
        title: multilingualStringPreview(title)
      };
    }
  },
  fields: [
    {
      name: "page",
      title: "Page",
      type: "reference",
      to: [{ type: "page" }, { type: "landingPage" }, { type: "farePage" }]
    },
    {
      name: "trackingName",
      title: "Tracking Name",
      type: "string",
      description: "If empty, the page-specific tracking name will be used"
    },
    {
      name: "title",
      title: "Title",
      description: "Override label",
      type: "multilingualArrayString"
    },
    {
      name: "content",
      title: "Content",
      description: "Override content",
      type: "multilingualArrayText"
    },
    {
      name: "icon",
      type: "designSystemIcon"
    }
  ]
});
