import { defineType } from "sanity";

export default defineType({
  title: "Open Graph",
  name: "openGraph",
  type: "object",
  fields: [
    {
      title: "Title",
      name: "title",
      type: "multilingualString",
      description: "Heads up! This will override the page title.",
      options: {
        collapsible: false
      }
      // validation: (Rule) =>
      //   Rule.max(60).warning("Should be under 60 characters")
    },
    {
      title: "Description",
      name: "description",
      type: "multilingualText",
      options: {
        collapsible: false
      }
      // validation: (Rule) =>
      //   Rule.max(155).warning("Should be under 155 characters")
    },
    {
      title: "Image",
      description: "Facebook recommends 1200x630 (will be auto resized)",
      name: "image",
      type: "image"
    }
    /*
    // You can add videos to Open Graph tags too
    {
      name: 'video',
      title: 'Video',
      type: 'mux.video'
    }
    */
  ],
  preview: {
    select: {
      title: "title.en",
      route: "route.slug.current",
      link: "link"
    },
    prepare({ title, route, link }) {
      return {
        title,
        // eslint-disable-next-line no-nested-ternary
        subtitle: route
          ? `Route: /${route}/`
          : link
          ? `External link: ${link}`
          : "Not set"
      };
    }
  }
});
