import { FcTimeline } from "react-icons/fc";
import { StructureBuilder, StructureResolverContext } from "sanity/structure";
import { inclusion, cruiseGroupInclusions } from "../schemas/content-types";
import { orderableDocumentListDeskItem } from "@sanity/orderable-document-list";
import { ComponentType } from "react";

export default (S: StructureBuilder, context: StructureResolverContext) =>
  S.listItem()
    .title("Inclusions")
    .icon(FcTimeline)
    .child(
      S.list()
        .title("Inclusions")
        .items([
          S.listItem()
            .title("Infographic Inclusions")
            .child(S.documentTypeList(inclusion.name)),
          orderableDocumentListDeskItem({
            type: cruiseGroupInclusions.name,
            S,
            context,
            icon: cruiseGroupInclusions.icon as ComponentType,
            title: "Cruise Group Inclusions"
          }),
          S.divider(),
          S.documentListItem()
            .schemaType(`expeditionInclusionModal`)
            .id(`expedition-inclusion-modal`)
            .title(`Expedition Inclusion Modal`)
        ])
    );
