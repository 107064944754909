import { RxSection } from "react-icons/rx";
import { defineType } from "sanity";
import { multilingualStringPreview } from "../base/multilingualArrayString";
import { orderRankField } from "@sanity/orderable-document-list";

export default defineType({
  name: "brochureContentGroup",
  title: "Brochure Content Group",
  type: "document",
  icon: RxSection,
  preview: {
    select: {
      title: "title"
    },
    prepare({ title }) {
      return {
        title: multilingualStringPreview(title)
      };
    }
  },
  fields: [
    orderRankField({ type: "brochureContentGroup" }),
    {
      name: "title",
      title: "Title",
      type: "multilingualArrayString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualArrayText"
    }
  ]
});
