import { defineField } from "sanity";

const toReadableText = (condition: string): string => {
  switch (condition) {
    case "freeeconomy":
      return "Free Economy";
    case "freebusiness":
      return "Free Business";
    case "nonuseaircredit":
      return "Special Saving";
    case "freeeconomynobc":
      return "Free Economy/No Business";
    case "*":
      return "Always";
    default:
      return "";
  }
};

export default defineField({
  type: "string",
  name: "airCondition",
  title: "Condition",
  options: {
    list: [
      {
        title: toReadableText("*"),
        value: "*"
      },
      {
        title: toReadableText("freeeconomy"),
        value: "freeeconomy"
      },
      {
        title: toReadableText("freebusiness"),
        value: "freebusiness"
      },
      {
        title: toReadableText("nonuseaircredit"),
        value: "nonuseaircredit"
      },
      {
        title: toReadableText("freeeconomynobc"),
        value: "freeeconomynobc"
      }
    ]
  },
  toReadableText
});
