const WidenIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="1 1 30 30"
      height="1em"
      width="1em"
    >
      <path
        fill="#bf311a"
        d="M16 1C7.7 1 1 7.7 1 16s6.7 15 15 15 15-6.7 15-15S24.3 1 16 1z"
      />
      <g fill="#fff">
        <path d="m12.6 23.5-6-10.9c-.2-.4.1-.8.5-.8h2.1c.3 0 .6.2.8.5l4.6 8.6c.2.3.1.6 0 .9l-1.1 1.8c-.2.2-.7.2-.9-.1zM21 18.4l-3.2-5.8c-.2-.4.1-.8.5-.8H25c.4 0 .7.5.5.8L22 18.4c-.3.3-.8.3-1 0zM18 23.2l-5.8-10.7c-.2-.4.1-.8.5-.8h2.1c.3 0 .6.2.8.5l4.5 8.3c.2.3.1.6 0 .9L19 23.2c-.3.4-.8.4-1 0z" />
      </g>
    </svg>
  );
};

export default WidenIcon;
