import { Box, Stack, Button } from "@sanity/ui";
import React, { useCallback, useState } from "react";
import { useSchema, ObjectSchemaType } from "sanity";
import SelectableList, { SchemaFieldset } from "./SelectableList";
import { UploadIcon } from "@sanity/icons";

type Props = {
  onClick: (selectedItems: SchemaFieldset[]) => void;
  type: string;
};

const PartialPublishDialog: React.FC<Props> = ({ onClick, type }) => {
  const [selectedItems, setSelectedItems] = useState<SchemaFieldset[]>([]);
  const schema = useSchema();
  const schemaType = schema.get(type) as ObjectSchemaType;

  const mappedFieldsets =
    schemaType!.fieldsets?.map<SchemaFieldset>((fieldset) => {
      if (fieldset.single) {
        return {
          _key: fieldset.field.type.name,
          title: fieldset.field.type.title,
          fieldNames: [fieldset.field.name]
        };
      }
      return {
        _key: fieldset.name,
        title: fieldset.title,
        fieldNames: fieldset.fields.map((x) => x.name)
      };
    }) || [];

  const onSelectionToggle = useCallback(
    (section: SchemaFieldset, selected: boolean) => {
      setSelectedItems((items) => {
        if (selected) return [...items, section];

        return items.filter((s) => s._key != section._key);
      });
    },
    []
  );

  return (
    <Box>
      <Stack>
        <SelectableList
          sections={mappedFieldsets}
          selectedSections={selectedItems}
          onSectionToggle={onSelectionToggle}
        />
      </Stack>
      <Stack>
        <Button
          tone="positive"
          iconRight={UploadIcon}
          onClick={() => onClick(selectedItems)}
        >
          Publish Selected ({selectedItems.length})
        </Button>
      </Stack>
    </Box>
  );
};

export default PartialPublishDialog;
