import { FaMoneyBill } from "react-icons/fa";
import {
  filterableAsCardWithIcon,
  filterableMultilingualBlockContent,
  filterableMultilingualString,
  locationFilter
} from "../base";
import { exclusiveOfferFeature } from "../objects";
import {
  asCard,
  asCardAirCondition,
  metadataWithoutParent
} from "../page-fragments";
import allPageBuilderSections from "../../common/allPageBuilderSections";
import filterableNumber from "../base/filterableNumber";
import { cruiseGroups } from "../data-types/cruiseGroups";
import { defineField, defineType } from "sanity";

export default defineType({
  name: "cruiseGroupPerFare",
  title: "Cruise Group by Fare",
  type: "document",
  icon: FaMoneyBill,
  preview: {
    select: {
      title: "title",
      media: "heroImage",
      cruiseGroup: "cruiseGroup"
    },
    prepare: ({ title, media, cruiseGroup }) => ({
      //@ts-ignore
      title: filterableMultilingualBlockContent.toString(title[0]),
      media,
      subtitle: cruiseGroup
    })
  },
  fieldsets: [
    {
      name: "features",
      title: "Features",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "visibility",
      title: "Visibility",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "cruises",
      title: "Cruises",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  groups: [
    {
      name: "termsAndConditions",
      title: "Terms&Conditions"
    },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "cruiseGroup",
      title: "Cruise Group",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: cruiseGroups
      }
    },
    {
      name: "customTokens",
      title: "Custom Tokens",
      group: "termsAndConditions",
      type: "array",
      of: [
        {
          title: "Currency Token",
          type: "currencyToken"
        },
        {
          title: "Date Token",
          type: "dateToken"
        }
      ]
    },
    {
      name: "order",
      title: "Order",
      group: "termsAndConditions",
      description:
        "in ascending order (1: first in the list, 100000: last in the list)",
      type: "array",
      of: [
        {
          type: filterableNumber.name
        }
      ]
    },
    {
      name: "termsAndConditions",
      title: "Terms & Conditions",
      group: "termsAndConditions",
      type: "array",
      of: [
        {
          type: filterableMultilingualBlockContent.name
        }
      ]
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "locationFilters",
      title: "Location Filters",
      type: "array",
      of: [
        {
          type: locationFilter.name
        }
      ]
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "fareCode",
      title: "Fare Code",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: [
          { title: "Door To Door", value: "DoorToDoor" },
          { title: "Air Included", value: "AirIncluded" },
          { title: "Port To Port", value: "PortToPort" }
        ]
      }
    },
    defineField({
      deprecated: {
        reason: "not used anymore"
      },
      name: "heroImage",
      title: "Hero Image",
      type: "imageWithAlt",
      options: {
        hotspot: true
      },
      validation: (Rule) => Rule.required()
    }),

    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "title",
      title: "Title",
      type: "array",
      of: [{ type: filterableMultilingualBlockContent.name }],
      validation: (Rule) => Rule.required()
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "description",
      title: "Description",
      type: "multilingualBlockContent",
      validation: (Rule) => Rule.required()
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "customCTALabel",
      title: "Custom CTA Label",
      description: "if empty, the default CTA label will be used",
      type: "multilingualString",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "secondaryCTA",
      title: "Secondary CTA",
      type: "pageCTA",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "tertiaryCTA",
      title: "Tertiary CTA",
      type: "pageCTA",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "termsAndConditionsFootnote",
      title: "T&C Footnote",
      type: "array",
      of: [{ type: filterableMultilingualBlockContent.name }]
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "featureTitle",
      title: "Title",
      type: "array",
      of: [
        {
          type: filterableMultilingualString.name
        }
      ],
      fieldset: "features"
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "features",
      type: "array",
      of: [{ type: exclusiveOfferFeature.name }],
      fieldset: "features"
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "sections",
      type: "array",
      title: "Page sections",
      description: "Add, edit, and reorder sections",
      of: allPageBuilderSections(),
      options: {
        modal: "fullscreen"
      }
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "hideInHomepage",
      title: "Hide in Homepage",
      type: "boolean",
      fieldset: "visibility"
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "hideInCollections",
      title: "Hide in Collections (page builder offers mosaic/slider)",
      type: "boolean",
      fieldset: "visibility"
    },

    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "cardWithIcon",
      title: "Render as Slider Card with Icon",
      description:
        "Control how this page will display in sliders without images",
      type: "array",
      of: [
        {
          type: filterableAsCardWithIcon.name,
          title: "Blue Box"
        }
      ]
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "farePageCard",
      type: asCard.name
    },
    {
      deprecated: {
        reason: "not used anymore"
      },
      name: "card",
      type: asCardAirCondition.name
    },

    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: metadataWithoutParent.name,
      group: "seo"
    }
  ]
});
