import { ValidationContext } from "sanity";
import { SUPPORTED_LANGUAGES } from "./localization";

const contentIsPresent = (value: string | undefined): value is string =>
  !!value && value.trim() != "";

const someLanguageDefined = (context: ValidationContext) =>
  SUPPORTED_LANGUAGES.some((lang) =>
    contentIsPresent((context.parent as any)[lang.id])
  );

export const someLanguageShouldBeProvided = (
  item: unknown,
  context: ValidationContext
) => {
  const atLeastOneLang = someLanguageDefined(context);
  return atLeastOneLang || "At least one language should be provided";
};
