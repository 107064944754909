import { defineType } from "sanity";

export default defineType({
  name: "keyFeatures",
  title: "Key Features",
  type: "object",
  fields: [
    {
      name: "veranda",
      title: "Veranda",
      type: "boolean"
    },
    {
      name: "livingRoom",
      title: "Living Room",
      type: "boolean"
    }
  ]
});
