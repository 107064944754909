import { FaGlobe } from "react-icons/fa";
import { defineType } from "sanity";
import { currencies } from "../../common/currencies";

export default defineType({
  name: "country",
  title: "Country",
  type: "document",
  icon: FaGlobe,
  orderings: [
    {
      title: "Name",
      name: "nameAsc",
      by: [{ field: "name.en", direction: "asc" }]
    }
  ],
  preview: {
    select: {
      title: "name.en",
      isoAlpha2Code: "isoAlpha2Code",
      isoAlpha3Code: "isoAlpha3Code",
      defaultCurrency: "defaultCurrency",
      phoneNumber: "phoneNumber",
      unit: "unit",
      defaultGateway: "defaultGateway",
      defaultGatewayZone: "defaultGatewayZone",
      market: "market",
      region: "region"
    },
    prepare: ({ title, ...otherProps }) => {
      const missingKeys = Object.keys(
        Object.fromEntries(
          Object.entries(otherProps).filter(([key, value]) => {
            if (!value || value.trim() == "") return true;
            return false;
          })
        )
      );

      return {
        title,
        subtitle:
          missingKeys.length > 0
            ? `missing: ${missingKeys.join(", ")}`
            : undefined
      };
    }
  },
  fields: [
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "isoAlpha2Code",
      title: "ISO Code (Alpha-2)",
      type: "string",
      readOnly: true
    },
    {
      name: "isoAlpha3Code",
      title: "ISO Code (Alpha-3)",
      type: "string",
      readOnly: true
    },
    {
      name: "defaultCurrency",
      title: "Default currency",
      description:
        "warning: when changed, be sure to run an importer transformation",
      type: "string",
      options: {
        list: currencies
      }
    },
    {
      name: "phoneNumber",
      description: "warning: when changed, please wait 30mins due to API cache",
      title: "Phone Number",
      type: "string"
    },
    {
      name: "unit",
      description: "warning: when changed, please wait 30mins due to API cache",
      title: "Unit",
      type: "string",
      initialValue: "meter",
      options: {
        list: ["meter", "feet"]
      }
    },
    {
      name: "defaultGateway",
      title: "Default Gateway (Airport)",
      description:
        "warning: when changed, be sure to run an importer transformation",
      type: "string"
    },
    {
      name: "defaultGatewayZone",
      title: "Default Gateway Zone (Airport zone)",
      type: "string",
      readOnly: true
    },
    {
      name: "market",
      title: "Market",
      type: "string",
      readOnly: true
    },
    {
      name: "region",
      title: "Region",
      type: "string",
      readOnly: true
    }
  ]
});
