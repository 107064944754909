import { Box, Card, Checkbox, Flex, Text } from "@sanity/ui";
import React, { useCallback, useId } from "react";

export type SchemaFieldset = {
  _key: string;
  title?: string;
  fieldNames?: string[];
};

type Props = {
  sections: SchemaFieldset[];
  selectedSections: SchemaFieldset[];
  onSectionToggle: (section: SchemaFieldset, selected: boolean) => void;
};

const SelectableList: React.FC<Props> = ({
  onSectionToggle,
  sections,
  selectedSections
}) => {
  const id = useId();

  const onChangeLocal = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      const key = e.target.value;
      const selectedSection = sections.find((s) => s._key == key);
      if (selectedSection) onSectionToggle(selectedSection, checked);
    },
    [onSectionToggle, sections]
  );

  return (
    <Card padding={2}>
      <Flex gap={2} direction="column">
        {sections.map((s) => {
          const isChecked =
            selectedSections.findIndex((x) => x._key == s._key) != -1;
          return (
            <Card padding={2} radius={2} border key={s.title}>
              <Flex align="center">
                <Checkbox
                  id={`${id}-${s.title}`}
                  style={{ display: "block" }}
                  value={s._key}
                  onChange={onChangeLocal}
                  checked={isChecked}
                />
                <Box flex={1} paddingLeft={3}>
                  <Text>
                    <label htmlFor={`${id}-${s.title}`}>{s.title}</label>
                  </Text>
                </Box>
              </Flex>
            </Card>
          );
        })}
      </Flex>
    </Card>
  );
};

export default SelectableList;
