import { defineType } from "sanity";

export default defineType({
  name: "hero",
  title: "Hero",
  options: {
    collapsible: true,
    collapsed: true
  },
  type: "object",
  fields: [
    {
      name: "variant",
      title: "Variant",
      type: "string",
      options: {
        list: [
          { title: "Default", value: "" },
          { title: "Boxed right (for LPs)", value: "boxed" }
        ]
      }
    },
    {
      name: "background",
      title: "Background",
      type: "imageWithAlt",
      validation: (Rule) => Rule.required()
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualText"
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "calligraphicTitle",
      title: "Calligraphic Title",
      description: "use instead of `title`, but use only if forced!",
      type: "image",
      options: {
        hotspot: true,
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "calligraphicTitlePosition",
      title: "Calligraphic Title Position",
      type: "string",
      options: {
        list: [
          { title: "Left", value: "left" },
          { title: "Center", value: "center" },
          { title: "Right", value: "right" }
        ]
      }
    }
  ],
  preview: {
    select: {
      title: "title",
      background: "background"
    },
    prepare(selection) {
      const { title, background } = selection;
      return {
        title: title?.en || "Untitled Hero",
        media: background
      };
    }
  }
});
