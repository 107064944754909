import { BiArea } from "react-icons/bi";
import { defineType } from "sanity";

export default defineType({
  name: "publicAreaCategoryTemplate",
  title: "Public Area General Page",
  type: "document",
  icon: BiArea,
  fields: [
    {
      name: "isAvailableOnHeading",
      title: "Is Available On Heading",
      type: "sectionTitle"
    },
    {
      name: "otherRestaurantsHeading",
      title: "Other Restaurants Heading",
      type: "sectionTitle"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Public Area Category Template"
      };
    }
  }
});
