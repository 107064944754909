import { defineType } from "sanity";
import {
  cruiseGroups,
  getLabelForCruiseGroupCode
} from "../data-types/cruiseGroups";
import inclusionSpecificContent from "./inclusionSpecificContent";

export default defineType({
  name: "inclusionOverrideContent",
  title: "Inclusion Override Content",
  type: "object",
  preview: {
    select: {
      cruiseGroupFilters: "cruiseGroupFilters"
    },
    prepare: ({ cruiseGroupFilters }) => ({
      title: cruiseGroupFilters.map(getLabelForCruiseGroupCode).join(", ")
    })
  },
  fieldsets: [
    {
      name: "group content",
      title: "Group content",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "cruise content",
      title: "Cruise content",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  fields: [
    {
      name: "groupSpecificContent",
      title: "Group Content",
      type: inclusionSpecificContent.name,
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "cruiseSpecificContent",
      title: "Cruise Content",
      type: inclusionSpecificContent.name,
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "cruiseGroupFilters",
      title: "Cruise Group Filters",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: cruiseGroups
      }
    }
  ]
});
