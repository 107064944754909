import { MdOutlineCallSplit } from "react-icons/md";
import { defineField, defineType } from "sanity";
import {
  orderRankField,
  orderRankOrdering
} from "@sanity/orderable-document-list";
import { multilingualStringPreview } from "../base/multilingualString";

export default defineType({
  name: "redirect",
  title: "Redirect",
  type: "document",
  icon: MdOutlineCallSplit,
  initialValue: {
    strategy: "page"
  },
  preview: {
    select: {
      source: "source",
      strategy: "strategy",
      pageTarget: "pageTarget.metadata",
      urlTarget: "urlTarget"
    },
    prepare: ({ source, strategy, pageTarget, urlTarget }) => {
      const subtitle =
        strategy === "page"
          ? multilingualStringPreview(pageTarget?.routing.path)
          : multilingualStringPreview(urlTarget);
      return {
        title: multilingualStringPreview(source),
        subtitle: `${strategy}: ${subtitle}`
      };
    }
  },
  orderings: [orderRankOrdering],
  fields: [
    orderRankField({ type: "redirect" }),
    defineField({
      name: "source",
      title: "Source",
      type: "multilingualString",
      validation: false
    }),
    defineField({
      name: "strategy",
      type: "string",
      options: {
        list: ["page", "url"]
      },
      validation: (Rule) => Rule.required()
    }),
    defineField({
      name: "pageTarget",
      title: "Target",
      type: "reference",
      to: [{ type: "page" }, { type: "landingPage" }],
      hidden: ({ document }) => document?.strategy !== "page"
    }),
    defineField({
      name: "urlTarget",
      title: "Target",
      type: "multilingualString",
      hidden: ({ document }) => document?.strategy !== "url"
    })
  ]
});
