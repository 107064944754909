import { defineField, defineType } from "sanity";
import { fareCodes } from "../data-types/faresTokens";
import { markets } from "../../common/countries";
import { currencies } from "../../common/currencies";

export default defineType({
  name: "manualPromoValidity",
  type: "object",
  title: "Manual Promo Validity",
  fields: [
    defineField({
      name: "cruiseCodes",
      title: "Cruise Codes",
      description: "list of cruise codes (one per row) without any comma",
      type: "text"
    }),
    defineField({
      name: "validFrom",
      title: "Valid From Date",
      type: "date"
    }),
    defineField({
      name: "validTo",
      title: "Valid To Date",
      type: "date"
    }),
    defineField({
      name: "markets",
      title: "Markets",
      type: "array",

      of: [
        {
          type: "string"
        }
      ],
      options: {
        list: markets
      }
    }),
    defineField({
      name: "currencies",
      title: "Currencies",
      type: "array",
      of: [
        {
          type: "string"
        }
      ],
      options: {
        list: currencies
      }
    }),
    defineField({
      name: "fareCodes",
      title: "Fares",
      type: "array",
      of: [
        {
          type: "string"
        }
      ],
      options: {
        list: fareCodes
      }
    })
  ]
});
