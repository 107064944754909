import { defineType } from "sanity";

export default defineType({
  name: "slide",
  title: "Slide",
  type: "object",
  preview: {
    select: {
      media: "imageWithCaption",
      title: "imageWithCaption.caption.en"
    }
  },
  fields: [
    {
      name: "imageWithCaption",
      title: "Image",
      type: "imageWithCaption"
    }
  ]
});
