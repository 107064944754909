import { FaBook } from "react-icons/fa";
import { defineType } from "sanity";
import brochureContentGroup from "./brochureContentGroup";
import { multilingualStringPreview } from "../base/multilingualArrayString";

export default defineType({
  name: "brochure",
  title: "Brochure",
  type: "document",
  icon: FaBook,
  orderings: [
    {
      title: "Name",
      name: "nameAsc",
      by: [{ field: "title", direction: "asc" }]
    }
  ],
  preview: {
    select: {
      title: "title",
      language: "language",
      media: "thumbnail",
      contentGroup: "brochureContentGroup.title"
    },
    prepare(value) {
      return {
        title: `${value.title}`,
        subtitle: `${value.language} - ${multilingualStringPreview(
          value.contentGroup
        )}`,
        media: value.media
      };
    }
  },
  fields: [
    {
      name: "brochureContentGroup",
      title: "Brochure Content Group",
      type: "reference",
      to: [{ type: brochureContentGroup.name }]
    },
    {
      name: "title",
      title: "Title",
      type: "string"
    },
    {
      name: "brochureCode",
      readOnly: true,

      title: "Brochure Code",
      description: "Sent to SMA for tracking/integration",
      type: "string"
    },
    {
      name: "description",
      title: "Description",
      type: "blockContent"
    },
    {
      name: "language",
      readOnly: true,

      title: "Language",
      type: "string",
      options: {
        list: [
          { title: "English", value: "en" },
          { title: "Spanish", value: "es" },
          { title: "Portuguese", value: "pt-br" },
          { title: "French", value: "fr" },
          { title: "German", value: "de" },
          { title: "Italian", value: "it" },
          { title: "Russian", value: "ru" }
        ]
      }
    },
    {
      name: "issuu",
      title: "Issuu Url",
      readOnly: true,

      type: "string"
    },
    {
      name: "isDigitalOnly",
      readOnly: true,

      title: "Digital Only",
      type: "boolean"
    },
    {
      name: "isPreOrderOnly",
      readOnly: false,

      title: "Pre-Order",
      description:
        "Shows the pre-order button instead of 'Mail me this Brochure'",
      type: "boolean"
    },
    {
      name: "type",
      title: "Type",
      type: "string"
    },
    {
      name: "isActive",
      title: "Active",
      description:
        'Will be shown on default "Brochures" page as well as any groups.',
      type: "boolean"
    },
    {
      name: "validFrom",
      title: "Valid From",
      type: "date"
    },
    {
      name: "validTo",
      title: "Valid To",
      type: "date"
    },
    {
      name: "legacyThumbnailUrl",
      title: "AEM thumbnail URL",
      type: "string"
    },
    {
      name: "thumbnail",
      title: "Brochure Thumbnail",
      type: "imageWithAlt"
    },
    {
      name: "order",
      title: "Order",
      description:
        "in ascending order (1: first in the list, 100000: last in the list)",
      type: "number",
      validation: (Rule) => Rule.max(100000).min(0)
    },
    {
      name: "countries",
      title: "Countries",
      readOnly: true,
      type: "array",
      of: [{ type: "string" }]
    }
  ]
});
