import { BiBookOpen } from "react-icons/bi";
import { defineType } from "sanity";

export default defineType({
  name: "requestBrochure",
  title: "Request Brochure Page",
  icon: BiBookOpen,
  type: "document",
  groups: [
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "heading",
      title: "Heading",
      type: "openingText"
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Request Brochure Page"
      };
    }
  }
});
