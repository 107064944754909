import { inclusionCodes } from "../data-types/inclusionsTokens";
import { cruiseGroups } from "../data-types/cruiseGroups";
import { defineField, defineType } from "sanity";

export default defineType({
  name: "infographicPerCruiseGroup",
  type: "document",
  title: "Infographic",
  fields: [
    defineField({
      name: "name",
      type: "string"
    }),
    defineField({
      name: "cruiseGroup",
      title: "Cruise Group",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: cruiseGroups
      }
    }),
    {
      name: "doorToDoorActiveInclusions",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: inclusionCodes
      }
    },
    {
      name: "portToPortActiveInclusions",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: inclusionCodes
      }
    },
    {
      name: "essentialActiveInclusions",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: inclusionCodes
      }
    }
  ],
  preview: {
    select: {
      title: "name",
      cruiseGroup: "cruiseGroup"
    },
    prepare({ title, cruiseGroup }) {
      return {
        title,
        subtitle: cruiseGroup
      };
    }
  }
  // orderings: [
  //   {
  //     title: "Title",
  //     name: "orderAsc",
  //     by: [{ field: "genericContent.infographicTitle.en", direction: "asc" }]
  //   }
  // ]
});
