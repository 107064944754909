import { BsSegmentedNav } from "react-icons/bs";
import { defineType } from "sanity";

export default defineType({
  title: "1. [Opening] Sticky Nav",
  name: "stickyNav",
  type: "object",
  icon: BsSegmentedNav,
  fields: [
    {
      name: "links",
      title: "Links",
      type: "array",
      of: [{ type: "sectionAnchorLink" }]
    }
  ],
  preview: {
    select: {
      items: "links"
    },
    prepare({ items }) {
      return {
        title: "[Opening] Sticky Nav",
        subtitle: `${items.length} items`
      };
    }
  }
});
