import { MdRestaurant } from "react-icons/md";
import { defineType } from "sanity";

export default defineType({
  name: "diningAreaCategoryTemplate",
  title: "Dining Area General Page",
  type: "document",
  icon: MdRestaurant,
  fields: [
    {
      name: "isAvailableOnHeading",
      title: "Is Available On Heading",
      type: "sectionTitle"
    },
    {
      name: "otherRestaurantsHeading",
      title: "Other Restaurants Heading",
      type: "sectionTitle"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Dining Area Category Template"
      };
    }
  }
});
