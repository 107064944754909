import { GiPathDistance } from "react-icons/gi";
import { defineType } from "sanity";

export default defineType({
  name: "specialVoyageData",
  title: "Special Voyage Data",
  type: "document",
  orderings: [
    {
      title: "Cruise Code",
      name: "cruiseCode",
      by: [{ field: "cruiseCode", direction: "asc" }]
    }
  ],
  icon: GiPathDistance,
  preview: {
    select: { title: "cruiseCode" }
  },
  fields: [
    {
      name: "cruiseCode",
      title: "Cruise Code",
      type: "string",
      readOnly: true
    },
    {
      name: "dataOrigin",
      title: "Data Origin",
      type: "string",
      readOnly: true
    },
    {
      name: "isAvailable",
      title: "Available?",
      description: "True if has available suites to book",
      type: "boolean",
      readOnly: true
    },
    {
      name: "comboType",
      title: "Combo Type",
      type: "string",
      options: {
        list: ["World", "Grand"]
      },
      readOnly: true
    },
    {
      name: "cruiseType",
      title: "Cruise Type",
      description: "Expedition or Classic",
      type: "string",
      options: {
        list: ["Classic", "Expedition"]
      },
      readOnly: true
    },
    {
      name: "loadStress",
      title: "Load Stress",
      type: "string",
      readOnly: true
    },
    {
      name: "ship",
      title: "Ship",
      type: "reference",
      to: [{ type: "ship" }],
      readOnly: true,
      weak: true
    },

    {
      name: "departureYearMonth",
      title: "Departure Month",
      type: "string",
      readOnly: true
    },
    {
      name: "departureDate",
      title: "Departure Date",
      type: "string",
      readOnly: true
    },
    {
      name: "departurePort",
      title: "Departure Port",
      type: "reference",
      to: [{ type: "port" }],
      readOnly: true,
      weak: true
    },
    {
      name: "arrivalDate",
      title: "Arrival Date",
      type: "string",
      readOnly: true
    },
    {
      name: "arrivalPort",
      title: "Arrival Port",
      type: "reference",
      to: [{ type: "port" }],
      readOnly: true,
      weak: true
    },
    {
      name: "days",
      title: "Duration (days)",
      type: "number",
      readOnly: true
    },
    {
      name: "uniquePortsCount",
      title: "Number of Ports Visited (NOT IN USE)",
      type: "number",
      readOnly: true
    },
    {
      name: "uniqueCountriesCount",
      title: "Number of Countries Visited (NOT IN USE)",
      type: "number",
      readOnly: true
    },
    {
      name: "cruises",
      title: "Cruises",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "cruise" }],
          weak: true
        }
      ],
      readOnly: true
    },
    {
      name: "promotions",
      title: "Promotions",
      type: "array",
      of: [{ type: "string" }],
      readOnly: true
    },
    {
      name: "specialVoyageType",
      title: "Special Voyage Type (deprecated)",
      deprecated: {
        reason: "not used anymore"
      },
      type: "string",
      options: {
        list: [
          { title: "World Cruise", value: "WorldCruise" },
          { title: "Grand Voyage", value: "GrandVoyage" }
        ]
      },
      readOnly: true
    },
    {
      name: "preHotelLand",
      title: "Pre Hotel/Land",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "hotel" }, { type: "landProgramme" }],
          weak: true
        }
      ],
      readOnly: true
    },
    {
      name: "postHotelLand",
      title: "Post Hotel/Land",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "hotel" }, { type: "landProgramme" }],
          weak: true
        }
      ],
      readOnly: true
    },
    {
      name: "fareCodes",
      title: "Fare Codes",
      type: "array",
      of: [
        {
          type: "string"
        }
      ],
      readOnly: true
    },
    {
      name: "cruiseGroup",
      title: "Cruise Group",
      type: "string",
      readOnly: true
    }
  ]
});
