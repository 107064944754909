import { FaGift } from "react-icons/fa";
import { linkablePageTypes } from "../../common/allLinkablePage";
import { airNonAirFilter, locationFilter } from "../base";
import { multilingualBlockContentPreview } from "../base/multilingualArrayBlockContent";
import { packageInfographicCruiseCard } from "../data-types";
import {
  cruiseGroups,
  getLabelForCruiseGroupCode
} from "../data-types/cruiseGroups";
import { defineType } from "sanity";

export default defineType({
  name: "package",
  type: "document",
  icon: FaGift,
  title: "Package",
  fieldsets: [
    {
      name: "visibility",
      title: "Visibility",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "cruise-card-infographic-content",
      title: "Cruise card infographic content",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  fields: [
    {
      fieldset: "visibility",
      name: "cruiseGroup",
      title: "Cruise Group",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: cruiseGroups
      }
    },
    {
      fieldset: "visibility",
      name: "years",
      title: "Years",
      description:
        "cruises departing in these years will be included (unless explicity excluded below)",
      type: "array",
      of: [
        {
          type: "string",
          validation: (Rule) => Rule.required(),
          options: {
            list: ["2021", "2022", "2023", "2024"]
          }
        }
      ]
    },
    {
      fieldset: "visibility",
      name: "includedCruiseCodes",
      title: "Included Cruise Codes",
      description: "list of cruise codes (one per row) without any comma",
      type: "text"
    },
    {
      fieldset: "visibility",
      name: "excludedCruiseCodes",
      title: "Excluded Cruise Codes",
      description: "list of cruise codes (one per row) without any comma",
      type: "text"
    },
    {
      fieldset: "visibility",
      name: "locationFilters",
      title: "Location Filters",
      type: "array",
      of: [
        {
          type: locationFilter.name
        },
        {
          type: airNonAirFilter.name
        }
      ]
    },
    {
      fieldset: "cruise-card-infographic-content",
      name: "cruiseInfographicCard",
      title: "Card title",
      type: packageInfographicCruiseCard.name
    },
    {
      name: "page",
      type: "reference",
      to: linkablePageTypes
    }
  ],
  preview: {
    select: {
      title: "cruiseInfographicCard.title",
      cruiseGroup: "cruiseGroup"
    },
    prepare: ({ title, cruiseGroup }) => ({
      title: multilingualBlockContentPreview(100)(title),
      subtitle: cruiseGroup.map(getLabelForCruiseGroupCode).join(", ")
    })
  }
});
