import { GiPathDistance } from "react-icons/gi";
import { defineType } from "sanity";

export default defineType({
  name: "cruiseData",
  title: "Cruise Data",
  type: "document",
  orderings: [
    {
      title: "Cruise Code",
      name: "cruiseCode",
      by: [{ field: "cruiseCode", direction: "asc" }]
    }
  ],
  icon: GiPathDistance,
  preview: {
    select: { title: "cruiseCode" }
  },
  fields: [
    {
      name: "cruiseCode",
      title: "Cruise Code",
      type: "string",
      readOnly: true
    },
    {
      name: "dataOrigin",
      title: "Data Origin",
      type: "string",
      readOnly: true
    },
    {
      name: "isVisible",
      title: "Visible?",
      description: "True if visible to the public website",
      type: "boolean",
      readOnly: true
    },
    {
      name: "isAvailable",
      title: "Available?",
      description: "True if has available suites to book",
      type: "boolean",
      readOnly: true
    },
    {
      name: "destination",
      type: "reference",
      to: [{ type: "destination" }],
      readOnly: true
    },
    {
      name: "comboType",
      title: "Combo Type",
      type: "string",
      options: {
        list: ["Single", "Combo"]
      },
      readOnly: true
    },
    {
      name: "cruiseType",
      title: "Cruise Type",
      description: "Expedition or Classic",
      type: "string",
      options: {
        list: ["Classic", "Expedition"]
      },
      readOnly: true
    },
    {
      name: "loadStress",
      title: "Load Stress",
      type: "string",
      readOnly: true
    },
    {
      name: "ship",
      title: "Ship",
      type: "reference",
      to: [{ type: "ship" }],
      readOnly: true
    },
    {
      name: "departureYearMonth",
      title: "Departure Month",
      type: "string",
      readOnly: true
    },
    {
      name: "departureDate",
      title: "Departure Date",
      type: "string",
      readOnly: true
    },
    {
      name: "departurePort",
      title: "Departure Port",
      type: "reference",
      to: [{ type: "port" }],
      readOnly: true,
      weak: true
    },
    {
      name: "arrivalDate",
      title: "Arrival Date",
      type: "string",
      readOnly: true
    },
    {
      name: "arrivalPort",
      title: "Arrival Port",
      type: "reference",
      to: [{ type: "port" }],
      readOnly: true,
      weak: true
    },
    {
      name: "days",
      title: "Duration (days)",
      type: "number",
      readOnly: true
    },
    {
      name: "itinerary",
      title: "Itinerary",
      type: "array",
      of: [{ type: "itineraryItem" }],
      readOnly: true
    },
    {
      name: "promotions",
      title: "Promotions",
      type: "array",
      of: [{ type: "string" }],
      readOnly: true
    },
    {
      name: "map",
      title: "Map Image (map from ECM)",
      type: "image",
      readOnly: true
    },
    {
      name: "map3",
      title: "Map Image (map3 from ECM)",
      type: "image",
      readOnly: true
    },
    {
      name: "map4",
      title: "Map Image (map4 from ECM)",
      type: "image",
      readOnly: true
    },
    {
      name: "preHotelLand",
      title: "Pre Hotel/Land",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "hotel" }, { type: "landProgramme" }],
          weak: true
        }
      ],
      readOnly: true
    },
    {
      name: "postHotelLand",
      title: "Post Hotel/Land",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "hotel" }, { type: "landProgramme" }],
          weak: true
        }
      ],
      readOnly: true
    },
    {
      name: "fareCodes",
      title: "Fare Codes",
      type: "array",
      of: [
        {
          type: "string"
        }
      ],
      readOnly: true
    },
    {
      name: "cruiseGroup",
      title: "Cruise Group",
      type: "string",
      readOnly: true
    }
  ]
});
