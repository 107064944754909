import { DashboardWidget, LayoutConfig } from "@sanity/dashboard";
import { CruiseCodeSearch } from "./CruiseCodeSearch";

export function sscCruiseCodeSearchWidget(
  config: { layout?: LayoutConfig; silverseaApiRootUrl?: string } = {}
): DashboardWidget {
  return {
    name: "ssc-cruise-code-search",
    component: (props) => (
      <CruiseCodeSearch
        {...props}
        silverseaApiRootUrl={config.silverseaApiRootUrl}
      />
    ),
    layout: config.layout ?? { width: "medium" }
  };
}
