import { defineField } from "sanity";
import { SUPPORTED_LANGUAGES } from "../../common/localization";

export default defineField({
  name: "multilingualUrl",
  title: "Multi-lingual URL",
  type: "object",
  fields: SUPPORTED_LANGUAGES.map((lang) =>
    defineField({
      title: lang.title,
      name: lang.id,
      type: "url",
      validation: (Rule) =>
        Rule.uri({
          allowRelative: true,
          scheme: ["http", "https", "mailto"]
        })
    })
  )
});
