import {
  CRUISE_GROUP_CODES,
  CRUISE_GROUP_DESCRIPTIONS,
  CruiseGroupCode
} from "@silversea-ssc/web-cruise-groups";

export const cruiseGroups = CRUISE_GROUP_CODES.map((key) => ({
  title: CRUISE_GROUP_DESCRIPTIONS[key],
  value: key
}));

export const getLabelForCruiseGroupCode = (code: CruiseGroupCode) =>
  CRUISE_GROUP_DESCRIPTIONS[code];
