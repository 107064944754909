import { FaMapMarked } from "react-icons/fa";
import { StructureBuilder } from "sanity/structure";

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Destination")
    .icon(FaMapMarked)
    .child(
      S.list()
        .title("Destinations")
        .items([
          S.listItem()
            .title("Destination")
            .schemaType("destination")
            .child(S.documentTypeList("destination").title("Destination")),
          S.listItem()
            .title("Sub Destination")
            .schemaType("subDestination")
            .child(
              S.documentTypeList("subDestination").title("Sub Destination")
            ),
          S.listItem()
            .title("Highlighted Cruise Group")
            .schemaType("highlightedCruiseGroup")
            .child(
              S.documentTypeList("highlightedCruiseGroup").title(
                "Highlighted Cruise Group"
              )
            )
        ])
    );
