import { defineType } from "sanity";

export default defineType({
  name: "video",
  title: "Video",
  type: "object",
  fields: [
    {
      name: "file",
      title: "Video File",
      type: "widen.video"
    },
    {
      name: "trackingTitle",
      title: "Tracking Title",
      description: "(in English, for tracking only)",
      type: "string"
    }
  ]
});
