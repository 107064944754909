import { MdAirlineSeatIndividualSuite } from "react-icons/md";
import { metadataWithoutParent } from "../page-fragments";
import { defineType } from "sanity";

export default defineType({
  name: "suiteCategory",
  title: "Suite Category",
  type: "document",
  orderings: [
    {
      title: "Name",
      name: "nameAsc",
      by: [{ field: "name.en", direction: "asc" }]
    },
    {
      title: "Custom ordering",
      name: "orderAsc",
      by: [{ field: "order", direction: "asc" }]
    }
  ],
  icon: MdAirlineSeatIndividualSuite,
  preview: {
    select: {
      title: "name.en",
      subtitle: "order",
      images: "images"
    },
    prepare: ({ title, subtitle, images }) => ({title, subtitle, media: images[0]})
  },
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "isVisible",
      title: "Visible?",
      description: "True if visible to the public website",
      type: "boolean"
    },
    {
      name: "description",
      title: "Description",
      type: "openingText"
    },
    {
      name: "images",
      title: "Images",
      type: "array",
      of: [{ type: "imageWithCaption" }]
    },
    {
      name: "order",
      title: "Order",
      description:
        "in ascending order (1: first in the list, 100: last in the list)",
      type: "number"
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: metadataWithoutParent.name,
      group: "seo"
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    }
  ]
});
