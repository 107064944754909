import { defineType } from "sanity";

export default defineType({
  name: "phoneNumberCTA",
  type: "object",
  title: "Phone Number CTA",
  fields: [
    {
      name: "label",
      type: "multilingualString",
      description: "add $phoneNumber where you want the phone number to appear",
      validation: (Rule) => Rule.required()
    },
    {
      name: "overridePhoneNumber",
      type: "string",
      description: "Leave empty for standard country-based phone number"
    },
    {
      name: "type",
      type: "buttonType"
    }
  ],
  preview: {
    select: {
      title: "label.en"
    },
    prepare({ title }) {
      return {
        title: title
      };
    }
  }
});
