import { DashboardWidget, LayoutConfig } from "@sanity/dashboard";
import ShopApiLinks from "./ShopApiLinks";

export function sscShopApiLinksWidget(
  config: { layout?: LayoutConfig } = {}
): DashboardWidget {
  return {
    name: "ssc-shop-api-links",
    component: ShopApiLinks,
    layout: config.layout ?? { width: "medium" }
  };
}
