import { RiTreasureMapFill } from "react-icons/ri";
import { defineField, defineType } from "sanity";
import { cruiseGroups } from "../data-types/cruiseGroups";
import { multilingualStringPreview } from "../base/multilingualArrayString";

export default defineType({
  name: "highlightedCruiseGroup",
  title: "Highlighted Cruise Group",
  type: "document",
  icon: RiTreasureMapFill,
  orderings: [
    {
      title: "Title",
      name: "titleAsc",
      by: [{ field: "title.en", direction: "asc" }]
    }
  ],

  preview: {
    select: {
      title: "title",
      subtitle: "cruiseGroup",
      media: "heroImage"
    },
    prepare(selection) {
      const { title, subtitle, media } = selection;
      return {
        title: multilingualStringPreview(title),
        subtitle,
        media
      };
    }
  },
  fieldsets: [],
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "cruiseGroup",
      title: "Cruise Group",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: cruiseGroups
      }
    },
    defineField({
      name: "heroImage",
      title: "Hero Image",
      type: "imageWithAlt",
      options: {
        hotspot: true
      },
      validation: (Rule) => Rule.required()
    }),
    {
      name: "title",
      title: "Title",
      type: "multilingualArrayString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualArrayBlockContent"
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    },
    {
      name: "infographicsDisclaimer",
      type: "multilingualArrayText",
      title: "Infographics disclaimer",
      description: "common for all infographics"
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    }
  ]
});
