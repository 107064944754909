import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { defineType } from "sanity";

export default defineType({
  name: "topMenu",
  title: "Top Menu",
  icon: BsFillMenuButtonWideFill,
  type: "document",
  fields: [
    {
      name: "items",
      title: "Top Menu",
      type: "array",
      of: [{ type: "topMenuItem" }]
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Top Menu"
      };
    }
  }
});
