import React from "react";
import ValueLabel from "../commons/ValueLabel";
import SectionLabel from "../commons/SectionLabel";
import { CruiseDiagnosticValue } from "@silversea-ssc/dotcom-api-types";

export type CruiseDiagnosticValueHtml = {
  title: string;
  value: string;
  type: "html";
};

export type DiagnosticValue = CruiseDiagnosticValue | CruiseDiagnosticValueHtml;

export const buildCruiseDiagnosticValueHtml = (
  value: string,
  title: string
): CruiseDiagnosticValueHtml => ({
  title,
  value,
  type: "html"
});

type DataBlockProps = {
  title: string;
  rows: readonly DiagnosticValue[] | undefined | null;
};
const DataBlock: React.FC<DataBlockProps> = ({ title, rows = [] }) => {
  if (!rows?.length) return <></>;

  return (
    <React.Fragment>
      <thead></thead>
      <tbody style={{ borderSpacing: "1em" }}>
        <tr>
          <th colSpan={2}>
            <SectionLabel size={4} align="left">
              {title}
            </SectionLabel>
          </th>
        </tr>
        {rows.map((row) => (
          <tr key={row.title}>
            <th>
              <ValueLabel muted>{row.title}</ValueLabel>
            </th>
            <td>
              {row.type === "html" ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: row.value?.toString()
                  }}
                />
              ) : (
                row.value?.toString()
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </React.Fragment>
  );
};

export default DataBlock;
