import { defineType } from "sanity";

export default defineType({
  name: "sectionIntro",
  title: "Section Intro",
  type: "object",
  fields: [
    {
      type: "multilingualString",
      name: "title",
      title: "Title",
      validation: (Rule) => Rule.required()
    },
    {
      type: "multilingualText",
      name: "text",
      title: "Text",
      validation: (Rule) => Rule.required()
    },
    {
      name: "callsToAction",
      title: "Calls to Action",
      type: "array",
      of: [
        {
          type: "callToAction"
        }
      ]
    }
  ]
});
