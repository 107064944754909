import { defineField } from "sanity";
export default defineField({
  type: "object",
  name: "airNonAirFilter",
  title: "Air/NonAir",
  fields: [
    {
      name: "airNonAirFilter",
      type: "string",
      title: "Air/NonAir",
      options: {
        list: [
          {
            title: "Air only (excl. UK & NZ)",
            value: "Air"
          },
          {
            title: "Non-Air (excl. UK & NZ)",
            value: "NonAir"
          },
          {
            title: "UK",
            value: "UK"
          },
          {
            title: "New Zealand",
            value: "NZ"
          }
        ]
      }
    }
  ]
});
