import { FaGlobe } from "react-icons/fa";
import { publishAll } from "../utils/publishAll";
import { StructureBuilder } from "sanity/structure";

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Geo Area")
    .icon(FaGlobe)
    .child(
      S.list()
        .title("Geo Area")
        .items([
          S.listItem()
            .title("Country")
            .schemaType("country")
            .child(
              S.documentTypeList("country").menuItems([
                ...(S.documentTypeList("country").getMenuItems() || []),
                S.menuItem()
                  .title("Publish all")
                  .action(() =>
                    publishAll("country", S.context.getClient, S.context.schema)
                  )
              ])
            ),
          S.listItem()
            .title("Valid countries")
            .schemaType("country")
            .child(
              S.documentTypeList("country").filter(
                `_type == 'country' && (defined(isoAlpha2Code) && defined(isoAlpha3Code) && defined(defaultCurrency)&& defined(phoneNumber)&& defined(unit)&& defined(defaultGateway)&& defined(defaultGatewayZone) && defined(market) && defined(region))`
              )
            ),
          S.listItem()
            .title("Countries with issues")
            .schemaType("country")
            .child(
              S.documentTypeList("country").filter(
                `_type == 'country' && (!defined(isoAlpha2Code) || !defined(isoAlpha3Code) || !defined(defaultCurrency) || !defined(phoneNumber) || !defined(unit) || !defined(defaultGateway) || !defined(defaultGatewayZone)  || !defined(market)  || !defined(region))`
              )
            ),
          S.divider(),
          S.listItem()
            .title("Region")
            .schemaType("region")
            .child(
              S.documentTypeList("region").menuItems([
                ...(S.documentTypeList("region").getMenuItems() || []),
                S.menuItem()
                  .title("Publish all")
                  .action(() =>
                    publishAll("region", S.context.getClient, S.context.schema)
                  )
              ])
            )
        ])
    );
