import { FaShip } from "react-icons/fa";
import { StructureBuilder } from "sanity/structure";

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Ship")
    .icon(FaShip)
    .child(
      S.list()
        .title("Ship")
        .items([
          S.listItem()
            .title("Ships")
            .schemaType("ship")
            .child(S.documentTypeList("ship")),
          S.listItem()
            .title("Suites")
            .schemaType("suiteCategory")
            .child(S.documentTypeList("suiteCategory")),
          S.listItem()
            .title("Public Areas")
            .schemaType("publicAreaCategory")
            .child(S.documentTypeList("publicAreaCategory")),
          S.listItem()
            .title("Dining Areas")
            .schemaType("diningAreaCategory")
            .child(S.documentTypeList("diningAreaCategory"))
        ])
    );
