/* eslint-disable react/prop-types */
import React, { useCallback, useId, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import {
  Badge,
  Box,
  Dialog,
  Grid,
  Spinner,
  Text,
  TextInput,
  Flex,
  Card
} from "@sanity/ui";
import { WidenAsset, WidenSearchResponse } from "./types";
import { QueryFunction, useInfiniteQuery } from "react-query";
import useDebounce from "../../utils/useDebounce";

const styles: Record<string, string> = {};

type Props = {
  handleSelect: (asset: WidenAsset) => void;
  onClose: () => void;
  fileTypes: "image" | "video";
};

type ReactQueryKeys = [string, string];

const WidenAssetSearch: React.FC<Props> = ({
  fileTypes,
  handleSelect,
  onClose
}) => {
  const [query, setQuery] = useState<string>("");
  const debouncedQuery = useDebounce(query, 1000);
  const limit = 10;

  const loadPaginatedResults: QueryFunction<
    WidenSearchResponse,
    ReactQueryKeys
  > = useCallback(
    async ({ pageParam = 0, queryKey }) => {
      const [_key, debouncedQuery] = queryKey;

      const fileTypesQuery = fileTypes == "video" ? "ft:(VIDEO)" : "ft:(IMAGE)"; //fileTypes.map((t) => `fn:(*${t})`).join(" OR ");

      const query = debouncedQuery
        ? `(${fileTypesQuery}) AND ${debouncedQuery}`
        : fileTypesQuery;

      const response = await fetch(
        `https://api.widencollective.com/v2/assets/search?query=${query}&limit=${limit}&offset=${
          pageParam * limit
        }&search_document_text=true&expand=embeds%2Cmetadata%2Cmetadata_info%2Cthumbnails%2Csecurity%2Casset_properties%2Cfile_properties`,
        {
          headers: {
            Authorization: "Bearer silversea/6ec6a8a091d098f61b3f15e570ec6c7c"
          }
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    [fileTypes]
  );

  const getNextPageParam = useCallback((lastPage: WidenSearchResponse) => {
    if (lastPage.offset + limit >= lastPage.total_count) return undefined;

    return lastPage.offset / limit + 1;
  }, []);

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ["widen-assets", debouncedQuery],
    loadPaginatedResults,
    { getNextPageParam }
  );

  const dialogId = useId();

  return (
    <Dialog
      title="Widen Image"
      onClose={onClose}
      open
      id={dialogId}
      header="Search video on Widen"
      width={"auto"}
    >
      <div className={styles.root}>
        <Card padding={4}>
          <TextInput
            iconRight={
              <React.Fragment>
                {isLoading && <Spinner style={{ padding: 0 }} />}
              </React.Fragment>
            }
            fontSize={[2, 2, 3, 4]}
            onChange={(event) => {
              const value = event.currentTarget.value;
              setQuery(value);
            }}
            padding={[3, 3, 4]}
            placeholder="Search Widen"
          />
        </Card>

        <div style={{ height: "700px", overflow: "auto" }}>
          <InfiniteScroll
            key={debouncedQuery}
            pageStart={0}
            loadMore={() => fetchNextPage()}
            hasMore={hasNextPage}
            loader={
              <Box>
                <Flex align="center" justify="center">
                  <Spinner size={4} />
                </Flex>
              </Box>
            }
            useWindow={false}
          >
            {!isLoading && data?.pages.length === 0 && (
              <div>No results found</div>
            )}

            <Grid columns={[4]} gap={2} padding={4}>
              {data?.pages &&
                data.pages.map((group, i) => (
                  <React.Fragment key={i}>
                    {group.items.map((item) => (
                      <Card
                        padding={1}
                        key={item.id}
                        className={styles.imagecard}
                        onClick={() => handleSelect(item)}
                        border
                      >
                        <img
                          className={styles.image}
                          src={item.thumbnails["600px"].url}
                          alt=""
                          style={{
                            width: "100%",
                            maxHeight: "250px",
                            height: "auto",
                            objectFit: "contain",
                            marginBottom: "12px"
                          }}
                        />
                        <Flex direction="column" align="center" gap={2}>
                          <Text
                            size={2}
                            weight="semibold"
                            align="center"
                            style={{ wordBreak: "break-all" }}
                          >
                            {item.filename}
                          </Text>

                          {item.asset_properties.cutline_caption && (
                            <Text size={1}>
                              {item.asset_properties.cutline_caption}
                            </Text>
                          )}
                          {item.security.asset_groups.map((s) => (
                            <Badge mode="outline" fontSize={1} key={s}>
                              {s}
                            </Badge>
                          ))}
                        </Flex>
                      </Card>
                    ))}
                  </React.Fragment>
                ))}
            </Grid>
          </InfiniteScroll>
        </div>
      </div>
    </Dialog>
  );
};

export default WidenAssetSearch;
