import imageWithCaption from "../objects/imageWithCaption";
import { multilingualBlockContent } from "../base";
import { shipSections, keyFeatures, suiteConfiguration } from "../objects";
import HexColorInput from "../../components/HexColorInput";
import comparableFeatures from "../objects/comparableFeatures";
import suiteCategory from "./suiteCategory";
import { defineField, defineType } from "sanity";

export default defineType({
  name: "shipSuite",
  title: "Ship Suite",
  type: "object",
  preview: {
    select: {
      title: "category.name.en",
      imageUrl: "images.0.asset.url"
    }
  },
  fieldsets: [
    {
      name: "deprecated",
      title: "Deprecated (Deprecated)",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "category",
      title: "Category",
      type: "reference",
      to: [{ type: suiteCategory.name }],
      validation: (Rule) => Rule.required()
    },
    defineField({
      name: "color",
      title: "Color (HEX color code)",
      type: "string",
      components: {
        input: HexColorInput
      }
    }),
    {
      name: "description",
      title: "Description",
      type: multilingualBlockContent.name,
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "configurations",
      title: "Configurations",
      description: "e.g. One bedroom, two bedroom, accessible",
      type: "array",
      of: [
        {
          type: suiteConfiguration.name
        }
      ]
    },
    {
      name: "images",
      title: "Images",
      type: "array",
      of: [{ type: imageWithCaption.name }]
    },
    {
      name: "deck",
      title: "Deck(s))",
      type: "array",
      of: [{ type: "number" }]
    },
    {
      name: "shipSections",
      title: "Ship Sections",
      type: shipSections.name
    },
    {
      name: "keyFeatures",
      title: "Key Features",
      type: keyFeatures.name
    },
    {
      name: "comparableFeatures",
      title: "Comparable Features",
      type: comparableFeatures.name,
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    },
    {
      name: "codes",
      title: "Codes",
      description:
        "Must match the code from SMA's Shop API (e.g. SV). Order matters, e.g. If O2 comes before O1 and both prices are available, O2 will be used.",
      type: "array",
      of: [{ type: "string" }],
      validation: (Rule) => Rule.required(),
      fieldset: "deprecated",
      deprecated: {
        reason: "not used anymore"
      }
    }
  ]
});
