import { defineField } from "sanity";
import { currencies, Currency } from "../../common/currencies";
const DEFAULT_CURRENCY = currencies.find((lang) => lang.value === "USD")!;

const NON_DEFAULT_CURRENCIES = currencies.filter(
  (lang) => lang.value !== DEFAULT_CURRENCY.value
);

const firstValidCurrency = (selection: MultiCurrencyAmount) =>
  currencies.reduce<string | undefined>((s, i) => {
    if (s) return s.toString();
    const value = selection[i.value];
    if (value) return `${value} ${i.title}`;
  }, undefined);

export type MultiCurrencyAmount = {
  _type: "multiCurrencyAmount";
} & CurrencyValueType<number>;

type CurrencyValueType<T> = {
  [K in Currency]?: T;
};

export default defineField({
  name: "multiCurrencyAmount",
  title: "Multi-currency Amount",
  type: "object",
  fields: [
    defineField({
      title: DEFAULT_CURRENCY.title,
      name: DEFAULT_CURRENCY.value,
      type: "number",
      validation: (Rule) => Rule.required()
    }),
    ...NON_DEFAULT_CURRENCIES.map((lang) =>
      defineField({
        title: lang.title,
        name: lang.value,
        type: "number",
        validation: (Rule) => Rule.required()
      })
    )
  ]
});

export function multiCurrencyAmountPreview(value: MultiCurrencyAmount) {
  return firstValidCurrency(value);
}
