import { defineType } from "sanity";

export default defineType({
  name: "imageWithCaption",
  type: "image",
  title: "Image",
  options: {
    hotspot: true
  },
  fields: [
    {
      name: "alt",
      type: "multilingualString",
      title: "Alternative text",
      description: "Important for SEO and accessibility.",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "caption",
      type: "multilingualString",
      title: "Caption",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  preview: {
    select: {
      imageUrl: "asset.url",
      title: "caption.en"
    }
  }
});
