import { FaNewspaper, FaRegNewspaper } from "react-icons/fa";
import { publishAll } from "../utils/publishAll";
import { StructureBuilder } from "sanity/structure";

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Press Releases")
    .icon(FaNewspaper)
    .child(
      S.list()
        .title("Press Releases")
        .items([
          S.listItem()
            .title("Press Releases")
            .icon(FaNewspaper)
            .schemaType("pressRelease")
            .child(
              S.documentTypeList("pressRelease").menuItems([
                ...(S.documentTypeList("pressRelease").getMenuItems() || []),
                S.menuItem()
                  .title("Publish all")
                  .action(() =>
                    publishAll(
                      "pressRelease",
                      S.context.getClient,
                      S.context.schema
                    )
                  )
              ])
            ),
          S.divider(),
          S.listItem()
            .title("Press Release Index Page")
            .icon(FaRegNewspaper)
            .child(
              S.editor()
                .id("page-press-releases")
                .schemaType("pressReleaseIndex")
                .documentId("page-press-releases")
            )
        ])
    );
