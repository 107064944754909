import { FaMapPin } from "react-icons/fa";
import allPageBuilderSections from "../../common/allPageBuilderSections";
import { metadataWithoutParent } from "../page-fragments";
import { defineType } from "sanity";

export default defineType({
  name: "subDestination",
  title: "Destination",
  type: "document",
  icon: FaMapPin,
  orderings: [
    {
      title: "Name",
      name: "nameAsc",
      by: [{ field: "name.en", direction: "asc" }]
    }
  ],
  preview: {
    select: {
      title: "name.en",
      subtitle: "destination.name.en",
      media: "heroImage"
    }
  },
  fieldsets: [
    {
      name: "hero",
      title: "Hero",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "description",
      title: "Description",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "why",
      title: "Why with Silversea",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString",
      validation: (Rule) => Rule.required(),
      fieldset: "hero"
    },
    {
      name: "heroImage",
      title: "Image",
      type: "imageWithAlt",
      options: { hotspot: true },
      validation: (Rule) => Rule.required(),
      fieldset: "hero"
    },
    {
      name: "description",
      title: "Description",
      type: "descriptionText",
      fieldset: "description"
    },
    {
      name: "sections",
      type: "array",
      title: "Page sections",
      description: "Add, edit, and reorder sections",
      of: allPageBuilderSections(),
      options: {
        modal: "fullscreen"
      }
    },
    {
      title: "Destination Highlights",
      description: "add ports",
      name: "destinationHighlights",
      type: "array",
      of: [
        {
          type: "mosaicCard"
        }
      ]
    },
    {
      name: "fyc",
      type: "fyc",
      title: "Find Your Cruise",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "whyTitle",
      title: "Title",
      description: "Why in [name] with Silversea",
      type: "multilingualString",
      fieldset: "why"
    },
    {
      name: "why",
      title: "Rows",
      description:
        "usually: Intimate Ships, Luxury Oceanview suites, Personalised service, All-Inclusive cruises, Gurmet cuisine",
      type: "array",
      of: [{ type: "serviceRow" }],
      fieldset: "why"
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: metadataWithoutParent.name,
      group: "seo"
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    },
    {
      name: "destination",
      type: "reference",
      to: [{ type: "destination" }]
    }
  ]
});
