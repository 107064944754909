import { FaHeading } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType(
  {
    title: "1. [Opening] Hero",
    name: "openingHero",
    type: "hero",
    icon: FaHeading
  },
  { aliasFor: "object" }
);
