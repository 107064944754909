import { inclusionIcons } from "./inclusionsTokens";
import { defineType } from "sanity";
import { multilingualStringPreview } from "../base/multilingualArrayString";

//import groq from "groq";

export default defineType({
  name: "expeditionInclusionModalItem",
  type: "object",
  title: "Expedition Inclusion Modal Item",
  fields: [
    {
      name: "icon",
      title: "Icon",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: inclusionIcons
      }
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualArrayString",
      options: { collapsible: false }
    },
    {
      name: "description",
      title: "Description",
      type: "multilingualArrayBlockContent",
      options: { collapsible: false }
    }
  ],
  preview: {
    select: {
      title: "title"
    },
    prepare({ title }) {
      return {
        title: multilingualStringPreview(title)
      };
    }
  }
});
