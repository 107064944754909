import { ValidationContext, defineType } from "sanity";
import multilingualBlockContent from "../base/multilingualBlockContent";
import multilingualString from "../base/multilingualString";

const TYPES = [
  { title: "By Guest Due Covid", value: "by-guest-due-covid" },
  {
    title: "By Guest Due Other Reasons",
    value: "by-guest-due-other-reasons"
  },
  { title: "By Silversea Due Covid", value: "by-silversea-due-covid" },
  {
    title: "By Silversea Due Other Reasons",
    value: "by-silversea-due-other-reasons"
  }
];

const isUniqueType = async (code: string, context: ValidationContext) => {
  const { document, parent } = context as any;

  const currentKey = parent._key;

  const cancelledBy = document.cancelledBy || [];
  const itemsWithSameKey = cancelledBy.find(
    (c: any) => c.type == code && c._key != currentKey
  );
  return !itemsWithSameKey;
};

export default defineType({
  name: "cancellationPolicyModalSectionContent",
  title: "Cancellation Policy Modal Section Content",
  type: "object",
  preview: {
    select: {
      title: "title.en",
      type: "type"
    },
    prepare: ({ title, type }) => ({
      title,
      subtitle: TYPES.find((x) => x.value == type)?.title
    })
  },
  fields: [
    {
      name: "type",
      title: "Type",
      type: "string",
      options: {
        list: TYPES,
        layout: "dropdown"
      },
      validation: (Rule) =>
        Rule
          //.required()
          .custom(async (value: string, context) => {
            const isUnique = await isUniqueType(value, context);
            if (!isUnique) return "There is another content with this type";
            return true;
          })
    },
    {
      name: "title",
      title: "Title",
      type: multilingualString.name
    },
    {
      name: "content",
      title: "Content",
      type: multilingualBlockContent.name
    },
    {
      name: "disclaimer",
      title: "Disclaimer",
      type: multilingualString.name
    },
    {
      name: "staticInfographic",
      title: "Static Infographic",
      type: "boolean"
    },
    {
      name: "staticInfographicRefundableLabel",
      title: "Static Infographic refundable label",
      description: "example: Always 100% refundable",
      type: multilingualString.name,
      hidden: ({ parent }) => !parent.staticInfographic
    },
    {
      name: "staticInfographicFCCNote",
      title: "Static Infographic FCC note",
      description: "example: + extra 10% Future Cruise Credit",
      type: multilingualString.name,
      hidden: ({ parent }) => !parent.staticInfographic
    }
  ]
});
