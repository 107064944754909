import { defineType } from "sanity";
import {
  airNonAirFilter,
  locationFilter,
  filterableMultilingualString,
  multilingualArrayBlockContent
} from "../base";
import { multilingualBlockContentPreview } from "../base/multilingualBlockContent";

export default defineType({
  name: "heroSlidePromoContent",
  type: "object",
  title: "Slide promo content",
  fields: [
    {
      name: "promo",
      title: "Promo",
      type: "reference",
      to: [
        {
          type: "exclusiveOfferPerPromo"
        }
      ],
      validation: (Rule) => Rule.required()
    },
    {
      name: "image",
      type: "imageWithAlt",
      options: {
        hotspot: true,
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualBlockContent"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent"
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "locationFilters",
      title: "Location Filters",
      type: "array",
      of: [
        {
          type: locationFilter.name
        },
        {
          type: airNonAirFilter.name
        }
      ]
    },
    {
      name: "customKey",
      title: "Custom Key",
      description: "Used to identify the slide in the code",
      type: "string"
    }
  ],
  preview: {
    select: {
      imageUrl: "image",
      title: "title"
    },
    prepare({ title, imageUrl }) {
      return {
        //@ts-ignore
        title: multilingualBlockContentPreview(100)(title),
        media: imageUrl
      };
    }
  }
});
