import { defineField } from "sanity";
import {
  SUPPORTED_LANGUAGES,
  DEFAULT_LANGUAGE,
  LocalizedValueType
} from "../../common/localization";

export type MultilingualTextValue = {
  _type: "multilingualText";
} & LocalizedValueType<string>;

export default {
  name: "multilingualText",
  title: "Multi-lingual Text",
  type: "object",
  fields: SUPPORTED_LANGUAGES.map((lang) =>
    defineField({
      title: lang.title,
      name: lang.id,
      type: "text"
    })
  )
};

export function multilingualTextPreview(
  multilingualText: MultilingualTextValue | undefined
) {
  return (multilingualText || {})[DEFAULT_LANGUAGE.id] || "";
}
