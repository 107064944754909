import { DashboardWidget, LayoutConfig } from "@sanity/dashboard";
import SearchPanel from "./SearchPanel";

export function pageBuilderSectionsQueryWidget(
  config: { layout?: LayoutConfig } = {}
): DashboardWidget {
  return {
    name: "page-builder-sections-query",
    component: SearchPanel,
    layout: config.layout ?? { width: "medium" }
  };
}
