import { defineType } from "sanity";

export default defineType({
  name: "blogSection",
  title: "Blog Section",
  type: "object",
  preview: {
    select: {
      title: "intro.title"
    }
  },
  fields: [
    {
      name: "intro",
      title: "Section Intro",
      type: "sectionIntro"
    },
    {
      name: "articles",
      title: "Articles",
      type: "array",
      of: [
        {
          type: "blogArticle"
        }
      ]
    },
    {
      name: "linkText",
      title: "Link Text",
      type: "multilingualString"
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ]
});
