import { defineType } from "sanity";
import { SectionAnchorInput } from "../../../components/SectionAnchorInput";

export default defineType({
  name: "anchorCTA",
  type: "object",
  title: "Anchor CTA",

  fields: [
    {
      name: "label",
      type: "multilingualString"
    },
    {
      type: "string",
      name: "target",
      title: "Target",
      components: {
        input: SectionAnchorInput
      }
    },
    {
      name: "type",
      type: "buttonType"
    }
  ],
  preview: {
    select: {
      title: "label.en"
    },
    prepare({ title }) {
      return {
        title: title || "Anchor CTA"
      };
    }
  }
});
