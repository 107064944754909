import { DashboardWidgetContainer } from "@sanity/dashboard";
import React, { PropsWithChildren } from "react";
import { useDataset } from "sanity";
import { Text } from "@sanity/ui";
import { useSanityStatsApi, SanityStatsCount, formatNumber } from "./utils";

const SanityStats: React.FC = () => {
  const dataset = useDataset();
  const stats = useSanityStatsApi(dataset);

  return (
    <DashboardWidgetContainer header="Sanity Stats">
      {stats.data && stats.isFetched && (
        <table cellSpacing="21px" style={{ textAlign: "left" }}>
          <SanityStatsSectionItem
            title="Documents"
            items={stats.data.documents}
          />
          <SanityStatsSectionItem title="Fields" items={stats.data.fields} />
          <SanityStatsSectionItem title="Types" items={stats.data.types} />
        </table>
      )}
    </DashboardWidgetContainer>
  );
};

type SanityStatsSectionItemProps = {
  title: string;
  items: Record<string, SanityStatsCount>;
};

const SanityStatsSectionItem: React.FC<
  PropsWithChildren<SanityStatsSectionItemProps>
> = ({ title, items }) => {
  return (
    <tbody>
      <tr>
        <th colSpan={2}>
          <Text weight="bold">{title}</Text>
        </th>
      </tr>
      {Object.keys(items).map((v) => {
        const value = (items as any)[v];
        return <SanityStatsCountItem key={v} title={v} {...value} />;
      })}
    </tbody>
  );
};

const SanityStatsCountItem: React.FC<SanityStatsCount & { title: string }> = ({
  title,
  value,
  limit,
  unit
}) => {
  const valueFormatted = formatNumber(value, unit, false);
  const limitFormatted = formatNumber(limit, unit, true);

  return (
    <tr>
      <th>
        <Text muted>{title}</Text>
      </th>
      <td>
        <Text size={1}>
          {valueFormatted} of {limitFormatted}
        </Text>
      </td>
    </tr>
  );
};

export default SanityStats;
