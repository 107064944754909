import { validateDocument } from "@sanity/validation";
import {
  CollatedHit,
  SanityClient,
  SanityDocument,
  Schema,
  SourceClientOptions,
  collate,
  getPublishedId
} from "sanity";

async function publish(
  publishedAndDraft: CollatedHit<SanityDocument>,
  client: SanityClient
) {
  const document = publishedAndDraft.draft;
  if (!document) return;

  const newPublished = {
    ...document,
    _id: getPublishedId(document._id)
  };
  const tx = client.transaction().delete(document._id);

  console.log(newPublished);
  if (publishedAndDraft.published)
    tx.patch(client.patch(newPublished._id).set(newPublished));
  else tx.create(newPublished);
  await tx.commit();
}

async function validPublishedDraftPair(
  documents: SanityDocument[],
  getClient: (clientOptions: SourceClientOptions) => SanityClient,
  schema: Schema
) {
  const modified = collate(documents).filter((x) => x.draft);
  const contentAndValidation = await Promise.all(
    modified.map(
      async (
        x
      ): Promise<
        [
          CollatedHit<SanityDocument>,
          Awaited<ReturnType<typeof validateDocument>>
        ]
      > => {
        //@ts-ignore
        const valid = await validateDocument(getClient, x.draft!, schema);
        return [x, valid];
      }
    )
  );

  return contentAndValidation.filter((x) => x[1].length === 0).map((x) => x[0]);
}

export const publishAll = async (
  type: string,
  getClient: (clientOptions: SourceClientOptions) => SanityClient,
  schema: Schema
) => {
  const client = getClient({ apiVersion: "2021-10-21" });
  const documents = await client.fetch(`*[_type == "${type}"]`);
  const validDocs = await validPublishedDraftPair(documents, getClient, schema);
  for (const x of validDocs) {
    await publish(x, client);
  }
};
