import { defineType } from "sanity";
import { multiCurrencyAmountPreview } from "../../base/multiCurrencyAmount";

export default defineType({
  name: "multiCurrencyTokenValue",
  title: "Multi Currency Token Value",
  type: "object",
  preview: {
    select: {
      name: "name",
      value: "value"
    },
    prepare({ name, value }) {
      return {
        title: name,
        subtitle: multiCurrencyAmountPreview(value) || ""
      };
    }
  },
  fields: [
    {
      name: "name",
      title: "Token name",
      type: "string",
      validation: (Rule) => Rule.required()
    },
    {
      name: "value",
      title: "Value",
      type: "multiCurrencyAmount",
      validation: (Rule) => Rule.required()
    }
  ]
});
