import {
  ConditionFilterValue,
  LocationFilterValue,
  contentFilterPreview
} from "../common/localization";
import airConditions from "../schemas/base/airConditions";
import locationFilter from "../schemas/base/locationFilter";
import airNonAirFilter from "../schemas/base/airNonAirFilter";
import { ToReadableTextFn } from "../sanity-type-extensions";
import { defineField, defineType } from "sanity";

const capitalize = (s: string | undefined) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const filtersAsReadableText = (
  conditionFilters: ConditionFilterValue[],
  locationFilters: LocationFilterValue[]
) => {
  const locationFiltersPreview = contentFilterPreview(locationFilters);
  const airConditionsPreview = airConditions.toReadableText!(conditionFilters);

  return [airConditionsPreview, locationFiltersPreview]
    .filter((x) => x)
    .join(" and ");
};

export const filterableContentOf = (
  contentTypeName: string,
  contentPreview: ToReadableTextFn,
  name: string | undefined = undefined,
  hideConditionFilters = false
) =>
  defineType({
    name: name || `filterable${capitalize(contentTypeName)}`,
    title: "Version",
    type: "object",

    preview: {
      select: {
        conditionFilters: "conditionFilters",
        locationFilters: "locationFilters",
        content: "content"
      },
      prepare({ content, conditionFilters, locationFilters }) {
        return {
          title: contentPreview(content),
          subtitle: filtersAsReadableText(conditionFilters, locationFilters)
        };
      }
    },

    fields: [
      defineField({
        name: "content",
        title: "Content",
        type: contentTypeName,
        options: {
          collapsed: false,
          collapsable: true
        }
      }),
      defineField({
        name: "conditionFilters",
        title: "Condition Filters",
        type: airConditions.name,
        hidden: hideConditionFilters
      }),
      defineField({
        name: "locationFilters",
        title: "Location Filters",
        description:
          "The content above will be shown if the user's country matches any of the following (unless there is more specific content available for that country).",
        type: "array",
        of: [
          {
            type: locationFilter.name
          },
          {
            type: airNonAirFilter.name
          }
        ]
      })
    ],

    //@ts-ignore
    toString: (filterableContent) => contentPreview(filterableContent?.content),

    filtersAsReadableText: (filterableContent: any) =>
      filtersAsReadableText(
        filterableContent?.conditionFilters,
        filterableContent?.locationFilters
      )
  });
