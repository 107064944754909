import { FaWineBottle } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  name: "themedCruise",
  title: "Themed Cruise",
  type: "document",
  icon: FaWineBottle,
  preview: {
    select: {
      name: "name.en",
      code: "code"
    },
    prepare(value) {
      return {
        title: value.code ? `${value.name} (${value.code})` : value.name
      };
    }
  },
  fields: [
    {
      name: "code",
      title: "Code",
      type: "string",
      validation: (Rule) => Rule.required()
    },
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "fycFilterDescription",
      title: "FYC filter description",
      type: "multilingualText",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "cruiseCodes",
      title: "Cruise Codes",
      description: "list of cruise codes (one per row) without any comma",
      type: "text"
    }
  ]
});
