import { AiOutlinePicRight } from "react-icons/ai";
import { multilingualBlockContentPreview } from "../base/multilingualBlockContent";
import { defineType } from "sanity";

export default defineType({
  title: "5. Inline CTA Box",
  name: "inlineCTABox",
  icon: AiOutlinePicRight,
  type: "object",
  fields: [
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  preview: {
    select: {
      title: "content"
    },
    prepare({ title }) {
      return {
        title:
          multilingualBlockContentPreview(100)(title) || "(Inline CTA Box)",
        subtitle: "Inline CTA Box"
      };
    }
  }
});
