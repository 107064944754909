import { useMemo } from "react";
import { useDiagnosticApi } from "../commons/useDiagnosticApi";
import {
  CruiseDiagnosticImporterLogsApiResponse,
  CruiseDiagnosticResponseCodec
} from "@silversea-ssc/dotcom-api-types";

type Args = {
  cruiseCode: string | undefined;
  silverseaApiRootUrl?: string;
};

function stringPresent(content: string | null | undefined): content is string {
  if (content === null || content === undefined) return false;
  return content.trim() != "";
}

export const useDiagnosticData = ({
  cruiseCode,
  silverseaApiRootUrl
}: Args) => {
  const enabled =
    stringPresent(cruiseCode) && stringPresent(silverseaApiRootUrl);

  const importerQuery = useDiagnosticApi({
    queryKey: ["importer", cruiseCode],
    enabled: enabled,
    cruiseCode: cruiseCode!,
    silverseaApiRootUrl: silverseaApiRootUrl!,
    action: "cruiseDiagnostics/importer",
    type: CruiseDiagnosticResponseCodec
  });

  const importerLogsQuery =
    useDiagnosticApi<CruiseDiagnosticImporterLogsApiResponse>({
      queryKey: ["importer-logs", cruiseCode],
      enabled: enabled,
      cruiseCode: cruiseCode!,
      silverseaApiRootUrl: silverseaApiRootUrl!,
      action: "cruiseDiagnostics/importer-logs"
    });

  const shopApiQuery = useDiagnosticApi({
    queryKey: ["shopApi", cruiseCode],
    enabled: enabled,
    cruiseCode: cruiseCode!,
    silverseaApiRootUrl: silverseaApiRootUrl!,
    action: "cruiseDiagnostics/shopApi",
    type: CruiseDiagnosticResponseCodec
  });

  const searchQuery = useDiagnosticApi({
    queryKey: ["search", cruiseCode],
    enabled: enabled,
    cruiseCode: cruiseCode!,
    silverseaApiRootUrl: silverseaApiRootUrl!,
    action: "cruiseDiagnostics/search",
    type: CruiseDiagnosticResponseCodec
  });

  return useMemo(
    () => ({
      importerData: importerQuery,
      importerLogsData: importerLogsQuery,
      shopApiData: shopApiQuery,
      searchData: searchQuery
    }),
    [importerQuery, searchQuery, shopApiQuery, importerLogsQuery]
  );
};
