import { formatThisAndThatList } from "../common/shared";
import { MultilingualStringValue } from "../schemas/base/multilingualString";

export const contentsTitles = (
  contents: { title?: MultilingualStringValue }[]
) => {
  const titles = (contents || [])
    .map((x) => x.title && x.title.en)
    .filter((x): x is string => !!x);

  return titles.length ? formatThisAndThatList(titles) : undefined;
};
