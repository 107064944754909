import { defineType } from "sanity";

export default defineType({
  name: "newLayoutOptions",
  type: "object",
  title: "New Layout Options",
  fields: [
    {
      name: "hideHeaderRAQ",
      title: "Hide header RAQ",
      type: "boolean"
    }
  ]
});
