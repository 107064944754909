import { defineField } from "sanity";
import {
  DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGE_IDS,
  ArrayLocalizedValueType
} from "../../common/localization";
import { stringIsPresent } from "../../utils/checks";

const firstValidTranslation = (selection: MultilingualArrayTextValue) =>
  SUPPORTED_LANGUAGE_IDS.map(
    (l) => selection?.langs?.find((x) => x._key == l)?.value
  ).filter(stringIsPresent)?.[0];

export type MultilingualArrayTextValue = {
  _type: "multilingualArrayText";
} & ArrayLocalizedValueType<"internationalizedArrayTextValue", string>;

export default defineField({
  name: "multilingualArrayText",
  title: "Multi-lingual String",
  type: "object",
  fields: [
    defineField({
      title: " ", // yes, is correct
      name: "langs",
      type: "internationalizedArrayText"
    })
  ],

  toReadableText: (multilingualString: MultilingualArrayTextValue) =>
    firstValidTranslation(multilingualString)
});

export function multilingualTextPreview(
  multilingualString: MultilingualArrayTextValue
) {
  return (multilingualString || {}).langs?.find(
    (x) => x._key == DEFAULT_LANGUAGE.id
  );
}
