import { DashboardWidgetContainer } from "@sanity/dashboard";
import { Card, Flex, Select, Spinner, Stack } from "@sanity/ui";
import allPageBuilderSections from "../../common/allPageBuilderSections";
import { ChangeEvent, useCallback, useDeferredValue, useState } from "react";
import { useSanityComponentSearch } from "./useSanityComponentSearch";
import {
  IntentButton,
  Preview,
  SanityDocument,
  getPublishedId,
  getSchemaTypeTitle,
  useClient,
  useSchema
} from "sanity";
import { isPresent } from "../../utils/checks";

const SearchPanel: React.FC = () => {
  const schema = useSchema();

  const [selectedComponentType, setSelectedComponentType] =
    useState<string>("");
  const deferredSelectedComponentType = useDeferredValue(selectedComponentType);
  const client = useClient({ apiVersion: "2023-06-05" }).withConfig({
    perspective: "previewDrafts"
  });

  const pageBuilderComponents = allPageBuilderSections()
    .map((c) => {
      const type = schema.get(c.type);
      if (!type) return undefined;

      return {
        type: c.type,
        name: getSchemaTypeTitle(type)
      };
    })
    .filter(isPresent);

  const results = useSanityComponentSearch(
    deferredSelectedComponentType,
    client
  );

  const onSelect = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedComponentType(value);
  }, []);

  return (
    <DashboardWidgetContainer header="Page Builder Components">
      <Stack padding={4} space={[3, 3, 4, 5]}>
        <Select onChange={onSelect}>
          <option value=""></option>
          {pageBuilderComponents.map((c) => (
            <option key={c.type} value={c.type}>
              {c.name}
            </option>
          ))}
        </Select>
      </Stack>
      <Stack padding={4} space={[3, 3, 4, 5]}>
        <Card>
          {results.isError && <div>Error</div>}
          {results.isLoading && (
            <Card padding={4}>
              <Flex justify="center">
                <Spinner muted />
              </Flex>
            </Card>
          )}
          {results.isSuccess && results.data.length == 0 && (
            <div>Could not locate any documents</div>
          )}
          <Stack space={2}>
            {results.data &&
              results.data.map((doc) => (
                <DocumentItem key={doc._id} doc={doc} />
              ))}
          </Stack>
        </Card>
      </Stack>
    </DashboardWidgetContainer>
  );
};

export default SearchPanel;

const DocumentItem: React.FC<{ doc: SanityDocument }> = ({ doc }) => {
  const schema = useSchema();
  const type = schema.get(doc._type);
  return (
    <Card flex={1}>
      <IntentButton
        intent="edit"
        mode="bleed"
        //padding={1}
        //radius={0}
        params={{
          type: doc._type,
          id: getPublishedId(doc._id)
        }}
        style={{ width: "100%" }}
        tooltipProps={{ placement: "top" }}
      >
        {type ? (
          <Preview
            layout="default"
            schemaType={type}
            value={doc}
            key={doc._id}
          />
        ) : (
          "Schema-type missing"
        )}
      </IntentButton>
    </Card>
  );
};
