import {
  Autocomplete,
  BaseAutocompleteOption,
  Card,
  Flex,
  Box
} from "@sanity/ui";
import { useId, useState, useDeferredValue, useCallback } from "react";
import { useQuery } from "react-query";
import { useSchema, useClient, SanityDocument, Preview } from "sanity";
import { SanityDocumentWithSections } from "./type";
import { SearchIcon } from "@sanity/icons";

type PageAutocompleteProps = {
  onSelectedDocument: (document: SanityDocumentWithSections) => void;
};
const PageAutocomplete: React.FC<PageAutocompleteProps> = ({
  onSelectedDocument
}: PageAutocompleteProps) => {
  const schema = useSchema();
  const client = useClient({ apiVersion: "2021-10-21" });

  const autocompleteId = useId();
  const [query, setQuery] = useState<string | null>();
  const deferredQuery = useDeferredValue(query);

  const foundDocuments = useQuery({
    queryKey: ["autocompleteQuery", deferredQuery],
    enabled: deferredQuery != undefined && deferredQuery.length > 0,
    queryFn: async () =>
      (
        await client
          .withConfig({ perspective: "previewDrafts" })
          .fetch<SanityDocumentWithSections[]>(
            `*[
            _type in ["page", "landingPage"] && (_id match $st||_type match $st||title match $st||category match $st||title.en match $st||metadata.routing.path.en match $st)
          ]`,
            { st: `*${deferredQuery}*` }
          )
      ).map<SanityDocumentWithSections>((x) => ({
        ...x,
        _id: (x._originalId || x._id) as string,
        _originalId: undefined
      }))
  });

  const onHandleSearch = useCallback((query: string | null) => {
    setQuery(query);
  }, []);

  const onSelect = useCallback(
    (value: string) => {
      const item = foundDocuments.data?.find((x) => x._id == value);
      if (item) onSelectedDocument(item);
    },
    [foundDocuments.data, onSelectedDocument]
  );

  return (
    <Autocomplete
      fontSize={[2, 2, 3]}
      icon={SearchIcon}
      id={autocompleteId}
      options={foundDocuments.data?.map<
        BaseAutocompleteOption & { options: SanityDocument }
      >((x) => ({
        value: `${x._id}`,
        options: x
      }))}
      placeholder="Type to search..."
      value={""}
      onQueryChange={onHandleSearch}
      onSelect={onSelect}
      filterOption={(query, option) => {
        return true;
      }}
      loading={foundDocuments.isLoading}
      renderOption={(item) => {
        return (
          <Card as="button">
            <Flex gap={2} align="center" key={item.options._id}>
              <Box flex={1}>
                <Preview
                  value={item.options}
                  schemaType={schema.get(item.options._type)!}
                />
              </Box>
            </Flex>
          </Card>
        );
      }}
      renderValue={() => ""}
    />
  );
};

export default PageAutocomplete;
