import { defineType } from "sanity";
import { multilingualStringPreview } from "../../base/multilingualString";
import { multilingualStringPreview as multilingualArrayStringPreview } from "../../base/multilingualArrayString";

export default defineType({
  name: "topMenuItem",
  title: "Item",
  type: "object",
  preview: {
    select: {
      title: "title",
      card: "page.card"
    },
    prepare({ title, card }) {
      const pageTitle = card?.title
        ? multilingualStringPreview(card.title)
        : undefined;
      const overrideTitle = multilingualArrayStringPreview(title);

      return {
        title: overrideTitle || pageTitle
      };
    }
  },
  fields: [
    {
      name: "linkType",
      title: "Type",
      type: "string",
      options: {
        list: [
          { title: "Link", value: "link" },
          { title: "Page", value: "page" }
        ]
      },
      validation: (Rule) => Rule.required()
    },
    {
      name: "page",
      title: "Page",
      type: "reference",
      to: [
        { type: "page" },
        { type: "requestAQuote" },
        { type: "requestBrochure" },
        { type: "signupForOffers" }
      ],
      hidden: ({ parent }) => parent?.linkType !== "page"
    },
    {
      name: "link",
      title: "Link",
      type: "multilingualArrayString",
      hidden: ({ parent }) => parent?.linkType !== "link"
    },
    {
      name: "openNewTab",
      title: "Open in new tab",
      type: "boolean",
      hidden: ({ parent }) => parent?.linkType !== "link"
    },
    {
      name: "specialType",
      title: "Special Type",
      type: "string",
      options: {
        list: [
          { title: "RAQ", value: "raq" },
          { title: "RAB", value: "rab" },
          { title: "SFO", value: "sfo" }
        ]
      }
    },
    {
      name: "title",
      title: "Title",
      description: "Override label",
      type: "multilingualArrayString"
    },
    {
      name: "trackingName",
      title: "Tracking Name",
      type: "string"
    }
  ]
});
