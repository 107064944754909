import { defineType } from "sanity";

export default defineType({
  name: "sectionTitle",
  title: "Section Title",
  type: "object",
  fields: [
    {
      name: "tagline",
      title: "Tagline",
      type: "multilingualString",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  preview: {
    select: {
      title: "title.en"
    },
    prepare({ title }) {
      return {
        title: title
      };
    }
  }
});
