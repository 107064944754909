import { defineType } from "sanity";

export default defineType({
  title: "Card section",
  name: "cardSection",
  type: "object",
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent"
    },
    {
      name: "items",
      title: "Cards",
      type: "array",
      of: [{ type: "mosaicCard" }]
    }
  ],
  preview: {
    select: {
      title: "title.en",
      items: "items"
    },
    prepare({ title, items }) {
      return {
        title: title || "(Card section)",
        subtitle: `${items.length} pages`
      };
    }
  }
});
