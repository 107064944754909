import { defineField, defineType } from "sanity";
import { SectionAnchorInput } from "../../components/SectionAnchorInput";

export default defineType({
  name: "sectionAnchorLink",
  title: "Section Anchor Link",
  type: "object",
  preview: {
    select: {
      title: "title.en"
    }
  },
  fields: [
    {
      type: "multilingualString",
      name: "title",
      title: "Title",
      validation: (Rule) => Rule.required()
    },
    defineField({
      type: "string",
      name: "target",
      title: "Target",
      components: {
        input: SectionAnchorInput
      }
    })
  ]
});
