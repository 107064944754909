import { DashboardWidget, LayoutConfig } from "@sanity/dashboard";
import InitiateImport from "./initiateImport";

export function sscInitiateImportWidget(
  config: { layout?: LayoutConfig; silverseaApiRootUrl?: string } = {}
): DashboardWidget {
  return {
    name: "initiate-import",
    component: (props) => <InitiateImport {...props} />,
    layout: config.layout ?? { width: "medium" }
  };
}
