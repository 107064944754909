export const markets = ["FT", "EU", "AS", "UK"];

export const regions = [
  {
    key: "/",
    market: null,
    name: ""
  },
  {
    key: "FT/Caribbean",
    market: "FT",
    name: "Caribbean"
  },
  {
    key: "EU/AsiaEU",
    market: "EU",
    name: "AsiaEU"
  },
  {
    key: "EU/Africa",
    market: "EU",
    name: "Africa"
  },
  {
    key: "/Nordic",
    market: null,
    name: "Nordic"
  },
  {
    key: "EU/OtherEurope",
    market: "EU",
    name: "OtherEurope"
  },
  {
    key: "/OtherEurope",
    market: null,
    name: "OtherEurope"
  },
  {
    key: "EU/Middle East",
    market: "EU",
    name: "Middle East"
  },
  {
    key: "FT/South America",
    market: "FT",
    name: "South America"
  },
  {
    key: "AS/Far east",
    market: "AS",
    name: "Far east"
  },
  {
    key: "AS/Australia",
    market: "AS",
    name: "Australia"
  },
  {
    key: "AS/Asia",
    market: "AS",
    name: "Asia"
  },
  {
    key: "EU/Germanic",
    market: "EU",
    name: "Germanic"
  },
  {
    key: "/Africa",
    market: null,
    name: "Africa"
  },
  {
    key: "EU/Benelux",
    market: "EU",
    name: "Benelux"
  },
  {
    key: "FT/Brazil",
    market: "FT",
    name: "Brazil"
  },
  {
    key: "FT/Canada",
    market: "FT",
    name: "Canada"
  },
  {
    key: "/Caribbean",
    market: null,
    name: "Caribbean"
  },
  {
    key: "EU/Nordic",
    market: "EU",
    name: "Nordic"
  },
  {
    key: "UK/UK",
    market: "UK",
    name: "UK"
  },
  {
    key: "UK/Ireland",
    market: "UK",
    name: "Ireland"
  },
  {
    key: "/AsiaEU",
    market: null,
    name: "AsiaEU"
  },
  {
    key: "FT/Mexico",
    market: "FT",
    name: "Mexico"
  },
  {
    key: "AS/New Zealand",
    market: "AS",
    name: "New Zealand"
  },
  {
    key: "/Asia",
    market: null,
    name: "Asia"
  },
  {
    key: "/Far east",
    market: null,
    name: "Far east"
  },
  {
    key: "FT/USA",
    market: "FT",
    name: "USA"
  },
  {
    key: "EU/South Africa",
    market: "EU",
    name: "South Africa"
  }
];

export const countries = [
  {
    code: "ZZ",
    name: "At sea",
    regionKey: "/"
  },
  {
    code: "AW",
    name: "Aruba",
    regionKey: "FT/Caribbean"
  },
  {
    code: "AF",
    name: "Afghanistan",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "AO",
    name: "Angola",
    regionKey: "EU/Africa"
  },
  {
    code: "AI",
    name: "Anguilla",
    regionKey: "FT/Caribbean"
  },
  {
    code: "AX",
    name: "Åland Islands",
    regionKey: "/Nordic"
  },
  {
    code: "AL",
    name: "Albania",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "AD",
    name: "Andorra",
    regionKey: "/OtherEurope"
  },
  {
    code: "AN",
    name: "Netherlands Antilles",
    regionKey: "FT/Caribbean"
  },
  {
    code: "AE",
    name: "United Arab Emirates",
    regionKey: "EU/Middle East"
  },
  {
    code: "AR",
    name: "Argentina",
    regionKey: "FT/South America"
  },
  {
    code: "AM",
    name: "Armenia",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "AS",
    name: "American samoa",
    regionKey: "AS/Far east"
  },
  {
    code: "AQ",
    name: "Antarctica",
    regionKey: "FT/South America"
  },
  {
    code: "TF",
    name: "French Southern Territories",
    regionKey: "AS/Far east"
  },
  {
    code: "AG",
    name: "Antigua and Barbuda",
    regionKey: "FT/Caribbean"
  },
  {
    code: "AU",
    name: "Australia",
    regionKey: "AS/Australia"
  },
  {
    code: "AT",
    name: "Austria",
    regionKey: "EU/Germanic"
  },
  {
    code: "AZ",
    name: "Azerbaijan",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "BI",
    name: "Burundi",
    regionKey: "/Africa"
  },
  {
    code: "BE",
    name: "Belgium",
    regionKey: "EU/Benelux"
  },
  {
    code: "BJ",
    name: "Benin",
    regionKey: "EU/Africa"
  },
  {
    code: "BF",
    name: "Burkina Faso",
    regionKey: "EU/Africa"
  },
  {
    code: "BD",
    name: "Bangladesh",
    regionKey: "AS/Far east"
  },
  {
    code: "BG",
    name: "Bulgaria",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "BH",
    name: "Bahrain",
    regionKey: "EU/Middle East"
  },
  {
    code: "BS",
    name: "Bahamas",
    regionKey: "FT/Caribbean"
  },
  {
    code: "BA",
    name: "Bosnia and Herzegowina",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "BL",
    name: "French West Indies",
    regionKey: "FT/Caribbean"
  },
  {
    code: "BY",
    name: "Belarus",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "BZ",
    name: "Belize",
    regionKey: "FT/South America"
  },
  {
    code: "BM",
    name: "Bermuda",
    regionKey: "FT/Caribbean"
  },
  {
    code: "BO",
    name: "Bolivia",
    regionKey: "FT/South America"
  },
  {
    code: "BR",
    name: "Brazil",
    regionKey: "FT/Brazil"
  },
  {
    code: "BB",
    name: "Barbados",
    regionKey: "FT/Caribbean"
  },
  {
    code: "BN",
    name: "Brunei Darussalam",
    regionKey: "AS/Far east"
  },
  {
    code: "BT",
    name: "Bhutan",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "BW",
    name: "Botswana",
    regionKey: "EU/Africa"
  },
  {
    code: "CF",
    name: "Central African Republic",
    regionKey: "EU/Africa"
  },
  {
    code: "CA",
    name: "Canada",
    regionKey: "FT/Canada"
  },
  {
    code: "CC",
    name: "Cocos Islands",
    regionKey: "/"
  },
  {
    code: "CH",
    name: "Switzerland",
    regionKey: "EU/Germanic"
  },
  {
    code: "CL",
    name: "Chile",
    regionKey: "FT/South America"
  },
  {
    code: "CN",
    name: "China",
    regionKey: "AS/Asia"
  },
  {
    code: "CI",
    name: "Cote d'Ivoire",
    regionKey: "EU/Africa"
  },
  {
    code: "CM",
    name: "Cameroon",
    regionKey: "EU/Africa"
  },
  {
    code: "CD",
    name: "Congo, Democratic Republic of",
    regionKey: "/Africa"
  },
  {
    code: "CG",
    name: "Congo",
    regionKey: "EU/Africa"
  },
  {
    code: "CK",
    name: "Cook Islands",
    regionKey: "AS/Far east"
  },
  {
    code: "CO",
    name: "Colombia",
    regionKey: "FT/South America"
  },
  {
    code: "KM",
    name: "Comoros",
    regionKey: "EU/Africa"
  },
  {
    code: "CV",
    name: "Cape Verde",
    regionKey: "EU/Africa"
  },
  {
    code: "CR",
    name: "Costa Rica",
    regionKey: "FT/South America"
  },
  {
    code: "CU",
    name: "Cuba",
    regionKey: "FT/Caribbean"
  },
  {
    code: "CW",
    name: "Curaçao",
    regionKey: "/Caribbean"
  },
  {
    code: "CX",
    name: "Christmas Islands",
    regionKey: "FT/Caribbean"
  },
  {
    code: "KY",
    name: "Cayman Islands",
    regionKey: "FT/Caribbean"
  },
  {
    code: "CY",
    name: "Cyprus",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "CZ",
    name: "Czech Republic",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "DE",
    name: "Germany",
    regionKey: "EU/Germanic"
  },
  {
    code: "DJ",
    name: "Djibouti",
    regionKey: "/Africa"
  },
  {
    code: "DM",
    name: "Dominica",
    regionKey: "FT/Caribbean"
  },
  {
    code: "DK",
    name: "Denmark",
    regionKey: "EU/Nordic"
  },
  {
    code: "DO",
    name: "Dominican Republic",
    regionKey: "FT/Caribbean"
  },
  {
    code: "DZ",
    name: "Algeria",
    regionKey: "EU/Africa"
  },
  {
    code: "EC",
    name: "Ecuador",
    regionKey: "FT/South America"
  },
  {
    code: "EG",
    name: "Egypt",
    regionKey: "EU/Middle East"
  },
  {
    code: "ER",
    name: "Eritrea",
    regionKey: "EU/Africa"
  },
  {
    code: "EH",
    name: "Western Sahara",
    regionKey: "/Africa"
  },
  {
    code: "ES",
    name: "Spain",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "EE",
    name: "Estonia",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "ET",
    name: "Ethiopia",
    regionKey: "EU/Africa"
  },
  {
    code: "FI",
    name: "Finland",
    regionKey: "EU/Nordic"
  },
  {
    code: "FJ",
    name: "Fiji",
    regionKey: "AS/Far east"
  },
  {
    code: "FK",
    name: "Falkland Islands (Malvinas)",
    regionKey: "FT/South America"
  },
  {
    code: "FR",
    name: "France",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "FO",
    name: "Faeroe Islands",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "FM",
    name: "Fed States of Micronesia",
    regionKey: "AS/Far east"
  },
  {
    code: "GA",
    name: "Gabon",
    regionKey: "EU/Africa"
  },
  {
    code: "GB",
    name: "United Kingdom",
    regionKey: "UK/UK"
  },
  {
    code: "GE",
    name: "Georgia",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "GG",
    name: "Guernsey",
    regionKey: "UK/UK"
  },
  {
    code: "GH",
    name: "Ghana",
    regionKey: "EU/Africa"
  },
  {
    code: "GI",
    name: "Gibraltar",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "GN",
    name: "Guinea",
    regionKey: "EU/Africa"
  },
  {
    code: "GP",
    name: "French West Indies",
    regionKey: "FT/Caribbean"
  },
  {
    code: "GM",
    name: "Gambia",
    regionKey: "EU/Africa"
  },
  {
    code: "GW",
    name: "Guinea-Bissau",
    regionKey: "/Africa"
  },
  {
    code: "GQ",
    name: "Equatorial Guinea",
    regionKey: "EU/Africa"
  },
  {
    code: "GR",
    name: "Greece",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "GD",
    name: "Grenada",
    regionKey: "FT/Caribbean"
  },
  {
    code: "GL",
    name: "Greenland",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "GT",
    name: "Guatemala",
    regionKey: "FT/South America"
  },
  {
    code: "GF",
    name: "French Guiana",
    regionKey: "FT/South America"
  },
  {
    code: "GU",
    name: "Guam",
    regionKey: "FT/South America"
  },
  {
    code: "GY",
    name: "Guyana",
    regionKey: "FT/South America"
  },
  {
    code: "HK",
    name: "Hong Kong",
    regionKey: "AS/Far east"
  },
  {
    code: "HN",
    name: "Honduras",
    regionKey: "FT/South America"
  },
  {
    code: "HR",
    name: "Croatia",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "HT",
    name: "Haiti",
    regionKey: "FT/Caribbean"
  },
  {
    code: "HU",
    name: "Hungary",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "ID",
    name: "Indonesia",
    regionKey: "AS/Far east"
  },
  {
    code: "IM",
    name: "Isle of Man",
    regionKey: "UK/UK"
  },
  {
    code: "IN",
    name: "India",
    regionKey: "AS/Far east"
  },
  {
    code: "IO",
    name: "Indian Ocean Territory",
    regionKey: "/"
  },
  {
    code: "IE",
    name: "Ireland",
    regionKey: "UK/Ireland"
  },
  {
    code: "IR",
    name: "Iran (islamic republic of)",
    regionKey: "EU/Middle East"
  },
  {
    code: "IQ",
    name: "Iraq",
    regionKey: "EU/Middle East"
  },
  {
    code: "IS",
    name: "Iceland",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "IL",
    name: "Israel",
    regionKey: "EU/Middle East"
  },
  {
    code: "IT",
    name: "Italy",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "JM",
    name: "Jamaica",
    regionKey: "FT/Caribbean"
  },
  {
    code: "JE",
    name: "Jersey",
    regionKey: "UK/UK"
  },
  {
    code: "JO",
    name: "Jordan",
    regionKey: "EU/Middle East"
  },
  {
    code: "JP",
    name: "Japan",
    regionKey: "AS/Far east"
  },
  {
    code: "KZ",
    name: "Kazakhstan",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "KE",
    name: "Kenya",
    regionKey: "EU/Africa"
  },
  {
    code: "KG",
    name: "Kyrgyzstan",
    regionKey: "/AsiaEU"
  },
  {
    code: "KH",
    name: "Cambodia",
    regionKey: "AS/Far east"
  },
  {
    code: "KI",
    name: "Kiribati",
    regionKey: "AS/Far east"
  },
  {
    code: "KN",
    name: "St Kitts and Nevis",
    regionKey: "FT/Caribbean"
  },
  {
    code: "KR",
    name: "Korea, Republic of",
    regionKey: "AS/Far east"
  },
  {
    code: "KW",
    name: "Kuwait",
    regionKey: "EU/Middle East"
  },
  {
    code: "LA",
    name: "Lao People's Democratic Rep",
    regionKey: "AS/Far east"
  },
  {
    code: "LB",
    name: "Lebanon",
    regionKey: "EU/Middle East"
  },
  {
    code: "LR",
    name: "Liberia",
    regionKey: "EU/Africa"
  },
  {
    code: "LY",
    name: "Libya",
    regionKey: "EU/Africa"
  },
  {
    code: "LC",
    name: "St Lucia, West Indies",
    regionKey: "FT/Caribbean"
  },
  {
    code: "LI",
    name: "Liechtenstein",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "LK",
    name: "Sri Lanka",
    regionKey: "AS/Far east"
  },
  {
    code: "LS",
    name: "Lesotho",
    regionKey: "EU/Africa"
  },
  {
    code: "LT",
    name: "Lithuania",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "LU",
    name: "Luxembourg",
    regionKey: "EU/Benelux"
  },
  {
    code: "LV",
    name: "Latvia",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "MO",
    name: "Macau",
    regionKey: "AS/Far east"
  },
  {
    code: "MA",
    name: "Morocco",
    regionKey: "EU/Africa"
  },
  {
    code: "MC",
    name: "Monaco",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "MD",
    name: "Moldova",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "MG",
    name: "Madagascar",
    regionKey: "EU/Africa"
  },
  {
    code: "MV",
    name: "Maldives",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "MX",
    name: "Mexico",
    regionKey: "FT/Mexico"
  },
  {
    code: "MH",
    name: "Marshall Islands",
    regionKey: "AS/Far east"
  },
  {
    code: "MK",
    name: "Macedonia",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "ML",
    name: "Mali",
    regionKey: "EU/Africa"
  },
  {
    code: "MT",
    name: "Malta",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "MM",
    name: "Myanmar (former Burma)",
    regionKey: "AS/Far east"
  },
  {
    code: "ME",
    name: "Montenegro",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "MN",
    name: "Mongolia",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "MP",
    name: "Northern Marianas",
    regionKey: "AS/Far east"
  },
  {
    code: "MZ",
    name: "Mozambique",
    regionKey: "EU/Africa"
  },
  {
    code: "MR",
    name: "Mauritania",
    regionKey: "EU/Africa"
  },
  {
    code: "MS",
    name: "Montserrat",
    regionKey: "FT/Caribbean"
  },
  {
    code: "MQ",
    name: "Martinique",
    regionKey: "FT/Caribbean"
  },
  {
    code: "MU",
    name: "Mauritius",
    regionKey: "EU/Africa"
  },
  {
    code: "MW",
    name: "Malawi",
    regionKey: "EU/Africa"
  },
  {
    code: "MY",
    name: "Malaysia",
    regionKey: "AS/Far east"
  },
  {
    code: "YT",
    name: "Mayotte",
    regionKey: "EU/Africa"
  },
  {
    code: "NA",
    name: "Namibia",
    regionKey: "EU/Africa"
  },
  {
    code: "NC",
    name: "New Caledonia",
    regionKey: "AS/Far east"
  },
  {
    code: "NE",
    name: "Niger",
    regionKey: "EU/Africa"
  },
  {
    code: "NF",
    name: "Norfolk Island",
    regionKey: "FT/South America"
  },
  {
    code: "NG",
    name: "Nigeria",
    regionKey: "EU/Africa"
  },
  {
    code: "NI",
    name: "Nicaragua",
    regionKey: "FT/South America"
  },
  {
    code: "NU",
    name: "Niue",
    regionKey: "/"
  },
  {
    code: "NL",
    name: "Netherlands",
    regionKey: "EU/Benelux"
  },
  {
    code: "NO",
    name: "Norway",
    regionKey: "EU/Nordic"
  },
  {
    code: "NP",
    name: "Nepal",
    regionKey: "AS/Far east"
  },
  {
    code: "NR",
    name: "Nauru",
    regionKey: "AS/Far east"
  },
  {
    code: "NZ",
    name: "New Zealand",
    regionKey: "AS/New Zealand"
  },
  {
    code: "OM",
    name: "Oman",
    regionKey: "EU/Middle East"
  },
  {
    code: "PK",
    name: "Pakistan",
    regionKey: "AS/Far east"
  },
  {
    code: "PA",
    name: "Panama",
    regionKey: "FT/Caribbean"
  },
  {
    code: "PN",
    name: "Pitcairn Island",
    regionKey: "/"
  },
  {
    code: "PE",
    name: "Peru",
    regionKey: "FT/South America"
  },
  {
    code: "PH",
    name: "Philippines",
    regionKey: "AS/Far east"
  },
  {
    code: "PW",
    name: "Palau",
    regionKey: "AS/Far east"
  },
  {
    code: "PG",
    name: "Papua New Guinea",
    regionKey: "AS/Far east"
  },
  {
    code: "PL",
    name: "Poland",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "PR",
    name: "Puerto Rico",
    regionKey: "FT/Caribbean"
  },
  {
    code: "KP",
    name: "Korea, Dem People's Rep",
    regionKey: "/Asia"
  },
  {
    code: "PT",
    name: "Portugal",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "PY",
    name: "Paraguay",
    regionKey: "FT/South America"
  },
  {
    code: "PF",
    name: "French Polynesia",
    regionKey: "AS/Far east"
  },
  {
    code: "QA",
    name: "Qatar",
    regionKey: "EU/Middle East"
  },
  {
    code: "RE",
    name: "Reunion",
    regionKey: "EU/Africa"
  },
  {
    code: "RO",
    name: "Romania",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "RU",
    name: "Russian Federation",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "RW",
    name: "Rwanda",
    regionKey: "/Africa"
  },
  {
    code: "SA",
    name: "Saudi Arabia",
    regionKey: "EU/Middle East"
  },
  {
    code: "SD",
    name: "Sudan",
    regionKey: "EU/Africa"
  },
  {
    code: "SN",
    name: "Senegal",
    regionKey: "EU/Africa"
  },
  {
    code: "SG",
    name: "Singapore",
    regionKey: "AS/Far east"
  },
  {
    code: "GS",
    name: "Georgia & Sandwich islands",
    regionKey: "FT/South America"
  },
  {
    code: "SH",
    name: "St Helena",
    regionKey: "EU/Africa"
  },
  {
    code: "SJ",
    name: "Svalbard and Jan Mayen",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "SB",
    name: "Solomon Islands",
    regionKey: "/Far east"
  },
  {
    code: "SL",
    name: "Sierra Leone",
    regionKey: "/Africa"
  },
  {
    code: "SV",
    name: "El Salvador",
    regionKey: "FT/South America"
  },
  {
    code: "SM",
    name: "San Marino",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "SO",
    name: "Somalia",
    regionKey: "EU/Africa"
  },
  {
    code: "PM",
    name: "St Pierre and Miquelon",
    regionKey: "FT/Caribbean"
  },
  {
    code: "RS",
    name: "Serbia",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "ST",
    name: "Sao Tome and Principe",
    regionKey: "EU/Africa"
  },
  {
    code: "SR",
    name: "Suriname",
    regionKey: "FT/South America"
  },
  {
    code: "SK",
    name: "Slovakia",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "SI",
    name: "Slovenia",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "SE",
    name: "Sweden",
    regionKey: "EU/Nordic"
  },
  {
    code: "SZ",
    name: "Swaziland",
    regionKey: "EU/Africa"
  },
  {
    code: "SC",
    name: "Seychelles",
    regionKey: "EU/Africa"
  },
  {
    code: "SY",
    name: "Syrian Arab Republic",
    regionKey: "EU/Middle East"
  },
  {
    code: "TC",
    name: "Turks and Caicos Islands",
    regionKey: "FT/Caribbean"
  },
  {
    code: "TD",
    name: "Chad",
    regionKey: "/Africa"
  },
  {
    code: "TG",
    name: "Togo",
    regionKey: "EU/Africa"
  },
  {
    code: "TH",
    name: "Thailand",
    regionKey: "AS/Far east"
  },
  {
    code: "TJ",
    name: "Tajikistan",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "TK",
    name: "Tokelau",
    regionKey: "AS/Far east"
  },
  {
    code: "TM",
    name: "Turkmenistan",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "TL",
    name: "East Timor",
    regionKey: "/Asia"
  },
  {
    code: "TO",
    name: "Tonga",
    regionKey: "/Far east"
  },
  {
    code: "TT",
    name: "Trinidad and Tobago",
    regionKey: "FT/Caribbean"
  },
  {
    code: "TN",
    name: "Tunisia",
    regionKey: "EU/Africa"
  },
  {
    code: "TR",
    name: "Turkey",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "TV",
    name: "Tuvalu",
    regionKey: "AS/Far east"
  },
  {
    code: "TW",
    name: "Taiwan",
    regionKey: "AS/Far east"
  },
  {
    code: "TZ",
    name: "Tanzania",
    regionKey: "EU/Africa"
  },
  {
    code: "UG",
    name: "Uganda",
    regionKey: "EU/Africa"
  },
  {
    code: "UA",
    name: "Ukraine",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "UM",
    name: "US Minor Outlying Islands",
    regionKey: "FT/Caribbean"
  },
  {
    code: "UY",
    name: "Uruguay",
    regionKey: "FT/South America"
  },
  {
    code: "US",
    name: "United States of America",
    regionKey: "FT/USA"
  },
  {
    code: "UZ",
    name: "Uzbekistan",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "VA",
    name: "Vatican City State",
    regionKey: "EU/OtherEurope"
  },
  {
    code: "VC",
    name: "St Vincent and Grenadines",
    regionKey: "FT/Caribbean"
  },
  {
    code: "VE",
    name: "Venezuela",
    regionKey: "FT/South America"
  },
  {
    code: "VG",
    name: "British Virgin Islands",
    regionKey: "FT/Caribbean"
  },
  {
    code: "VI",
    name: "United States Virgin Islands",
    regionKey: "FT/Caribbean"
  },
  {
    code: "VN",
    name: "Vietnam",
    regionKey: "AS/Far east"
  },
  {
    code: "VU",
    name: "Vanuatu",
    regionKey: "AS/Far east"
  },
  {
    code: "WF",
    name: "Wallis and Futuna Islands",
    regionKey: "EU/AsiaEU"
  },
  {
    code: "WS",
    name: "Samoa Islands",
    regionKey: "AS/Far east"
  },
  {
    code: "YE",
    name: "Yemen",
    regionKey: "EU/Middle East"
  },
  {
    code: "ZA",
    name: "South Africa",
    regionKey: "EU/South Africa"
  },
  {
    code: "ZM",
    name: "Zambia",
    regionKey: "EU/Africa"
  },
  {
    code: "ZW",
    name: "Zimbabwe",
    regionKey: "EU/Africa"
  }
];
