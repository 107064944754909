import { AiOutlineSafety } from "react-icons/ai";
import { FaShoppingCart, FaFileContract } from "react-icons/fa";
import { StructureBuilder } from "sanity/structure";

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Price Configurator")
    .icon(FaShoppingCart)
    .child(
      S.list()
        .title("Price Configurator")
        .items([
          S.listItem()
            .title("Cruise with confidence")
            .icon(AiOutlineSafety)
            .child(
              S.editor()
                .id("cruise-with-confidence-section")
                .schemaType("cruiseWithConfidence")
                .documentId("cruise-with-confidence-section")
            ),
          S.divider(),
          S.listItem()
            .title("Terms & Conditions")
            .icon(FaFileContract)
            .child(
              S.editor()
                .id("terms-and-conditions-summary")
                .schemaType("termsAndConditionsSummary")
                .documentId("terms-and-conditions-summary")
            )
        ])
    );
