import { defineType } from "sanity";
import { multilingualStringPreview } from "../../base/multilingualArrayString";

export default defineType({
  name: "mainMenuGroup",
  title: "Group",
  type: "object",
  preview: {
    select: {
      title: "title",
      collection: "collection"
    },
    prepare({ title, collection }) {
      return {
        title: multilingualStringPreview(title),
        subtitle: collection ? `Collection: ${collection}` : undefined
      };
    }
  },
  fields: [
    {
      name: "showTitle",
      title: "Show Title",
      type: "boolean",
      validation: (Rule) => Rule.required()
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualArrayString"
    },

    {
      name: "layout",
      title: "Layout",
      type: "string",
      options: {
        list: [
          { title: "Auto", value: "auto" },
          { title: "1 Column", value: "1column" },
          { title: "2 Columns", value: "2columns" },
          { title: "3 Columns", value: "3columns" },
          { title: "4 Columns", value: "4columns" }
        ]
      },
      validation: (Rule) => Rule.required()
    },
    {
      name: "showItemsMedia",
      title: "Show Items Media",
      description: "Show image or icon",
      type: "boolean",
      validation: (Rule) => Rule.required()
    },
    {
      name: "items",
      title: "Items",
      type: "array",
      of: [
        {
          type: "mainMenuItem"
        },
        {
          type: "locationSpecificMainMenuItem"
        }
      ]
    },
    {
      name: "collection",
      title: "Collection",
      type: "string",
      options: {
        list: [
          { title: "Destinations", value: "destinations" },
          { title: "Ships (Expedition)", value: "ships-expeditions" },
          { title: "Ships (Ocean)", value: "ships-ocean" },
          { title: "Promos", value: "promos" },
          { title: "Cruises Launches", value: "cruises-launches" }
        ]
      }
    }
  ]
});
