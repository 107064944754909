import { defineType } from "sanity";

export default defineType({
  name: "sectionSliderCard",
  type: "object",
  title: "Section Slider Card",
  fields: [
    {
      name: "page",
      type: "reference",
      to: [{ type: "page" }, { type: "landingPage" }, { type: "destination" }]
    },
    {
      name: "image",
      title: "Image",
      description: "overides page card image",
      type: "imageWithAlt",
      options: { hotspot: true }
    },
    {
      name: "title",
      type: "multilingualString",
      description: "overides page card title"
    },
    {
      name: "description",
      type: "multilingualText",
      description: "overides page card description"
    }
  ],
  preview: {
    select: {
      title: "title.en",
      pageTitle: "page.card.title.en",
      media: "image"
    },
    prepare({ title, pageTitle, media }) {
      return {
        title: title || pageTitle,
        media
      };
    }
  }
});
