import { MdAirlineSeatIndividualSuite } from "react-icons/md";
import suiteCategory from "./suiteCategory";
import { defineType } from "sanity";

export default defineType({
  name: "deckSuiteCategory",
  title: "Deck Suite Category",
  type: "document",
  icon: MdAirlineSeatIndividualSuite,
  preview: {
    select: {
      suiteCategory: "suiteCategory.name.en"
    },
    prepare({ suiteCategory }) {
      return {
        title: suiteCategory
      };
    }
  },
  fields: [
    {
      name: "suiteCategory",
      title: "Suite Category",
      type: "reference",
      to: [{ type: suiteCategory.name }]
    }
  ]
});
