import { FaMapMarked } from "react-icons/fa";
import { metadataWithoutParent } from "../page-fragments";
import brochureGrouping from "./brochureGrouping";
import { defineType } from "sanity";
import { cruiseGroups } from "../data-types/cruiseGroups";

export default defineType({
  name: "destination",
  title: "Destination",
  type: "document",
  icon: FaMapMarked,
  orderings: [
    {
      title: "Title",
      name: "titleAsc",
      by: [{ field: "title.en", direction: "asc" }]
    }
  ],

  preview: {
    select: {
      title: "name.en",
      subtitle: "title.en",
      media: "heroImage"
    }
  },
  fieldsets: [
    {
      name: "hero",
      title: "Hero",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "FYC",
      title: "Find Your Cruise",
      description:
        "Options for the find your cruise search on this destination",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "opening",
      title: "Opening section",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "infographic-section",
      title: "Infographic Section",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "travel-guide",
      title: "Travel guide section",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "ships",
      title: "Our ships section",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "blog",
      title: "Blog",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "isExpeditionDestination",
      title: "Is Expedition Destination",
      type: "boolean",
      validation: (Rule) => Rule.required()
    },
    {
      name: "heroImage",
      title: "Hero Image",
      type: "image",
      options: { hotspot: true },
      fieldset: "hero"
    },
    {
      name: "video",
      type: "video",
      fieldset: "hero"
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualText",
      fieldset: "opening",
      //validation: (Rule) => Rule.required(),
      options: {
        rows: 3
      }
    },
    {
      name: "prefixArticle",
      title: "Prefix Article",
      description: "Article to be displayed on the destination page",
      type: "multilingualArrayString"
    },
    {
      name: "leadIn",
      title: "Lead-in",
      type: "multilingualText",
      fieldset: "opening"
      //validation: (Rule) => Rule.required()
    },
    {
      name: "body",
      title: "Body",
      type: "multilingualBlockContent",
      fieldset: "opening"
    },
    {
      name: "fycShowExpeditionFilter",
      title: "Show Expeditions filter",
      type: "boolean",
      fieldset: "FYC"
    },
    {
      name: "fycSpecialType",
      title: "Special Type Filter",
      type: "string",
      options: {
        list: [
          { title: "Top Cruise", value: "TopCruise" },
          { title: "Antarctica Bridge", value: "AntarcticaBridge" }
        ]
      },
      fieldset: "FYC"
    },
    {
      name: "slideshowImages",
      title: "Slideshow Images",
      type: "array",
      of: [
        {
          type: "slide"
        }
      ]
    },
    {
      name: "packagesTitle",
      type: "multilingualString",
      title: "Title",
      fieldset: "infographic-section"
    },
    {
      name: "packagesHeaderContent",
      type: "multilingualBlockContent",
      title: "Content",
      fieldset: "infographic-section"
    },
    {
      name: "allInclusiveDisclaimer",
      type: "multilingualText",
      title: "Disclaimer",
      fieldset: "infographic-section"
      //validation: (Rule) => Rule.required()
    },

    {
      name: "travelGuideImage",
      title: "Image",
      type: "image",
      options: { hotspot: true },
      fieldset: "travel-guide"
      //validation: (Rule) => Rule.required()
    },
    {
      name: "travelGuideTitle",
      title: "Title",
      type: "multilingualString",
      fieldset: "travel-guide"
      //validation: (Rule) => Rule.required()
    },
    {
      name: "travelGuideHeader",
      title: "Header",
      type: "multilingualString",
      fieldset: "travel-guide"
      //validation: (Rule) => Rule.required()
    },
    {
      name: "travelGuideBody",
      title: "Body",
      type: "multilingualText",
      fieldset: "travel-guide"
      //validation: (Rule) => Rule.required()
    },
    {
      name: "brochureGroup",
      title: "Brochure Group",
      type: "reference",
      to: [{ type: brochureGrouping.name }],
      fieldset: "travel-guide"
    },
    {
      name: "travelGuideButtonText",
      title: "Button Text",
      type: "multilingualString",
      fieldset: "travel-guide"
      //validation: (Rule) => Rule.required()
    },
    {
      name: "expeditionTeam",
      title: "Expedition Team",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "expeditionTeamMember" }]
        }
      ]
    },
    {
      name: "blog",
      title: "Blog Posts",
      type: "blogSection",
      fieldset: "blog"
    },
    {
      name: "discoverBlogCategoryId",
      title: "Discover Blog Category Id",
      type: "number",
      readOnly: true,
      fieldset: "blog"
    },
    {
      name: "shipsSectionTitle",
      title: "Title",
      type: "multilingualString",
      fieldset: "ships"
    },
    {
      name: "visitingShips",
      title: "Visiting Ships",
      type: "array",
      fieldset: "ships",
      of: [
        {
          name: "ship",
          title: "Ship",
          type: "reference",
          to: [{ type: "ship" }]
        }
      ]
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: metadataWithoutParent.name,
      group: "seo"
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    },
    {
      name: "destinationId",
      title: "Destination ID (in Shop API/SMA/Setup)",
      type: "number",
      validation: (Rule) => Rule.required().integer().positive()
    },
    {
      name: "destinationWebCode",
      title: "Destination WebCode",
      type: "string",
      validation: (Rule) => Rule.required()
    },
    {
      name: "cruiseGroup",
      title: "Cruise Group",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: cruiseGroups
      }
    }
  ]
});
