import { GrConfigure } from "react-icons/gr";
import {
  CustomValidatorResult,
  ValidationContext,
  ValidationError,
  defineType
} from "sanity";

const isUniqueCode = async (
  code: string | undefined,
  context: ValidationContext
) => {
  const { document, getClient } = context;
  if (!document) return false;

  const client = getClient({ apiVersion: "2022-12-07" });
  const id = document._id.replace(/^drafts\./, "");

  const params = {
    draft: `drafts.${id}`,
    published: id,
    code: code
  };

  /* groq */
  const query = `!defined(*[
    _type == 'promoConfiguration' &&
    !(_id in [$draft, $published]) &&
    code == $code
  ][0]._id)`;

  return await client.fetch(query, params);
};

const isUniqueCommercialTypeId = async (
  commercialTypeId: number | undefined,
  context: ValidationContext
) => {
  const { document, getClient } = context;
  if (!document) return false;

  const client = getClient({ apiVersion: "2022-12-07" });
  const id = document._id.replace(/^drafts\./, "");

  const params = {
    draft: `drafts.${id}`,
    published: id,
    commercialTypeId: commercialTypeId
  };

  /* groq */
  const query = `!defined(*[
    _type == 'promoConfiguration' &&
    !(_id in [$draft, $published]) &&
    promoType == 'res_commercial_type' &&
    commercialTypeId == $commercialTypeId
  ][0]._id)`;

  return await client.fetch(query, params);
};

const isUniqueResIDs = async (
  resIds: (number | undefined)[] | undefined,
  context: ValidationContext
) => {
  const { document, getClient } = context;
  if (!document) return false;

  const client = getClient({ apiVersion: "2022-12-07" });
  const id = document._id.replace(/^drafts\./, "");

  const params = {
    draft: `drafts.${id}`,
    published: id,
    resIds: resIds
  };

  /* groq */
  const query = `!defined(*[
    _type == 'promoConfiguration' &&
    !(_id in [$draft, $published]) &&
    promoType == 'res_promos' &&
    count(resIds[@ in $resIds]) > 0
  ][0]._id)`;

  return await client.fetch(query, params);
};

export default defineType({
  name: "promoConfiguration",
  title: "Configuration for Promo",
  type: "document",
  icon: GrConfigure,
  validation: (Rule) =>
    Rule.custom((fields) => {
      if (fields?.promoType === "manual") {
        const manualValidity = fields.manualValidity as ManualValidityValue;
        return validateManualPromoValidity(manualValidity);
      }
      return true;
    }),
  preview: {
    select: {
      title: "code"
    },
    prepare({ title }) {
      return {
        title
      };
    }
  },
  fieldsets: [
    {
      name: "API",
      title: "API settings",
      options: { collapsible: false }
    }
  ],
  fields: [
    {
      name: "code",
      title: "Code",
      type: "string",
      description: "add a unique code to identify the promo",
      validation: (Rule) =>
        Rule.required().custom<string>(async (value, context) => {
          const isUnique = await isUniqueCode(value, context);
          if (!isUnique) return "Another promo with this code is present";
          return true;
        })
    },
    {
      name: "promoType",
      title: "Type",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: [
          { title: "RES promos", value: "res_promos" },
          { title: "RES commercial type", value: "res_commercial_type" },
          { title: "Manual", value: "manual" }
        ]
      }
    },
    {
      fieldset: "API",
      name: "resIds",
      title: "Res IDs",
      description: "if possible, use commercial type id",
      type: "array",
      of: [
        {
          type: "number",
          title: "Promo ID",
          validation: (Rule) =>
            Rule.required()
              .integer()
              .custom<number>((value) => {
                if (value === 0) return "Promo ID must be different than 0";
                return true;
              })
        }
      ],
      hidden: ({ parent }) => parent?.promoType !== "res_promos",
      validation: (Rule) =>
        Rule.custom(async (value: number[], context: ValidationContext) => {
          const required = context.document?.promoType == "res_promos";
          if (!required) return true;
          if (!value || value.length == 0) return "Add a promo id";

          const isUnique = await isUniqueResIDs(value, context);
          if (!isUnique)
            return "Another promo with one of the res ids is present";

          return true;
        })
    },
    {
      fieldset: "API",
      name: "commercialTypeId",
      title: "Commercial Type ID",
      description: "all promos groupped by a type id",
      type: "number",
      hidden: ({ parent }) => parent?.promoType !== "res_commercial_type",
      validation: (Rule) =>
        Rule.custom(async (value: number, context: ValidationContext) => {
          const required = context.document?.promoType == "res_commercial_type";
          if (!required) return true;
          if (!value) return "Add a commercial type id";

          const isUnique = await isUniqueCommercialTypeId(value, context);
          if (!isUnique)
            return "Another promo with this commercial type id is present";
          return true;
        })
    },
    {
      name: "manualValidity",
      type: "manualPromoValidity",
      hidden: ({ parent }) => parent?.promoType !== "manual"
    },
    {
      name: "calculateDiscountedPrice",
      title: "Apply automatic promotion",
      description:
        "[WARNING] If the promo is automatic, calculate the discounted price applying this promo and show in the website. This needs to be enabled even for Shipboard credit promotions.",
      type: "boolean",
      hidden: ({ parent }) => parent?.promoType == "manual"
    },
    {
      name: "activeInEcommerce",
      title: "Active in ecommerce",
      type: "boolean"
    },
    {
      name: "customTokens",
      title: "Custom Tokens",
      description:
        "Do not include the configuration code in the token name. An importer transform run is needed to apply these tokens",
      type: "array",
      of: [
        {
          type: "singleCurrencyTokenValue"
        },
        {
          type: "multiCurrencyTokenValue"
        },
        {
          type: "dateTokenValue"
        }
      ],
      validation: (Rule) =>
        Rule.custom(async (value: { name?: string }[] | undefined) => {
          if (!value) return true;

          const checks = value.reduce(
            (acc, { name = "not defined" }) => {
              if (acc[name]) acc[name]++;
              else acc[name] = 1;
              return acc;
            },
            {} as Record<string, number>
          );

          const duplicatedTokenNames = Object.keys(checks).filter(
            (x) => checks[x] > 1
          );

          if (duplicatedTokenNames.length > 0)
            return `Token name must be unique: ${duplicatedTokenNames.join(", ")}`;
          return true;
        })
    }
  ]
});

type ManualValidityValue =
  | {
      cruiseCodes?: string;
      validFrom?: string;
      validTo?: string;
      markets?: string[];
      currencies?: string[];
      fareCodes?: string[];
    }
  | undefined;

const validateManualPromoValidity = (
  manualValidity: ManualValidityValue
): CustomValidatorResult => {
  const { cruiseCodes, validFrom, validTo, currencies, fareCodes, markets } =
    manualValidity || {};

  const errors = [] as ValidationError[];
  if (!cruiseCodes) errors.push(buildManualPromoValidityError("cruiseCodes"));

  if (!validFrom) errors.push(buildManualPromoValidityError("validFrom"));

  if (!validTo) errors.push(buildManualPromoValidityError("validTo"));

  if (!markets || markets.length === 0)
    errors.push(buildManualPromoValidityError("markets"));

  if (!currencies || currencies.length === 0)
    errors.push(buildManualPromoValidityError("currencies"));

  if (!fareCodes || fareCodes.length === 0)
    errors.push(buildManualPromoValidityError("fareCodes"));

  if (errors.length > 0) return errors;
  return true;
};

const buildManualPromoValidityError = (field: string): ValidationError => {
  return {
    message: `${field} is required for manual promos`,
    path: ["manualValidity", field]
  };
};
