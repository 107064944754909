import { defineType } from "sanity";
import { contentFilterPreview } from "../../common/localization";

export default defineType({
  name: "destinationPackageItem",
  title: "Package Item",
  type: "object",
  preview: {
    select: {
      title: "name.en",
      filters: "filters",
      filterExclusions: "filterExclusions"
    },
    prepare({ title, filters, filterExclusions }) {
      return {
        title: title,
        subtitle: contentFilterPreview(filters, filterExclusions)
      };
    }
  },
  fields: [
    {
      name: "icon",
      title: "Icon",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: [
          "Services",
          "Flight",
          "Food & Beverage",
          "Reduced Fares",
          "Hotel",
          "Private Flight",
          "Pack",
          "Shorex",
          "Quito",
          "Door to Door"
        ]
      }
    },
    {
      name: "name",
      title: "Name",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "description",
      title: "Description",
      type: "multilingualText"
    },
    {
      name: "isHighlighted",
      title: "Highlighted",
      type: "boolean"
    },
    {
      name: "filters",
      title: "Filters",
      description:
        "If present, the menu item will only appear for the below selected filters",
      type: "array",
      of: [
        {
          type: "airNonAirFilter"
        },
        {
          type: "locationFilter"
        }
      ]
    },
    {
      name: "filterExclusions",
      title: "Filter Exclusions",
      description:
        "If present, any selected countries will be excluded, even if included within the filters above.",
      type: "array",
      of: [
        {
          type: "locationFilter"
        }
      ]
    }
  ]
});
