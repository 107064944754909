import { defineType } from "sanity";

export default defineType({
  name: "ctaButtons",
  type: "array",
  title: "CTA Buttons",
  of: [
    { type: "pageCTA" },
    { type: "raqCTA" },
    { type: "brochureCTA" },
    { type: "linkCTA" },
    { type: "fileCTA" },
    { type: "videoCTA" },
    { type: "anchorCTA" },
    { type: "phoneNumberCTA" }
  ]
});
