import { Badge, BadgeTone } from "@sanity/ui";
import styled from "styled-components";
import { match } from "ts-pattern";

const LogLevelBadge = styled(Badge)`
  display: block;
  margin: 0;
`;

export type CruiseDiagnosticImporterLogLevelBadgeProps = {
  level: "WARN" | "ERROR" | "INFO" | "DEBUG";
};

export default function CruiseDiagnosticImporterLogLevelBadge({
  level
}: CruiseDiagnosticImporterLogLevelBadgeProps) {
  return (
    <LogLevelBadge
      tone={match(level)
        .returnType<BadgeTone>()
        .with("DEBUG", () => "primary")
        .with("INFO", () => "positive")
        .with("WARN", () => "caution")
        .with("ERROR", () => "critical")
        .exhaustive()}
      mode="outline"
    >
      {level}
    </LogLevelBadge>
  );
}
