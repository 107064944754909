import { BsFillGridFill } from "react-icons/bs";
import { packageIcon } from "../base";
import { multilingualBlockContentPreview } from "../base/multilingualBlockContent";
import { defineType } from "sanity";

export default defineType({
  title: "Custom Offer Box Item",
  name: "customOfferBoxItem",
  type: "object",
  icon: BsFillGridFill,
  fields: [
    {
      name: "promo",
      title: "Promo",
      type: "reference",
      to: [{ type: "exclusiveOfferPerPromo" }],
      description: "Select a promo to use default content and tokens"
    },
    {
      name: "icon",
      title: "Icon",
      type: packageIcon.name
    },
    {
      title: "Title",
      name: "title",
      type: "multilingualString"
    },
    {
      title: "Text",
      name: "text",
      type: "multilingualBlockContent"
    }
  ],
  preview: {
    select: {
      title: "title.en",
      subtitle: "text.en",
      icon: "icon"
    },
    prepare: ({ title, subtitle, icon }) => ({
      title: `[${icon}] ${title}`,
      subtitle: multilingualBlockContentPreview(100)(subtitle)
    })
  }
});
