import { useCallback, useState } from "react";
import { BsClipboardMinus } from "react-icons/bs";
import { DocumentActionComponent, DocumentActionDescription } from "sanity";
import { SanityDocumentWithSections } from "./type";
import SectionsChooser from "./SectionsChooser";

export const CopySelectedFieldsAction: DocumentActionComponent = (props) => {
  const { onComplete } = props;
  const draft = props.draft as SanityDocumentWithSections;
  const published = props.published as SanityDocumentWithSections;
  const [dialogOpen, setDialogOpen] = useState(false);
  const pageSections = draft?.["sections"] || published?.["sections"] || [];

  const [selected, setSelected] = useState<string[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<
    SanityDocumentWithSections[]
  >([]);

  const onSectionSelected = useCallback((section: string) => {
    setSelected((selection) =>
      selection.includes(section)
        ? selection.filter((i) => i != section)
        : [...selection, section]
    );
  }, []);

  const onSelectedDocument = useCallback(
    (document: SanityDocumentWithSections) => {
      setSelectedDocuments((documents) =>
        documents.includes(document)
          ? documents.filter((i) => i._id != document._id)
          : [...documents, document]
      );
    },
    []
  );

  const onRemoveSelectedDocument = useCallback(
    (document: SanityDocumentWithSections) => {
      setSelectedDocuments((documents) =>
        documents.filter((i) => i._id != document._id)
      );
    },
    []
  );

  return {
    label: "Copy Sections...",
    disabled: pageSections.length === 0,
    onHandle: () => {
      setDialogOpen(true);
    },
    dialog: dialogOpen && {
      type: "dialog",
      header: "Copy Sections to other Pages",
      width: "full",
      onClose: onComplete,
      content: (
        <SectionsChooser
          document={draft || published}
          selectedSections={selected}
          onSectionSelected={onSectionSelected}
          onSelectedDocument={onSelectedDocument}
          selectedDocuments={selectedDocuments}
          onRemoveSelectedDocument={onRemoveSelectedDocument}
          onMigrationCompleted={onComplete}
        />
      )
    },
    icon: BsClipboardMinus
  } satisfies DocumentActionDescription;
};
