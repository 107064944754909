import { VscTasklist } from "react-icons/vsc";
import { defineType } from "sanity";

export default defineType({
  name: "brochureLeadForm",
  title: "7. Brochure Lead Form",
  type: "object",
  icon: VscTasklist,

  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "brochureGroup",
      title: "Brochure Group",
      type: "reference",
      to: [{ type: "brochureGrouping" }]
    },
    {
      name: "ctaLabel",
      title: "CTA Label",
      type: "multilingualString",
      description: "leave empty for the default value"
    },
    {
      name: "asModal",
      title: "Enable in-page modal",
      description:
        "Show in-page modal with the form. Use only when only with group where only 1 brochure per country is valid. If off, it will show a link to the brochure group page",
      type: "boolean"
    },
    {
      name: "asSticky",
      title: "Renders as sticky bar",
      type: "boolean"
    }
  ],
  preview: {
    select: {
      title: "brochureGroup.name" || "(Brochure Lead Form)"
    },
    prepare({ title }) {
      return {
        title: title
      };
    }
  }
});
