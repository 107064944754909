import { FileValue, ValidationContext, defineField, defineType } from "sanity";
import {
  DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES,
  SUPPORTED_LANGUAGE_IDS,
  LanguageCode
} from "../../common/localization";

const NON_DEFAULT_LANGUAGES = SUPPORTED_LANGUAGES.filter(
  (lang) => lang !== DEFAULT_LANGUAGE
);

const defaultLanguageDefined = (context: ValidationContext) =>
  !!(context.parent as any)[DEFAULT_LANGUAGE.id];

const allOtherLanguagesNotDefined = (context: ValidationContext) =>
  NON_DEFAULT_LANGUAGES.every((lang) => !(context.parent as any)[lang.id]);

const firstValidTranslation = (selection: MultilingualFileValue): string =>
  SUPPORTED_LANGUAGE_IDS.reduce<string>(
    (s, i) => s || selection[i].asset?._key || "", //TODO: check
    ""
  );

const defaultLanguageShouldBeProvided = (
  item: unknown,
  context: ValidationContext
) =>
  defaultLanguageDefined(context) ||
  allOtherLanguagesNotDefined(context) ||
  "English should be provided";

type MultilingualFileValue = Record<LanguageCode, FileValue>;

export default defineType({
  name: "multilingualFile",
  title: "Multi-lingual File",
  type: "object",
  fields: [
    defineField({
      title: DEFAULT_LANGUAGE.title,
      name: DEFAULT_LANGUAGE.id,
      type: "file",
      validation: (Rule) => Rule.custom(defaultLanguageShouldBeProvided)
    }),
    ...NON_DEFAULT_LANGUAGES.map((lang) =>
      defineField({
        title: lang.title,
        name: lang.id,
        type: "file"
      })
    )
  ],
  // preview: {
  //   select: SUPPORTED_LANGUAGES_MAP,
  //   prepare(translations: MultilingualFileValue) {
  //     return {
  //       title: firstValidTranslation(translations)
  //     };
  //   }
  // }
  toReadableText: (multilingualFile: MultilingualFileValue) =>
    firstValidTranslation(multilingualFile)
});
