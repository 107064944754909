import { defineType } from "sanity";

export default defineType({
  type: "object",
  name: "featureToggles",
  title: "Feature Toggles",
  fields: [
    {
      title: "Enable Offline Brochure Ordering",
      name: "enableOfflineBrochureOrdering",
      type: "boolean"
    }
  ]
});
