import { SUPPORTED_LANGUAGES } from "../../common/localization";
import { someLanguageShouldBeProvided } from "../../common/customValidations";
import { SlugIsUniqueValidator, defineField } from "sanity";

const isUniqueSlugByParent: SlugIsUniqueValidator = (slug, options) => {
  const { document, path, getClient } = options;
  const client = getClient({ apiVersion: "2022-12-07" });

  if (!document || !path) return false;

  const slugPropertyPath = path.join(".");

  const id = document._id.replace(/^drafts\./, "");
  const parent = (document as any).metadata?.routing?.parent;

  const params = {
    draft: `drafts.${id}`,
    published: id,
    slug
  };

  if (!path.join(".").startsWith("metadata.routing")) {
    const query = `!defined(*[!(_id in [$draft, $published]) && ${slugPropertyPath}.current == $slug][0]._id)`;
    return client.fetch(query, params);
  }

  const routingPropertyPath = path.slice(0, -2).join(".");
  const parentPropertyPath = `${routingPropertyPath}.parent`;

  return parent
    ? client.fetch(
        `!defined(*[!(_id in [$draft, $published]) && ${slugPropertyPath}.current == $slug && ${parentPropertyPath}._ref == $parentRef][0]._id)`,
        { ...params, parentRef: parent?._ref }
      )
    : client.fetch(
        `!defined(*[!(_id in [$draft, $published]) && ${slugPropertyPath}.current == $slug && !defined(${parentPropertyPath})][0]._id)`,
        params
      );
};

export default defineField({
  name: "multilingualSlug",
  title: "Multi-lingual Slug",
  type: "object",
  validation: (Rule) => Rule.required().custom(someLanguageShouldBeProvided),
  fields: SUPPORTED_LANGUAGES.map((lang) =>
    defineField({
      title: lang.title,
      name: lang.id,
      type: "slug",
      options: {
        isUnique: isUniqueSlugByParent
      }
    })
  )
});
