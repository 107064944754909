import { FaPiggyBank } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  title: "5. Offer Description Row",
  name: "offerDescriptionRow",
  type: "object",
  icon: FaPiggyBank,
  fields: [
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "offers",
      title: "Offers",
      type: "array",
      of: [
        {
          type: "reference",
          to: [
            { type: "exclusiveOfferPerPromo" },
            { type: "cruiseGroupPerFare" }
          ]
        }
      ]
    },
    {
      name: "description",
      title: "Description",
      type: "multilingualBlockContent"
    },
    {
      name: "termsAndConditions",
      title: "Terms and conditions",
      type: "multilingualBlockContent"
    }
  ],
  preview: {
    select: {
      title: "heading.title.en"
    },
    prepare({ title }) {
      return {
        title: title || "(Offer description row)"
      };
    }
  }
});
