import { INCLUSION_CODES } from "@silversea-ssc/web-inclusions";
import { startCase } from "lodash";

export const inclusionCodes = INCLUSION_CODES.map((c) => ({
  title: startCase(c),
  value: c
}));

export const inclusionIcons = [
  {
    title: "Reduced fares in business class",
    value: "reduced-fares-in-business-class"
  },
  { title: "Free flight", value: "free-flight" },
  { title: "Free transfers", value: "free-transfers" },
  { title: "Shore excursions", value: "shore-excursions" },
  { title: "Food beverage", value: "food-beverage" },
  { title: "Services", value: "services" },
  { title: "Free hotel", value: "free-hotel" },
  { title: "Door to door", value: "door-to-door" },
  { title: "Ship", value: "ship-lateral" }
];

export const getLabelForInclusionCode = (code: string) =>
  inclusionCodes.find((c) => c.value == code)?.title || code;
