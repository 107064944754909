import { GoMail } from "react-icons/go";
import { defineType } from "sanity";

export default defineType({
  title: "7. Subscribe to Newsletter",
  name: "subscribeToNewsletter",
  icon: GoMail,
  type: "object",
  fields: [
    {
      name: "enabled",
      title: "Enabled",
      description:
        "used only to allow to add the component in the page builder",
      type: "boolean"
    }
  ],
  preview: {
    prepare(_document) {
      return {
        title: "Subscribe to Newsletter"
      };
    }
  }
});
