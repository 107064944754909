import { defineType } from "sanity";
import brochureGrouping from "../../content-types/brochureGrouping";

export default defineType({
  name: "brochureCTA",
  type: "object",
  title: "Brochure CTA",
  fields: [
    {
      name: "brochure",
      description:
        "Brochures to show when clicking on the CTA. Leave it empty for all brochures page",
      type: "reference",
      to: [{ type: brochureGrouping.name }]
    },
    {
      name: "asModal",
      title: "Enable in-page modal",
      description:
        "Show in-page modal with the form. Use only when only with group where only 1 brochure per country is valid. If off, it will show a link to the brochure group page",
      type: "boolean"
    },
    {
      name: "label",
      type: "multilingualString",
      description: 'leave empty for "View the Brochures"'
    },
    {
      name: "type",
      type: "buttonType"
    }
  ],
  preview: {
    select: {
      title: "label.en"
    },
    prepare({ title }) {
      return {
        title: title || "View the Brochures"
      };
    }
  }
});
